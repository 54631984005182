
import { ElMessage } from 'element-plus/es'
import {
  defineComponent,
  reactive,
}                    from 'vue'

import ConfigHelper  from '@/helpers/config'
import LangHelper    from '@/helpers/lang'
import PortHelper    from '@/helpers/port'
import WrapperFooter from '@/views/wrapper/WrapperFooter.vue'


export default defineComponent({
  components: {
    WrapperFooter,
  },
  setup() {
    const model = reactive({
      ...ConfigHelper.config,
    })

    function save() {
      _.chain(model)
       .map((
           value: string,
           key: string,
       ) => {
         const [ group, item ] = key.split('_')

         return {
           group,
           item,
           value,
         }
       })
       .filter(({
         group,
         item,
         value,
       }: any) => ConfigHelper.get(group, item) !== value)
       .groupBy('group')
       .forEach((
           data: any[],
           group: string,
       ) => {
         switch (group) {
           case 'connector':
             if (data.length === 1) {
               const connectorData = data.pop()

               if (connectorData.item === 'port') {
                 PortHelper.close()
                 PortHelper.setPort(connectorData.value)
               }
             }
             break

           case 'rfid':
           case 'qrcode1':
           case 'qrcode2':
             PortHelper.type[group].close()
             break
         }

         data.forEach(({
           item,
           value,
         }: any) => {
           ConfigHelper.set(group, item, value)

           switch (group) {
             case 'rfid':
             case 'qrcode1':
             case 'qrcode2':
               PortHelper.type[group].setItem(item, value)
               break
           }
         })
       })
       .value()

      ElMessage.success(LangHelper.trans('message.config_update_success'))
    }

    return {
      model,
      save,
    }
  },
})
