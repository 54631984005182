
import { defineComponent } from "vue"

import AuthHelper from "@/helpers/auth"
import LangHelper from "@/helpers/lang"
import { Icon } from "@iconify/vue"

export default defineComponent({
	components: { Icon },
	setup() {
		const mobileVersion = AuthHelper.mobileVersion
		const lang = LangHelper.lang

		function setLanguage(lang: string) {
			console.log(AuthHelper.logoutRedirect)
			LangHelper.SetLanguage(lang)
		}

		return {
			mobileVersion,
			lang,
			setLanguage
		}
	}
})
