
import { defineComponent, inject } from "vue"
import { ElMessage, ElMessageBox } from "element-plus/es"

import { DataHook } from "@/components/data-table/hooks"
import LangHelper from "@/helpers/lang"
import ResourceHelper from "@/helpers/resource"
import { Icon } from "@iconify/vue"

export default defineComponent({
	components: { Icon },
	props: {
		id: {
			type: [String, Number],
			required: true
		}
	},
	setup() {
		const data = inject<DataHook>("data-hook")!

		async function deleteRecord(id: number) {
			try {
				await ElMessageBox.confirm(
					LangHelper.trans("confirm.record_delete.message"),
					LangHelper.trans("confirm.record_delete.title"),
					{
						confirmButtonText: "Confirm",
						confirmButtonClass: "el-button--danger",
						cancelButtonText: LangHelper.trans("label.cancel"),
						type: "warning"
					}
				)
			} catch (error) {
				return
			}

			const response = await ResourceHelper.Delete(data.endPoint, [<number>id])

			if (response.affected === 0)
				return ElMessage.error(LangHelper.trans("message.record_delete_fail"))

			if (response.affected > 0) {
				await data.refresh()

				return ElMessage.success(
					LangHelper.trans("message.record_delete_success")
				)
			}

			ElMessage.error(LangHelper.trans("message.record_delete_fail"))
		}

		return {
			deleteRecord
		}
	}
})
