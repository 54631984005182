import dayjs, { ManipulateType } from 'dayjs'

export function getDateRange(
  amount: number,
  unit: ManipulateType,
) {
  const end   = dayjs()
    .hour(23)
    .minute(59)
    .second(59)
  const start = dayjs()
    .subtract(amount, unit)
    .hour(0)
    .minute(0)
    .second(0)

  return [ start.toDate(), end.toDate() ]
}

export const shortcuts = [
  // {
  //   text : 'Last week',
  //   value: () => getDateRange(1, 'week'),
  // },
  // {
  //   text : 'Last month',
  //   value: () => getDateRange(1, 'month'),
  // },
  // {
  //   text : 'Last quarter',
  //   value: () => getDateRange(3, 'month'),
  // },
  // {
  //   text : 'Last year',
  //   value: () => getDateRange(1, 'year'),
  // },
]
