
import {
  defineComponent,
  onMounted,
  ref,
  watch,
} from 'vue'

import AuthHelper   from '@/helpers/auth'
import ConfigHelper from '@/helpers/config'

export const defaultImage = '/img/svg/camera.svg'

export default defineComponent({
  props: {
    model   : {
      type    : Object,
      required: true,
    },
    preview : {
      type   : Boolean,
      default: false,
    },
    callback: {
      type   : Function,
      default: () => {
      },
    },
  },
  setup(props) {
    const mobileVersion = AuthHelper.mobileVersion
    const { model }     = props

    const ready        = ref<boolean>(false)
    const captured     = ref<boolean>(props.preview)
    const image        = ref<HTMLImageElement>()
    const previewImage = ref<string>('')

    function takePhoto() {
      const canvas = document.createElement('canvas')
      const ctx    = canvas.getContext('2d')
      const size   = image.value!.naturalHeight
      const margin = (image.value!.naturalWidth - size) / 2

      canvas.width  = size
      canvas.height = size

      // if (process.env.NODE_ENV === 'production')
        ctx!.drawImage(image.value!, margin, 0, size, size, 0, 0, size, size)
      try {
        model.visitorImage = canvas.toDataURL('image/jpeg')
      } catch (error) {
        console.log(error)
      }
      captured.value     = true

      props.callback(model.visitorImage)
    }

    function retake() {
      captured.value     = false
      model.visitorImage = defaultImage
    }

    function loadCamera() {
      const camera = {
        ip      : ConfigHelper.get('camera', 'ip'),
        port    : ConfigHelper.get('camera', 'port'),
        username: ConfigHelper.get('camera', 'username'),
        password: ConfigHelper.get('camera', 'password'),
      }

      if (process.env.NODE_ENV !== 'production') {
        camera.ip = '192.168.1.64'
        camera.port = '80'
        camera.username = 'admin'
        camera.password = 'ace123!!'
      }
      if (
          _.isEmpty(camera.ip) ||
          _.isEmpty(camera.port) ||
          _.isEmpty(camera.username) ||
          _.isEmpty(camera.password)
      ) {
        ready.value = false
        return
      }

      const query = _.map(camera, (value: string, item: string) => `${item}=${value}`)
                     .join('&')

      if (process.env.NODE_ENV !== 'production')
        previewImage.value = `${location.protocol}//${location.hostname}:3000/camera?${query}`
      else
        previewImage.value = `/camera?${query}`

      ready.value = true
    }

    watch(
        () => props.preview,
        (preview: boolean) => {
          if (!preview)
            loadCamera()
        },
        {
          immediate: true,
        },
    )

    return {
      mobileVersion,
      ready,
      captured,
      image,
      previewImage,
      takePhoto,
      retake,
    }
  },
})
