
import AuthHelper     from '@/helpers/auth'
import ResourceHelper from '@/helpers/resource'
import axios          from 'axios'
import {
  defineComponent,
  inject,
  ref,
}                     from 'vue'

import { EditorHook } from '@/components/data-table/hooks'
import LangHelper     from '@/helpers/lang'


export default defineComponent({
  setup() {
    const isStaff    = AuthHelper.isStaff
    const editorHook = inject<EditorHook>('editor-hook')!

    const tenants      = ref<any[]>([])

    const rawUserName = editorHook.model.userName

    editorHook.setCallback(() => {
      editorHook.model.password = ''
    })

    async function getRelatedData() {
      tenants.value = _.sortBy(await ResourceHelper.List('/tenant/profile'), [ 'tenantName' ])
    }

    async function checkUsername(
        rule: any,
        value: any,
        callback: any,
    ) {
      if (
          !_.isEmpty(rawUserName) &&
          rawUserName === value
      )
        return callback()

      const result = await axios.get(`/tenant/staff/check-duplicate-email/${value}`)

      if (
          !result ||
          result.status !== 200
      )
        return callback(new Error(LangHelper.trans('message.item_exist', LangHelper.trans('label.username'))))

      callback()
    }

    getRelatedData()

    return {
      isStaff,
      ...editorHook.expose,
      tenants,
      checkUsername,
    }
  },
})
