
import { computed, defineComponent, reactive, ref } from "vue"
import { ElInput } from "element-plus"
import dayjs from "dayjs"

import AuthHelper from "@/helpers/auth"
import ResourceHelper from "@/helpers/resource"
import UserHelper from "@/helpers/user"
import RecordHelper, { RecordType } from "@/helpers/record"
import { defaultImage } from "@/components/PhotoTaker.vue"

export default defineComponent({
	props: {
		model: {
			type: Object,
			required: true
		}
	},
	setup({ model }) {
		const name = ref<InstanceType<typeof ElInput>>()
		const types = ref<any[]>([])
		const blacklisted = ref<boolean>(false)
		const showBlacklistInfo = ref<boolean>(false)
		const blacklistInfo = reactive({
			image: defaultImage,
			phone: "",
			email: "",
			blacklistedBy: "",
			remarks: ""
		})
		const visitDate = computed({
			get: () => {
				const date = dayjs(model.visitDate)

				if (date.toDate().toString() === "Invalid Date") return null

				return dayjs(date.format("YYYY-MM-DD")).toDate()
			},
			set: (value) => {
				const date = dayjs(value).format("YYYY-MM-DD")
				let time = dayjs(model.visitDate).format("HH:mm")

				if (time === "Invalid Date") time = "00:00"

				model.visitDate = `${date} ${time}:00`
				model.visitSchFm = `${date} ${time}:00`
				model.visitSchTo = `${date} 23:59:00`
			}
		})
		const visitTime = computed({
			get: () => {
				const date = dayjs(model.visitDate).toDate()

				if (date.toString() === "Invalid Date") return ""

				return date
			},
			set: (value) => {
				const time = dayjs(value).format("HH:mm")
				let date = dayjs(model.visitDate).format("YYYY-MM-DD")

				if (date === "Invalid Date") date = dayjs().format("YYYY-MM-DD")

				model.visitDate = `${date} ${time}:00`
				model.visitSchFm = `${date} ${time}:00`
			}
		})

		async function checkBlacklist() {
			const data = {
				firstName: model.firstName,
				lastName: model.lastName
			}

			const blacklist = await UserHelper.checkBlacklist(data)

			if (blacklist) {
				blacklistInfo.image = blacklist.image || defaultImage
				blacklistInfo.phone = blacklist.phone
				blacklistInfo.email = blacklist.email
				blacklistInfo.blacklistedBy = RecordHelper.Get(
					RecordType.User,
					blacklist.voidBy
				)
				blacklistInfo.remarks = blacklist.remarks
			}

			blacklisted.value = blacklist !== false
			showBlacklistInfo.value = blacklist !== false
		}

		function focusName(focus: boolean = true) {
			if (focus) name.value?.focus()
			else name.value?.blur()
		}

		function reset() {
			visitDate.value = dayjs().toDate()

			blacklisted.value = false
			showBlacklistInfo.value = false
		}

		async function getRelatedData() {
			let list = await ResourceHelper.List("/visitor/type")

			if (AuthHelper.is.staff) list = list.filter((type) => type.tenantId < 0)

			types.value = list
		}

		getRelatedData()

		return {
			name,
			types,
			blacklisted,
			showBlacklistInfo,
			blacklistInfo,
			visitDate,
			visitTime,
			checkBlacklist,
			focusName,
			reset
		}
	}
})
