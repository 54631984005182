import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "gate-control__gate--wrapper" }
const _hoisted_2 = { class: "gate-control__gate--icon" }
const _hoisted_3 = { class: "gate-control__gate--name" }
const _hoisted_4 = { class: "gate-control__gate--action" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_button_group = _resolveComponent("el-button-group")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createBlock(_component_el_card, { class: "gate-control__gate" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Icon, { icon: "ic:twotone-door-back" })
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_3, "Gate - " + _toDisplayString(_ctx.gate.gateNumber), 1),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_el_button_group, { size: "small" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  type: _ctx.status === 2 ? 'success' : 'default',
                  plain: "",
                  onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.setGateStatus(2)), ["prevent"]))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.trans("label.open")), 1)
                  ]),
                  _: 1
                }, 8, ["type"]),
                _createVNode(_component_el_button, {
                  type: _ctx.status === 3 ? 'danger' : 'default',
                  plain: "",
                  onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.setGateStatus(3)), ["prevent"]))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.trans("label.normal")), 1)
                  ]),
                  _: 1
                }, 8, ["type"])
              ]),
              _: 1
            })
          ])
        ])
      ])
    ]),
    _: 1
  }))
}