import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!

  return (_openBlock(), _createBlock(_component_el_dropdown_item, {
    style: _normalizeStyle({ color: _ctx.color }),
    onClick: _ctx.changeStatus
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Icon, { icon: _ctx.icon }, null, 8, ["icon"]),
      (_ctx.status)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.trans("label.disable")), 1))
        : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.trans("label.enable")), 1))
    ]),
    _: 1
  }, 8, ["style", "onClick"]))
}