
import { defineComponent, ref } from "vue"
import { useRoute, useRouter } from "vue-router"

export default defineComponent({
	setup() {
		const title = ref<string>("")
		const router = useRouter()
		const route = useRoute()

		function updateBreadcrumb() {
			const { matched } = route

			title.value = matched
				.filter((route) => route.name)
				.map((route) => route.name as string)
				.pop()!
		}

		router.afterEach(updateBreadcrumb)
		updateBreadcrumb()

		return {
			title
		}
	}
})
