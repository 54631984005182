import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isStaff)
      ? (_openBlock(), _createBlock(_component_el_form_item, {
          key: 0,
          prop: "tenantId",
          label: _ctx.trans('label.tenant'),
          rules: {
                  required: true,
                  message: _ctx.trans('message.field_require', _ctx.trans('label.tenant')),
                }
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_select, {
              modelValue: _ctx.model.tenantId,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.tenantId) = $event)),
              placeholder: _ctx.trans('label.nothing-selected'),
              filterable: ""
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tenants, (tenant) => {
                  return (_openBlock(), _createBlock(_component_el_option, {
                    label: tenant.tenantName,
                    value: tenant.tenantId
                  }, null, 8, ["label", "value"]))
                }), 256))
              ]),
              _: 1
            }, 8, ["modelValue", "placeholder"])
          ]),
          _: 1
        }, 8, ["label", "rules"]))
      : _createCommentVNode("", true),
    _createVNode(_component_el_form_item, {
      prop: "name",
      label: _ctx.trans('label.name'),
      rules: {
                  required: true,
                  message: _ctx.trans('message.field_require', _ctx.trans('label.name')),
                }
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_input, {
          modelValue: _ctx.model.name,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.name) = $event)),
          maxlength: 50,
          "show-word-limit": ""
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["label", "rules"]),
    _createVNode(_component_el_form_item, {
      prop: "userName",
      label: _ctx.trans('label.login-email'),
      rules: [
                  {
                    type: 'email',
                    required: true,
                    message: _ctx.trans('message.field_not-email', _ctx.trans('label.login-email')),
                  },
                  { validator: _ctx.checkUsername }
                ]
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_input, {
          modelValue: _ctx.model.userName,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.userName) = $event)),
          maxlength: 200,
          disabled: _ctx.mode !== 'create',
          "show-word-limit": ""
        }, null, 8, ["modelValue", "disabled"])
      ]),
      _: 1
    }, 8, ["label", "rules"])
  ], 64))
}