import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_preview = _resolveComponent("preview")!
  const _component_wrapper_footer = _resolveComponent("wrapper-footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_card, {
      header: _ctx.trans('label.report-filter')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form, {
          ref: "form",
          "label-width": "140px",
          "label-position": "left",
          model: _ctx.model
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              prop: "lastname",
              label: _ctx.trans('label.last-name')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.model.lastname,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.lastname) = $event)),
                  maxlength: 50,
                  "show-word-limit": ""
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_form_item, {
              prop: "firstname",
              label: _ctx.trans('label.first-name')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.model.firstname,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.firstname) = $event)),
                  maxlength: 50,
                  "show-word-limit": ""
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_form_item, {
              prop: "phone",
              label: _ctx.trans('label.phone')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.model.phone,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.phone) = $event)),
                  maxlength: 20,
                  "show-word-limit": ""
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_form_item, {
              prop: "remarks",
              label: _ctx.trans('label.remarks')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.model.remarks,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.remarks) = $event)),
                  maxlength: 200,
                  "show-word-limit": ""
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_form_item, {
              prop: "date",
              label: _ctx.trans('label.blacklisted-date')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_date_picker, {
                  modelValue: _ctx.model.date,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.date) = $event)),
                  type: "datetimerange",
                  "unlink-panels": "",
                  "range-separator": "To",
                  format: "DD-MM-YYYY HH:mm",
                  "start-placeholder": _ctx.trans('label.start-date'),
                  "end-placeholder": _ctx.trans('label.end-date'),
                  shortcuts: _ctx.shortcuts
                }, null, 8, ["modelValue", "start-placeholder", "end-placeholder", "shortcuts"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_form_item, {
              prop: "voidBy",
              label: _ctx.trans('label.blacklisted-by')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: _ctx.model.voidBy,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.voidBy) = $event)),
                  placeholder: _ctx.trans('label.nothing-selected'),
                  filterable: ""
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.staffs, (staff) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        label: `Staff: ${staff.lastName} ${staff.firstName}`,
                        value: staff.userId
                      }, null, 8, ["label", "value"]))
                    }), 256)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tenants, (tenant) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        label: `Tenant: ${tenant.tenantName}`,
                        value: 10000000 + tenant.tenantId
                      }, null, 8, ["label", "value"]))
                    }), 256))
                  ]),
                  _: 1
                }, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_form_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  type: "primary",
                  onClick: _withModifiers(_ctx.filterData, ["prevent"])
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Icon, { icon: "ic:baseline-search" }),
                    _createElementVNode("span", null, _toDisplayString(_ctx.trans("label.search")), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model"])
      ]),
      _: 1
    }, 8, ["header"]),
    _createVNode(_component_preview, {
      fields: _ctx.fields,
      result: _ctx.result
    }, null, 8, ["fields", "result"]),
    _createVNode(_component_wrapper_footer, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_button, {
          type: "warning",
          onClick: _withModifiers(_ctx.exportData, ["prevent"])
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Icon, { icon: "ic:baseline-cloud-download" }),
            _createElementVNode("span", null, _toDisplayString(_ctx.trans("label.export")) + " (" + _toDisplayString(_ctx.result.length) + ")", 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    })
  ], 64))
}