
import {
  defineComponent,
  nextTick,
  onBeforeUnmount,
  onMounted,
  ref,
} from 'vue'

export default defineComponent({
  props: {
    fields: {
      type    : Object,
      required: true,
    },
    result: {
      type    : Object,
      required: true,
    },
    loading: {
      type    : Boolean,
    },
  },
  setup() {
    const maxHeight   = ref<number>(100)

    async function onResize() {
      await nextTick()

      maxHeight.value = document.body.clientHeight - 252
    }

    onMounted(() => {
      onResize()

      window.addEventListener('resize', onResize)
    })
    onBeforeUnmount(() => window.removeEventListener('resize', onResize))

    return {
      maxHeight,
    }
  },
})
