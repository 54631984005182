
import {
  defineComponent,
  PropType,
  provide,
  ref,
} from 'vue'

import {
  DataHook,
  EditorHook,
}                           from './hooks'
import { IDataTableConfig } from './types'
import DataTableActionBar   from './DataTableActionBar.vue'
import DataTableBody        from './DataTableBody.vue'
import DataTableEditor      from './DataTableEditor.vue'

export default defineComponent({
  components: {
    DataTableActionBar,
    DataTableBody,
    DataTableEditor,
  },
  props     : {
    config: {
      type    : Object as PropType<IDataTableConfig>,
      required: true,
    },
    afterDataLoaded: {
      type    : Function,
      required: false,
    },
  },
  setup({ config, afterDataLoaded }) {
    const editor   = ref<InstanceType<typeof DataTableEditor>>()
    const dataHook = new DataHook(config.data, config.table.columns)
    if(typeof afterDataLoaded === 'function') {
      dataHook.loadData(afterDataLoaded)
    } else {
      dataHook.loadData()
    }

    provide('data-hook', dataHook)

    if (config.editor) {
      const editorHook = new EditorHook(config.editor, dataHook.endPoint)

      editorHook.setCallback(() => dataHook.refresh())

      provide('editor-hook', editorHook)
    }

    return {
      editor,
    }
  },
})
