
import axios from "axios"
import dayjs from "dayjs"
import { defineComponent, onMounted, onUnmounted, reactive, ref } from "vue"
import { useRoute, useRouter } from "vue-router"
import { ElForm, ElMessage } from "element-plus"
import hotkeys, { HotkeysEvent } from "hotkeys-js"

import AuthHelper from "@/helpers/auth"
import LangHelper from "@/helpers/lang"
import ResourceHelper from "@/helpers/resource"
import WrapperFooter from "@/views/wrapper/WrapperFooter.vue"
import { defaultImage } from "@/components/PhotoTaker.vue"

import VisitingCompanies from "../components/VisitingCompanies.vue"
import VisitorInformation from "../components/VisitorInformation.vue"
import HostInformation from "../components/HostInformation.vue"
import VisitorList from "../components/VisitorList.vue"

export default defineComponent({
	components: {
		VisitingCompanies,
		VisitorInformation,
		HostInformation,
		VisitorList,
		WrapperFooter
	},
	setup() {
		const tenantId = AuthHelper.userId.value
		const defaultModel = {
			visitorCompany: "",
			visitDate: null,
			tenantId: AuthHelper.is.staff ? null : tenantId,
			tenantUserId:
				AuthHelper.tenantUserID.value >= 0
					? AuthHelper.tenantUserID.value
					: null,
			floorIds: [],
			visitorType: 1,
			remarks: "",

			contactPerson: "",
			contactNo: "",
			contactEmail: "",
			groupName: "",

			visitSchFm: "",
			visitSchTo: "",

			hasAlert: false
		}

		const model = reactive<any>({ ...defaultModel })
		const activeTab = ref<string>("default")
		const form = ref<InstanceType<typeof ElForm>>()
		const visitingCompanies = ref<InstanceType<typeof VisitingCompanies>>()
		const visitorInformation = ref<InstanceType<typeof VisitorInformation>>()
		const visitorList = ref<InstanceType<typeof VisitorList>>()
		const hasId = ref<boolean>(false)
		const route = useRoute()
		const router = useRouter()

		let ajaxBlock = false
		let record: any = false
		let oldId = -1

		onMounted(async () => {
			hotkeys("f1,f3,f8", (event: KeyboardEvent, handler: HotkeysEvent) => {
				event.preventDefault()
				event.stopPropagation()

				switch (handler.key) {
					case "f1":
						resetModel()
						break

					case "f3":
						model.RFID = ""
						break

					case "f8":
						saveVisitor()
						break
				}
			})

			if (_.has(route.query, "id")) {
				oldId = _.toNumber(route.query.id)
				hasId.value = true

				record = await ResourceHelper.Get("/visitor/group", oldId)

				const current = dayjs().add(15, "minute")
				const visitTime = dayjs(record.visitDate)

				if (current.diff(visitTime) > 0) {
					cancelEditPage()
				} else {
					model.tenantUserId = record.tenantUserId
					model.tenantId = record.tenantId
					model.visitorCompany = record.visitorCompany
					model.visitorType = record.visitorType
					model.remarks = record.remarks
					model.groupName = record.groupName
					model.contactPerson = record.contactPerson
					model.contactNo = record.contactNo
					model.contactEmail = record.contactEmail

					visitingCompanies.value!.visitModel.combineId = `${record.tenantId}-${record.floorIds.floorIds[0]}`
					visitingCompanies.value!.addVisitCompany()
					visitorInformation.value!.setVisitDateTime(dayjs(record.visitDate))
					visitorList.value!.setVisitors(record.visitors)
				}
			} else {
				resetModel()
			}
		})
		onUnmounted(() => hotkeys.unbind())

		function resetModel() {
			_.forEach(defaultModel, (value: any, key: string) => {
				if (AuthHelper.is.staff || key != "floorIds") model[key] = value
			})

			const datetime = dayjs().hour(9).minute(0).toDate()

			visitingCompanies.value!.clearVisitCompany()

			visitorInformation.value!.visitDate = datetime
			visitorInformation.value!.visitTime = datetime

			visitorList.value!.visitors = []

			setTimeout(() => form.value!.clearValidate(), 500)
		}

		async function createVisitorGroup(visitors: any[]): Promise<any> {
			const groupData = {
				groupName: model.groupName,
				visitorGroupTypeId: model.visitorType,
				numberOfVisitors: visitors.length,
				visitDate: model.visitDate,
				visitSchFm: model.visitSchFm,
				visitSchTo: model.visitSchTo,
				sponsorFirstName: model.visitorCompany,
				contactPerson: model.contactPerson,
				contactNo: model.contactNo,
				email: model.contactEmail,
				tenantId: model.tenantId,
				tenantUserId: model.tenantUserId,

				suiteId: -1,
				isWebPortal: true,
				remarks: model.remarks
			}

			const visitorGroup = await ResourceHelper.Create(
				"/visitor/group",
				groupData
			)

			if (!visitorGroup.success) throw new Error()

			return visitorGroup
		}

		async function createVisitorGeneral(
			visitorGroupId: number,
			visitor: any
		): Promise<any> {
			const visitorData = {
				visitorGroupId,

				otherName: model.groupName,
				visitorCompany: model.visitorCompany,
				visitDate: model.visitDate,
				visitSchFm: model.visitSchFm,
				visitSchTo: model.visitSchTo,
				visitorType: model.visitorType,
				tenantId: model.tenantId,
				remarks: model.remarks,

				visitorImage: defaultImage,
				salutation: "",
				idTypeId: 1,
				idCardNo: "",
				email: "",
				RFID: "",
				...visitor
			}

			return await ResourceHelper.Create("/visitor/general", visitorData)
		}

		async function createVisitorContact(
			visitorId: number,
			floorId: number
		): Promise<any> {
			const contactData = {
				visitorId,
				contactPerson: model.contactPerson,
				contactNo: model.contactNo,
				tenantId: model.tenantId,
				floorId
			}

			return await ResourceHelper.Create("/visitor/contact", contactData)
		}

		async function updateVisitorContact(
			visitorId: number,
			floorIds: number
		): Promise<any> {
			const contacts = await ResourceHelper.Get("/visitor/contact", visitorId)
			const contactIds = contacts.map((item: any) => item._id)

			for (let floorId of model.floorIds)
				await createVisitorContact(visitorId, floorId)

			return await ResourceHelper.Delete("/visitor/contact", contactIds)
		}

		async function saveVisitor() {
			if (ajaxBlock) return
			if (!ajaxBlock) ajaxBlock = true

			try {
				ajaxBlock = false

				if (record) {
					const current = dayjs().add(15, "minute")
					const visitTime = dayjs(record.visitDate)

					if (current.diff(visitTime) > 0) {
						// cancelEditPage()
						return ElMessage.error(
							LangHelper.trans("message.operation-timeout")
						)
					}
				}

				await visitingCompanies.value!.visitForm!.validate()
				model.tenantId =
					visitingCompanies.value!.visitDisplayList[0].company.tenantId
				model.floorIds = [
					visitingCompanies.value!.visitDisplayList[0].floor.floorId
				]

				const visitors = visitorList.value!.getVisitors().map((visitor) => {
					// if (visitor.visitorId) delete visitor.visitorId
					return visitor
				})
				model.hasAlert = visitors.some((visitor) => visitor.alert)

				await form.value!.validate()

				if (!visitorList.value!.checkVisitors())
					return ElMessage.error(
						LangHelper.trans("message.visitor_invalid_fail")
					)

				if (visitors.length === 0)
					return ElMessage.error(
						LangHelper.trans("message.visitor_not-found_fail")
					)

				if (
					visitors.some(
						(visitor) =>
							visitors.filter(
								(item) => item.visitorEmail === visitor.visitorEmail
							).length > 1
					)
				)
					return ElMessage.error(
						LangHelper.trans("message.visitors_email-duplicate_fail")
					)

				// new record
				if (!record) {
					const visitorGroup = await createVisitorGroup(visitors)

					for (let visitor of visitors) {
						const visitorGeneral = await createVisitorGeneral(
							visitorGroup.id,
							visitor
						)

						if (!visitorGeneral.success) continue

						await ResourceHelper.Update("/visitor/general", visitorGeneral.id, {
							RFID: btoa(`c:${model.tenantId}_v:${visitorGeneral.id}_t:1`)
						})

						for (let floorId of model.floorIds)
							await createVisitorContact(visitorGeneral.id, floorId)
					}

					// let redirectToNewId = false

					// if (oldId !== -1) {
					// 	redirectToNewId = true
					// 	await ResourceHelper.Delete("/visitor/group", [oldId])
					// 	hasId.value = false
					// 	oldId = -1
					// }

					// if (oldId !== -1) {
					// 	// send update email
					// 	// axios.get(`/visitor/group/update-mail/${visitorGroup.id}`)

					// 	ElMessage.success(
					// 		LangHelper.trans(
					// 			"message.item_update_success",
					// 			LangHelper.trans("label.visitor-group")
					// 		)
					// 	)
					// } else {
					// send create email
					axios.get(`/visitor/group/mail/${visitorGroup.id}`)

					ElMessage.success(
						LangHelper.trans(
							"message.visitor_create_success",
							LangHelper.trans("label.visitor-group")
						)
					)

					resetModel()
					// }

					// if (redirectToNewId) {
					// 	router.push(`/visitor/pre-reg-management?id=${visitorGroup.id}`)
					// }

					// edit record
				} else {
					const hostFields = [
						"tenantId",
						"floorIds",
						"visitDate",
						"visitorCompany",
						"visitorType",
						"remarks",
						"contactPerson",
						"contactNo",
						"contactEmail",
						"groupName"
					]
					const visitorFields = [
						"salutation",
						"lastName",
						"firstName",
						"contactNo",
						"visitorEmail",
						"entryType",
						"status",
						"alert"
					]

					let hasUpdatedHost = false
					let hasUpdatedVisitors =
						!record || record.visitors.length !== visitors.length

					// check model updated or not
					hasUpdatedHost = hostFields.some((field) => {
						if (field == "floorIds") {
							return record[field][field][0] !== model[field][0]
						} else if (field == "visitDate") {
							return record[field] + ":00" !== model[field]
						} else {
							return record[field] !== model[field]
						}
					})

					// check visitors different
					if (!hasUpdatedVisitors) {
						hasUpdatedVisitors = [
							// find deleted or updated visitors
							record.visitors.some((visitor: any) => {
								const existVisitor = visitors.find(
									(item) => item.visitorEmail == visitor.visitorEmail
								)

								return (
									!existVisitor ||
									visitorFields.some(
										(field) => visitor[field] !== existVisitor[field]
									)
								)
							}),

							// find new visitors
							visitors.some(
								(visitor: any) =>
									!record.visitors.find(
										(item: any) => item.visitorEmail == visitor.visitorEmail
									)
							)
						].some((hasUpdated) => hasUpdated)
					}

					// nothing changes
					if (!hasUpdatedHost && !hasUpdatedVisitors) {
						return ElMessage.error(
							LangHelper.trans("message.visitors_no_updated")
						)
					} else {
						// handle group
						if (hasUpdatedHost) {
							const groupData = {
								groupName: model.groupName,
								visitorGroupTypeId: model.visitorType,
								numberOfVisitors: visitors.length,
								visitDate: model.visitDate,
								visitSchFm: model.visitSchFm,
								visitSchTo: model.visitSchTo,
								sponsorFirstName: model.visitorCompany,
								contactPerson: model.contactPerson,
								contactNo: model.contactNo,
								email: model.contactEmail,
								tenantId: model.tenantId,
								tenantUserId: model.tenantUserId,
								remarks: model.remarks
							}

							await ResourceHelper.Update("/visitor/group", oldId, groupData)
						} else {
							const groupData = {
								numberOfVisitors: visitors.length
							}

							await ResourceHelper.Update("/visitor/group", oldId, groupData)
						}

						// handle visitors
						if (hasUpdatedHost || hasUpdatedVisitors) {
							// find deleted visitors
							record.visitors
								.filter(
									(visitor: any) =>
										!visitors
											.filter((item) => item.visitorId)
											.find((item) => item.visitorId == visitor.visitorId)
								)
								.map(async (visitor: any) => {
									console.log(
										"deleted",
										visitor.visitorId,
										visitor.visitorEmail
									)
									await ResourceHelper.Delete("/visitor/pre-reg", [
										visitor.visitorId
									])

									return visitor
								})

							// update and create visitors
							visitors.map(async (visitor) => {
								const existVisitor =
									visitor.visitorId &&
									record.visitors.find(
										(item: any) => item.visitorId == visitor.visitorId
									)

								if (!existVisitor) {
									// create visitor
									const visitorGeneral = await createVisitorGeneral(
										oldId,
										visitor
									)

									if (visitorGeneral.success) {
										await ResourceHelper.Update(
											"/visitor/general",
											visitorGeneral.id,
											{
												RFID: btoa(
													`c:${model.tenantId}_v:${visitorGeneral.id}_t:1`
												)
											}
										)

										for (let floorId of model.floorIds)
											await createVisitorContact(visitorGeneral.id, floorId)

										// send create email
										axios.get(`visitor/pre-reg/mail/${visitorGeneral.id}`)
									}

									visitor.visitorId = visitorGeneral.id
								} else if (
									hasUpdatedHost ||
									visitorFields.some(
										(field) => visitor[field] !== existVisitor[field]
									)
								) {
									// edit visitor
									console.log(
										"updated",
										visitor.visitorId,
										visitor.visitorEmail
									)

									const visitorData = {
										otherName: model.groupName,
										visitorCompany: model.visitorCompany,
										visitDate: model.visitDate,
										visitSchFm: model.visitSchFm,
										visitSchTo: model.visitSchTo,
										visitorType: model.visitorType,
										tenantId: model.tenantId,
										remarks: model.remarks,
										salutation: visitor.salutation,
										lastName: visitor.lastName,
										firstName: visitor.firstName,
										contactNo: visitor.contactNo,
										visitorEmail: visitor.visitorEmail,
										entryType: visitor.entryType,
										status: visitor.status,
										alert: visitor.alert
									}

									if (hasUpdatedHost) {
										await updateVisitorContact(
											visitor.visitorId,
											model.floorIds
										)
									}

									await ResourceHelper.Update(
										"/visitor/pre-reg",
										visitor.visitorId,
										visitorData
									)
								}

								return visitor
							})
						}

						ElMessage.success(
							LangHelper.trans("message.visitor_update_success")
						)

						// router.push(`/visitor/pre-reg-management?id=${oldId}`)
					}
				}
			} catch (e) {
				console.log(e)
				ElMessage.error(
					LangHelper.trans(
						"message.item_create_fail",
						LangHelper.trans("label.visitor-group")
					)
				)
				ajaxBlock = false
			}
		}

		const cancelEditPage = () => {
			if (oldId !== -1) {
				router.push(`/visitor/pre-reg-management?id=${oldId}`)
			} else {
				router.push(`/dashboard`)
			}
		}

		const deleteVisitorGroup = async () => {
			if (oldId <= 0) return

			try {
				await ResourceHelper.Delete("/visitor/group", [oldId])

				// send delete email
				// axios.get(`/visitor/group/delete-mail/${oldId}`)

				ElMessage.success(
					LangHelper.trans(
						"message.record_delete_success",
						LangHelper.trans("label.visitor-group")
					)
				)

				router.push(`/visitor/pre-reg-management`)
			} catch (e) {
				ElMessage.error(
					LangHelper.trans(
						"message.record_delete_fail",
						LangHelper.trans("label.visitor-group")
					)
				)
			}
		}

		return {
			model,
			activeTab,
			form,
			visitingCompanies,
			visitorInformation,
			visitorList,
			hasId,
			saveVisitor,
			cancelEditPage,
			deleteVisitorGroup
		}
	}
})
