import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_nav = _resolveComponent("app-nav")!
  const _component_app_breadcrumb = _resolveComponent("app-breadcrumb")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_container, {
      class: "wrapper",
      direction: _ctx.mobileVersion ? 'vertical' : 'horizontal'
    }, {
      default: _withCtx(() => [
        (_ctx.menuOpen)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.mobileVersion ? _ctx.ElHeader : _ctx.ElAside), {
              key: 0,
              class: _normalizeClass(['nav', { 'is-mobile': _ctx.mobileVersion }])
            }, {
              default: _withCtx(() => [
                _createVNode(_component_app_nav)
              ]),
              _: 1
            }, 8, ["class"]))
          : _createCommentVNode("", true),
        _createVNode(_component_el_container, {
          direction: "vertical",
          class: _normalizeClass([
				'wrapper__body',
				{
					'is-mobile': _ctx.mobileVersion,
					'is-dashboard': _ctx.isDashboard
				}
			])
        }, {
          default: _withCtx(() => [
            (!_ctx.mobileVersion)
              ? (_openBlock(), _createBlock(_component_app_breadcrumb, {
                  key: 0,
                  class: "wrapper__body--breadcrumb"
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_el_main, { class: "wrapper__body--content" }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createVNode(_component_router_view)
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["class"])
      ]),
      _: 1
    }, 8, ["direction"]),
    (!_ctx.mobileVersion)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(`toggle-menu ${_ctx.menuOpen ? '' : 'shadow'}`),
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleMenu && _ctx.toggleMenu(...args)))
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.menuOpen ? 'show' : 'hide')
          }, [
            _createVNode(_component_Icon, { icon: "ic:baseline-menu-open" })
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.menuOpen ? 'hide' : 'show')
          }, [
            _createVNode(_component_Icon, { icon: "ic:baseline-menu" })
          ], 2)
        ], 2))
      : _createCommentVNode("", true)
  ], 64))
}