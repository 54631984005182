
import axios from "axios"
import LangHelper from "@/helpers/lang"
import { Html5Qrcode } from "html5-qrcode"
import * as QrCode from "qrcode"
import { ElMessage } from "element-plus"
import {
	defineComponent,
	onBeforeUnmount,
	onMounted,
	ref,
	toRef,
	watch
} from "vue"

import AuthHelper from "@/helpers/auth"
import PortHelper from "@/helpers/port"

export default defineComponent({
	props: {
		codeType: {
			type: String
		},
		model: {
			type: Object,
			required: true
		},
		preview: {
			type: Boolean,
			default: true
		},
		validate: {
			type: Boolean,
			default: false
		}
	},
	setup({ model, codeType }) {
		let scanner: Html5Qrcode
		const codeScanner = ref<boolean>(false)
		const codeImage = ref<string>("")
		const scanResult = ref<string>("")
		let isCameraFailed = false

		const isReaderConfigured = PortHelper.isConfigured

		const decodeLength = 120

		let reading: boolean = false
		let callback: Function | undefined = undefined

		function setCallback(cb: Function) {
			callback = cb
		}

		function showCodeScanner() {
			codeScanner.value = true
		}

		function hideCodeScanner() {
			codeScanner.value = false
		}

		function showScannerPreview() {
			if (!scanner) scanner = new Html5Qrcode("code-scanner")

			scanner.start(
				{
					facingMode: "environment"
				},
				{
					fps: 10,
					qrbox: {
						width: 250,
						height: 250
					}
				},
				(decodedText: string) => {
					if (reading) return

					onDataRead(decodedText)
					hideCodeScanner()
				},
				() => {}
			)
		}

		function hideScannerPreview() {
			scanner.stop()
		}

		async function onDataRead(data: string) {
			reading = true

			let code

			if (data.length > decodeLength) {
				const visitorData = await decodeData(data)

				code = visitorData.RFID

				if (visitorData.message && visitorData.message !== "") {
					ElMessage.error(LangHelper.trans(codeType + '.decode_failed'))
				}
			} else {
				data = data.replace("\u0002", "")
				data = data.replace("\r", "")
				data = data.split(",")[0]

				code = data
			}

			if (!_.isEmpty(code)) code = await mapCode(code)

			model.RFID = code
			reading = false

			if (callback) callback(code)
		}

		async function decodeData(data: string): Promise<any> {
			try {
				const response = await axios.post("/visitor/general/decode", { data })

				return response.data
			} catch {
				return ""
			}
		}

		async function mapCode(code: string): Promise<string> {
			const response = await axios.get(`/visitor/general/map/${code}`)

			return response.data.code
		}

		watch(
			toRef(model, "RFID"),
			async (code: string) => {
				if (code === "") codeImage.value = "/img/svg/scan.svg"
				else
					QrCode.toDataURL(
						code,
						{
							margin: 0,
							color: {
								dark: "#3db2c7",
								light: "#ffffff"
							}
						},
						(_: any, url: string) => (codeImage.value = url)
					)
			},
			{ immediate: true }
		)

		onMounted(() => PortHelper.startReading(onDataRead))
		onBeforeUnmount(() => PortHelper.stopReading())

		// TODO: Simulate read card data
		// setTimeout(() => onDataRead('1EF22F53'), 5000)

		return {
			codeScanner,
			codeImage,
			scanResult,
			isReaderConfigured,
			isCameraFailed,
			setCallback,
			showCodeScanner,
			showScannerPreview,
			hideScannerPreview
		}
	}
})
