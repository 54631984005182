import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "nav--icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['nav__language', { 'is-mobile': _ctx.mobileVersion }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Icon, { icon: "ic:baseline-language" })
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(['nav__language--lang', { 'is-active': _ctx.lang === 'en' }]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setLanguage('en')))
    }, " EN ", 2),
    _createElementVNode("div", {
      class: _normalizeClass(['nav__language--lang', { 'is-active': _ctx.lang === 'tw' }]),
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setLanguage('tw')))
    }, " 繁體 ", 2)
  ], 2))
}