
import { defineComponent, reactive } from "vue"
import LangHelper from "@/helpers/lang"

export default defineComponent({
	props: {
		model: {
			type: Object,
			required: true,
		},
		disabled: {
			type: Boolean,
		},
	},
	setup({ disabled }) {
		// const phoneRegex = /^[0-9\+]{1}[0-9a-zA-Z]{7,}$/;
		const phoneRegex = /^[\w\s\d]{8,}$/

		let rule = {
			required: !disabled,
			message: LangHelper.trans(
				"message.no_field_require",
				LangHelper.trans("label.contact-no")
			),
		} as any
		if (!disabled) {
			rule.min = 8
		}

		function checkPhone(rule: any, value: any, callback: any) {
			if (disabled) return callback()
			if (!phoneRegex.test(value))
				return callback(
					new Error(
						LangHelper.trans(
							"message.item_invalid_fail",
							LangHelper.trans("label.contact-no")
						)
					)
				)

			callback()
		}

		const contactNoRule = [reactive(rule), { validator: checkPhone }]

		return {
			contactNoRule,
		}
	},
})
