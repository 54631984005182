import LangHelper from '@/helpers/lang'
import dayjs      from 'dayjs'

import {
  IDataTableConfig,
  IDTActionBarConfig,
  IDTDataConfig,
  IDTEditorConfig,
  IDTTableConfig,
}                                   from '@/components/data-table/types'
import RecordHelper, { RecordType } from '@/helpers/record'

import TenantStaffEditor from './TenantStaffEditor.vue'

const data = <IDTDataConfig>{
  source: '/tenant/staff',
  parser: (row: any) => {
    row.tenant     = RecordHelper.Get(RecordType.User, row.tenantId + 10000000)
    row.createUser = RecordHelper.Get(RecordType.User, row.createBy)

    return row
  },
}

const actionBar = <IDTActionBarConfig>{
  search: true,
  create: true,
}

const table = <IDTTableConfig>{
  columns: [
    {
      title   : LangHelper.trans('label.tenant'),
      prop    : 'tenant',
      sortable: true,
    },
    {
      title   : LangHelper.trans('label.name'),
      prop    : 'name',
      sortable: true,
    },
    {
      title   : LangHelper.trans('label.login-email'),
      prop    : 'userName',
      sortable: true,
    },
    {
      title   : LangHelper.trans('label.created-by'),
      prop    : 'createUser',
      sortable: true,
    },
    {
      title    : LangHelper.trans('label.created-date'),
      prop     : 'createDate',
      sortable : true,
      formatter: ({ cellValue }: any) => dayjs(cellValue)
        // .utc()
        .format('YYYY-MM-DD HH:mm'),
    },
  ],
  action : {
    mode  : 'edit',
    delete: true,
  },
}

const editor = <IDTEditorConfig>{
  title       : LangHelper.trans('label.tenant-staff'),
  component   : TenantStaffEditor,
  delete      : true,
  defaultModel: {
    tenantId: null,
    name    : '',
    userName: '',
    password: '5f4dcc3b5aa765d61d83',
    isAdmin : false,
  },
}

export default <IDataTableConfig>{
  data,
  actionBar,
  table,
  editor,
}
