
import { defineComponent, reactive, ref, watch } from "vue"
import axios from "axios"
import dayjs from "dayjs"
import { ElMessage, ElMessageBox } from "element-plus/es"

import { CodeScanner, PhotoTaker } from "@/components"
import LangHelper from "@/helpers/lang"
import ResourceHelper from "@/helpers/resource"
import WrapperFooter from "@/views/wrapper/WrapperFooter.vue"

import VisitorProfile from "./VisitorProfile.vue"

import VisitingCompanies from "../components/VisitingCompanies.vue"
import { defaultModel } from "../components/config"

export default defineComponent({
	components: {
		VisitorProfile,
		PhotoTaker,
		CodeScanner,
		VisitingCompanies,
		WrapperFooter
	},
	setup() {
		const show = ref<boolean>(false)
		const showAllowEntryBtn = ref<boolean>(true)
		const companies = ref<any[]>([])
		const scanModel = reactive<any>({
			RFID: ""
		})
		const model = reactive<any>({ ...defaultModel })
		const visitCompanies = ref<any[]>([])
		const expired = ref<boolean>(false)
		const isDeleted = ref<boolean>(false)
		const isVRDeleted = ref<boolean>(false)
		const photoTaker = ref<InstanceType<typeof PhotoTaker>>()
		const visitorProfile = ref<InstanceType<typeof VisitorProfile>>()
		let photo = ref<any>(undefined)

		function takePhoto() {
			if (photoTaker.value?.ready) photoTaker.value!.takePhoto()
		}

		let recordId = -1

		watch(
			() => scanModel.RFID,
			_.debounce(async (code: string) => {
				const visitor = await axios.get(`/visitor/general/find/qrcode/${code}`)

				if (!visitor || visitor.status !== 200) {
					show.value = false

					return
				}

				if (
					visitor.data.isBlacklisted ||
					visitor.data.isVRDeleted ||
					visitor.data.isDeleted
				) {
					isVRDeleted.value = visitor.data.isVRDeleted ?? false

					await ElMessageBox.alert(
						LangHelper.trans(
							"alert.visitor_problem.message",
							visitor.data.isDeleted
								? "disabled"
								: visitor.data.isBlacklisted
								? "blacklisted"
								: "deleted"
						),
						LangHelper.trans("alert.visitor_problem.title"),
						{
							confirmButtonClass: "el-button--danger",
							confirmButtonText: "I understand"
						}
					)
				}

				Object.keys(model).forEach(
					(key: string) => (model![key] = visitor.data[key])
				)

				if (_.isEmpty(visitor.data.visitDate)) expired.value = true
				else {
					const visitDate = dayjs(visitor.data.visitDate)
					const todayDate = dayjs()
					const dateFormat = "YYYY-MM-DD"

					model.visitDate = visitDate.toDate()

					expired.value =
						visitDate.format(dateFormat) < todayDate.format(dateFormat)
				}

				const visitingCompanies = await axios.get(
					`/visitor/contact/${visitor.data._id}`
				)

				if (visitingCompanies.status === 200)
					visitCompanies.value = visitingCompanies.data

				recordId = visitor.data._id

				show.value = true
				await visitorProfile.value!.checkBlacklist()

				if (
					visitor.data.status === 1 &&
					visitor.data.entryType === "Single-entry"
				) {
					await ElMessageBox.alert(
						LangHelper.trans("alert.qrcode_scanned.message"),
						LangHelper.trans("alert.qrcode_scanned.title"),
						{
							confirmButtonClass: "el-button--danger",
							confirmButtonText: "I understand"
						}
					)

					showAllowEntryBtn.value = false
				}

				if (expired.value) {
					showAllowEntryBtn.value = false
				}
			}, 250)
		)

		async function updateVisitorImage(visitorImage: string) {
			const result = await ResourceHelper.Update("/visitor/general", recordId, {
				visitorImage
			})

			if (!result.success)
				return ElMessage.error(
					LangHelper.trans("message.visitor_upload-image_fail")
				)

			ElMessage.success(
				LangHelper.trans("message.visitor_upload-image_success")
			)
		}

		async function allowVisit() {
			if (!photoTaker.value?.captured) takePhoto()

			const allow = await axios.get(`/visitor/general/allow/${recordId}`)

			if (!allow || allow.status !== 200 || !allow.data.success)
				return ElMessage.error(LangHelper.trans("message.visitor_allow_fail"))

			ElMessage.success(LangHelper.trans("message.visitor_entry_success"))
		}

		async function getRelatedData() {
			companies.value = await ResourceHelper.List("/tenant/profile")
		}

		getRelatedData()

		return {
			show,
			showAllowEntryBtn,
			companies,
			scanModel,
			model,
			visitCompanies,
			expired,
			isDeleted,
			isVRDeleted,
			photoTaker,
			visitorProfile,
			allowVisit,
			updateVisitorImage
		}
	}
})
