
import ResourceHelper from "@/helpers/resource"
import axios from "axios"
import { defineComponent, inject, ref } from "vue"

import { PhotoTaker } from "@/components"
import { EditorHook } from "@/components/data-table/hooks"
import { defaultImage } from "@/components/PhotoTaker.vue"
import { ElMessage } from "element-plus"
import LangHelper from "@/helpers/lang"

export default defineComponent({
	components: {
		PhotoTaker
	},
	setup() {
		const selectImage = ref<HTMLInputElement>()
		const editorHook = inject<EditorHook>("editor-hook")!

		const staffs = ref<any[]>([])
		const tenants = ref<any[]>([])
		const visitorImage = ref(defaultImage)

		async function getRelatedData() {
			staffs.value = await ResourceHelper.List("/staff/user")
			tenants.value = _.sortBy(await ResourceHelper.List("/tenant/profile"), [
				"tenantName"
			])

			const { data: response } = await axios.post("/blacklist/check", {
				salutation: editorHook.model.salutation,
				firstName: editorHook.model.firstName,
				lastName: editorHook.model.lastName,
				phone: editorHook.model.phone,
				email: editorHook.model.email
			})

			if (response.record.withImage) visitorImage.value = response.record.image
		}

		function uploadImage(event: InputEvent) {
			const target = event.target as HTMLInputElement
			const files = target.files

			if (
				files === null ||
				files!.length !== 1 ||
				!files[0].type.startsWith("image/")
				|| files[0].size / 1024 / 1024 > 5
			)
			{
				ElMessage.error(LangHelper.trans('message.upload_limit_remarks'))
				return
			}
			const reader = new FileReader()

			reader.addEventListener("load", readImage)
			reader.readAsDataURL(files[0])
		}

		function readImage(event: ProgressEvent<FileReader>) {
			editorHook.model.image = event.target!.result as string
		}

		getRelatedData()

		return {
			selectImage,
			staffs,
			tenants,
			...editorHook.expose,
			visitorImage,
			uploadImage
		}
	}
})
