
import { defineComponent, inject, ref, watch } from "vue"
import dayjs from "dayjs"
import _ from "lodash"
import { shortcuts } from "@/helpers/date"

import { DataHook } from "../hooks"
import { Icon } from "@iconify/vue"

export default defineComponent({
	components: {
		Icon
	},
	props: {
		type: {
			type: [Boolean, String]
		},
		defaultDate: {
			type: Object
		}
	},
	setup({ type, defaultDate }) {
		const data = inject<DataHook>("data-hook")!
		const value = ref<string>("")
		const date =
			defaultDate ||
			ref<Date[]>([
				dayjs().set("hour", 0).set("minute", 0).set("second", 0).toDate(),
				dayjs()
					.add(1, "day")
					.set("hour", 0)
					.set("minute", 0)
					.set("second", 0)
					.toDate()
			])

		let timer: NodeJS.Timeout

		function search() {
			data.loading.value = true
			if (typeof timer === "number") clearTimeout(timer)
			timer = setTimeout(() => {
				data.search(value.value)
				data.loading.value = false
			}, 800)
		}

		function searchByDate() {
			data.searchByDate(date.value ?? [])
		}

		function searchByVisitDate() {
			data.searchByVisitDate(date.value ?? [])
		}

		if (type === "column") {
			search()
		} else if (type === "date") {
			watch(date, searchByDate)
			searchByDate()
		} else {
			watch(date, searchByVisitDate)
		}

		return {
			value,
			date,
			shortcuts,
			search
		}
	}
})
