
import { computed, defineComponent, reactive, ref } from "vue"
import { ElInput, ElTable } from "element-plus"
import dayjs from "dayjs"

import AuthHelper from "@/helpers/auth"
import ResourceHelper from "@/helpers/resource"
import UserHelper from "@/helpers/user"
import RecordHelper, { RecordType } from "@/helpers/record"
import { defaultImage } from "@/components/PhotoTaker.vue"
export default defineComponent({
	props: {
		model: {
			type: Object,
			required: true
		}
	},
	setup({ model }) {
		const visitorImage = ref(defaultImage)
		const name = ref<InstanceType<typeof ElInput>>()
		const types = ref<any[]>([])
		const blacklisted = ref<boolean>(false)
		const showBlacklistInfo = ref<boolean>(false)
		const blacklists = ref<any[]>([])
		const blacklistInfo = reactive({
			image: defaultImage,
			phone: "",
			email: "",
			blacklistedBy: "",
			remarks: ""
		})
		let currentRow = ref()
		const blackListTableRef = ref<InstanceType<typeof ElTable>>()
		const setCurrent = (row: any | undefined) => {
			blackListTableRef.value!.setCurrentRow(row)
		}
		const handleCurrentChange = (val: any | undefined) => {
			currentRow.value = val
			console.log(currentRow)
		}
		const visitDate = computed({
			get: () => {
				const date = dayjs(model.visitDate).toDate()

				if (date.toString() === "Invalid Date") return null

				return date
			},
			set: (value) => {
				model.visitDate = `${dayjs(value).format("YYYY-MM-DD")} 00:00:00`
				model.visitSchFm = `${dayjs(value).format("YYYY-MM-DD")} 00:00:00`
				model.visitSchTo = `${dayjs(value).format("YYYY-MM-DD")} 23:59:00`
			}
		})


		async function checkBlacklist() {
			const data = {
				firstName: model.firstName,
				lastName: model.lastName
			}

			blacklists.value = await UserHelper.checkBlacklist(data)
			if (blacklists.value) blacklists.value = blacklists.value.slice(0, 4)

			if (blacklists.value.length > 0) {
				_.forEach(blacklists.value, (value: any, key: string) => {
					value.image = value.image || defaultImage

					value.phone = value.phone
					value.email = value.email
					value.blacklistedBy = RecordHelper.Get(RecordType.User, value.voidBy)
					value.remarks = value.remarks
				})
				showBlacklistInfo.value = true
				blacklisted.value = true
			} else {
				showBlacklistInfo.value = false
				blacklisted.value = false
			}
		}

		function focusName(focus: boolean = true) {
			if (focus) name.value?.focus()
			else name.value?.blur()
		}

		function reset() {
			visitDate.value = dayjs().toDate()
			model.visitTime = ''
			blacklisted.value = false
			showBlacklistInfo.value = false
		}

		async function getRelatedData() {
			let list = await ResourceHelper.List("/visitor/type")

			if (AuthHelper.is.staff) list = list.filter((type) => type.tenantId < 0)

			types.value = list
		}

		getRelatedData()

		return {
			name,
			types,
			blacklisted,
			showBlacklistInfo,
			blacklistInfo,
			blacklists,
			visitDate,
			checkBlacklist,
			focusName,
			reset,
			currentRow,
			blackListTableRef,
			handleCurrentChange,
			setCurrent,
			visitorImage
		}
	}
})
