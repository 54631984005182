import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createBlock(_component_el_card, {
    class: "host-information",
    header: _ctx.trans('label.host-information')
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form_item, {
        prop: "contactPerson",
        label: _ctx.trans('label.contact-person'),
        rules: {
				required: !_ctx.disabled,
				message: _ctx.trans('message.field_require', _ctx.trans('label.contact-person')),
			}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: _ctx.model.contactPerson,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.contactPerson) = $event)),
            maxlength: "50",
            disabled: _ctx.disabled,
            "show-word-limit": ""
          }, null, 8, ["modelValue", "disabled"])
        ]),
        _: 1
      }, 8, ["label", "rules"]),
      _createVNode(_component_el_form_item, {
        prop: "contactNo",
        label: _ctx.trans('label.contact-no'),
        rules: _ctx.contactNoRule
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: _ctx.model.contactNo,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.contactNo) = $event)),
            maxlength: "20",
            disabled: _ctx.disabled,
            "show-word-limit": ""
          }, null, 8, ["modelValue", "disabled"])
        ]),
        _: 1
      }, 8, ["label", "rules"]),
      _createVNode(_component_el_form_item, {
        prop: "contactEmail",
        label: _ctx.trans('label.contact-email'),
        rules: [
				{
					required: _ctx.model.hasAlert,
					message: _ctx.trans('message.field_require', _ctx.trans('label.contact-email')),
				},
				{
					type: 'email',
					message: _ctx.trans(
						'message.field_not-email',
						_ctx.trans('label.contact-email')
					),
				},
			]
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: _ctx.model.contactEmail,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.contactEmail) = $event)),
            maxlength: "100",
            disabled: _ctx.disabled,
            "show-word-limit": ""
          }, null, 8, ["modelValue", "disabled"])
        ]),
        _: 1
      }, 8, ["label", "rules"]),
      _createVNode(_component_el_form_item, {
        prop: "groupName",
        label: _ctx.trans('label.event-name')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: _ctx.model.groupName,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.groupName) = $event)),
            maxlength: "100",
            disabled: _ctx.disabled,
            "show-word-limit": ""
          }, null, 8, ["modelValue", "disabled"])
        ]),
        _: 1
      }, 8, ["label"])
    ]),
    _: 1
  }, 8, ["header"]))
}