import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.ready)
    ? (_openBlock(), _createBlock(_Teleport, {
        key: 0,
        to: ".wrapper__body"
      }, [
        _createElementVNode("div", {
          class: _normalizeClass([
               'wrapper__footer',
               { 'is-mobile': _ctx.mobileVersion },
             ])
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 2)
      ]))
    : _createCommentVNode("", true)
}