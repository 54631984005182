import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_time_picker = _resolveComponent("el-time-picker")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createBlock(_component_el_card, {
    class: "visitor-information",
    header: _ctx.trans('label.visitor-information')
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_col, { span: 12 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, {
                prop: "visitDate",
                label: _ctx.trans('label.visit-date'),
                rules: {
						required: true,
						message: _ctx.trans('message.field_require', _ctx.trans('label.visit-date'))
					}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_date_picker, {
                    modelValue: _ctx.visitDate,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.visitDate) = $event)),
                    type: "date",
                    placeholder: _ctx.trans('label.nothing-selected'),
                    format: "DD-MM-YYYY",
                    "disabled-date": _ctx.limitVisitDate,
                    clearable: false
                  }, null, 8, ["modelValue", "placeholder", "disabled-date"])
                ]),
                _: 1
              }, 8, ["label", "rules"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_col, { span: 12 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, {
                prop: "visitDate",
                label: _ctx.trans('label.visit-time'),
                rules: {
						required: true,
						message: _ctx.trans('message.field_require', _ctx.trans('label.visit-time'))
					}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_time_picker, {
                    modelValue: _ctx.visitTime,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.visitTime) = $event)),
                    placeholder: _ctx.trans('label.nothing-selected'),
                    format: "HH:mm",
                    "disabled-hours": () => _ctx.limitVisitTime('h'),
                    "disabled-minutes": () => _ctx.limitVisitTime('m')
                  }, null, 8, ["modelValue", "placeholder", "disabled-hours", "disabled-minutes"])
                ]),
                _: 1
              }, 8, ["label", "rules"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_el_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_col, { span: 12 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, {
                prop: "visitorCompany",
                label: _ctx.trans('label.visitor-company')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: _ctx.model.visitorCompany,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.visitorCompany) = $event)),
                    maxlength: "100",
                    "show-word-limit": ""
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["label"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_col, { span: 12 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, {
                prop: "visitorType",
                label: _ctx.trans('label.visitor-type'),
                rules: {
						required: true,
						message: _ctx.trans('message.field_require', _ctx.trans('label.visitor-type'))
					}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_select, {
                    modelValue: _ctx.model.visitorType,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.visitorType) = $event)),
                    placeholder: _ctx.trans('label.nothing-selected'),
                    "automatic-dropdown": false
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.types, (type) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          label: _ctx.trans('visitorType.' + type.recordTypeName),
                          value: type.recordTypeId
                        }, null, 8, ["label", "value"]))
                      }), 256))
                    ]),
                    _: 1
                  }, 8, ["modelValue", "placeholder"])
                ]),
                _: 1
              }, 8, ["label", "rules"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, {
        prop: "remarks",
        label: _ctx.trans('label.remarks')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: _ctx.model.remarks,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.remarks) = $event)),
            type: "textarea",
            autosize: {
					minRows: 3,
					maxRows: 10
				},
            maxlength: 500,
            "show-word-limit": ""
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }, 8, ["label"])
    ]),
    _: 1
  }, 8, ["header"]))
}