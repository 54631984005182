
import { defineComponent, PropType } from "vue"

import { IDataTableCustomAction } from "@/components/data-table/types"
import { Icon } from "@iconify/vue"

export default defineComponent({
	components: { Icon },
	props: {
		config: {
			type: Object as PropType<IDataTableCustomAction>,
			required: true
		},
		row: {
			type: Object,
			required: true
		}
	}
})
