
import {
	defineComponent,
	nextTick,
	onMounted,
	onUnmounted,
	reactive,
	ref,
	toRaw
} from "vue"
import { ElForm, ElMessage } from "element-plus"
import hotkeys, { HotkeysEvent } from "hotkeys-js"
import { useRouter } from "vue-router"

import { CodeScanner, PhotoTaker } from "@/components"
import AuthHelper from "@/helpers/auth"
import LangHelper from "@/helpers/lang"
import ResourceHelper from "@/helpers/resource"
import UserHelper from "@/helpers/user"
import WrapperFooter from "@/views/wrapper/WrapperFooter.vue"

import PreviewVisitor from "./PreviewVisitor.vue"
import VisitorProfile from "../components/VisitorProfile.vue"
import VisitingCompanies from "../components/VisitingCompanies.vue"
import { defaultModel as _defaultModel } from "../components/config"
import dayjs from "dayjs"

export default defineComponent({
	components: {
		VisitorProfile,
		PhotoTaker,
		CodeScanner,
		VisitingCompanies,
		PreviewVisitor,
		WrapperFooter
	},
	setup() {
		const mobileVersion = AuthHelper.mobileVersion
		const defaultModel = {
			..._defaultModel,
			status: 1,

			salutation: "Mr",
			idTypeId: 1,
			idCardNo: "",
			remarks: "",
			email: "",
			visitSchFm: "",
			visitSchTo: "",
			tenantId: -1
		}

		const ready = ref<boolean>(false)
		const model = reactive<any>({ ...defaultModel })
		const form = ref<InstanceType<typeof ElForm>>()
		const visitorProfile = ref<InstanceType<typeof VisitorProfile>>()
		const photoTaker = ref<InstanceType<typeof PhotoTaker>>()
		const codeScanner = ref<InstanceType<typeof CodeScanner>>()
		const visitingCompanies = ref<InstanceType<typeof VisitingCompanies>>()
		const previewVisitor = ref<InstanceType<typeof PreviewVisitor>>()
		const router = useRouter()

		onMounted(() => {
			ready.value = true

			codeScanner.value!.setCallback(showVisitorData)

			hotkeys.filter = (event) => true

			hotkeys(
				"f1,f2,f3,f8,f9,f10,f11,enter",
				(event: KeyboardEvent, handler: HotkeysEvent) => {
					event.preventDefault()
					event.stopPropagation()

					handleHotkey(handler.key)
				}
			)
			resetModel()
		})
		onUnmounted(() => hotkeys.unbind())

		async function handleHotkey(key: string) {
			switch (key) {
				case "f1":
					await resetModel()
					break

				case "f2":
					await router.push("/visitor/management")
					break

				case "f3":
					await previewVisitor.value!.trigger()
					await nextTick(() => visitingCompanies.value!.focusCompany(false))
					break

				case "f4":
					await router.push("/visitor/pre-registration")
					break

				case "f8":
					await saveVisitorWithTakePhoto(false, false)
					break

				case "f9":
					await saveVisitorWithTakePhoto()
					break
				case "f10":
					await router.push("/visitor/reg-validation")
					break
				case "f11":
					await saveVisitorWithTakePhoto(true)
					break

				case "enter":
					visitingCompanies.value!.closeSpecialRequest()
					break
			}
		}

		function showVisitorData(code: string) {
			if (!previewVisitor.value!.open) return

			previewVisitor.value!.loadVisitor(code)
		}

		async function resetModel() {
			const items: string[] = []

			_.forEach(defaultModel, (value: any, key: string) => {
				items.push(key)

				model[key] = value
			})

			visitorProfile.value!.reset()
			visitingCompanies.value!.clearVisitCompany()

			setTimeout(() => form.value!.clearValidate(), 500)
		}

		async function saveVisitor(clear: boolean = true) {
			try {
				// await visitingCompanies.value!.addVisitCompany()
				await form.value!.validate()

				const bypass = await UserHelper.checkBlacklistAndShowMessage({
					salutation: model.salutation,
					firstName: model.firstName,
					lastName: model.lastName
				})

				if (!bypass) return

				if (_.isEmpty(model.RFID))
					return ElMessage.error(LangHelper.trans("message.visitor_scan_fail"))
				
				const visitCompanies = visitingCompanies.value!.getVisitCompanies()
				console.log('try asd')
				{
					let time = dayjs(model.visitTime).format("HH:mm")
					let date = dayjs(model.visitDate).format("YYYY-MM-DD")
					console.log(time)
					console.log(date)

					if (date === "Invalid Date") date = dayjs().format("YYYY-MM-DD")
					if (time === "Invalid Date") time = dayjs().format("HH:mm")

					model.visitDate = `${date} ${time}:00`
					model.visitSchFm = `${date} ${time}:00`
					console.log(model.visitDate)
					console.log(model.visitSchFm)
				}
				if (visitCompanies.length === 0)
					return ElMessage.error(
						LangHelper.trans("message.visitor_no-company_fail")
					)

				const newVisitor = await ResourceHelper.Create(
					"/visitor/general",
					toRaw(model)
				)

				if (!newVisitor.success)
					return ElMessage.error(
						LangHelper.trans("message.visitor_create_fail")
					)

				let boundVisitCompany = true

				for (let visitCompany of visitCompanies) {
					const newVisitorCompany = await ResourceHelper.Create(
						"/visitor/contact",
						{
							visitorId: newVisitor.id,
							...visitCompany
						}
					)

					boundVisitCompany &&= newVisitorCompany.success
				}

				if (!boundVisitCompany)
					return ElMessage.error(LangHelper.trans("message.visitor_bound_fail"))

				ElMessage.success(LangHelper.trans("message.visitor_create_success"))
				
				await visitingCompanies.value!.focusCompany(clear)
				await visitorProfile.value!.focusName(!clear)

				if (clear) await resetModel()
				else model.RFID = ""
			} catch (e) {
				console.log("saveVisitor error", e)
			}
		}

		async function saveVisitorWithTakePhoto(
			clearRfid: boolean = false,
			clear: boolean = true
		) {
			const photoTakerIns = photoTaker.value
			let captured = false

			try {
				await visitingCompanies.value!.visitForm!.validate()
				await form.value!.validate()

				if (photoTakerIns?.ready && !photoTakerIns?.captured) {
					photoTakerIns?.takePhoto()

					captured = true
				}

				await saveVisitor(clear)

				if (clearRfid) model.RFID = ""
			} catch (e) {
				console.log("saveVisitorWithTakePhoto error", e)
			} finally {
				if (captured) photoTakerIns?.retake()
			}
		}

		return {
			mobileVersion,
			ready,
			model,
			form,
			visitorProfile,
			photoTaker,
			codeScanner,
			visitingCompanies,
			previewVisitor,
			saveVisitor,
			saveVisitorWithTakePhoto
		}
	}
})
