
import {
  computed,
  defineComponent,
  inject,
  ref,
}            from 'vue'
import axios from 'axios'
import dayjs from 'dayjs'

import ResourceHelper from '@/helpers/resource'
import { EditorHook } from '@/components/data-table/hooks'
import {
  CodeScanner,
  PhotoTaker,
}                     from '@/components'

import VisitorProfile    from '../components/VisitorProfile.vue'
import VisitingCompanies from '../components/VisitingCompanies.vue'

export default defineComponent({
  components: {
    VisitorProfile,
    PhotoTaker,
    CodeScanner,
    VisitingCompanies,
  },
  setup() {
    const editorHook = inject<EditorHook>('editor-hook')!

    const show           = ref<boolean>(false)
    const blacklisted    = ref<boolean>(false)
    const companies      = ref<any[]>([])
    const visitCompanies = ref<any[]>([])
    const visitDate      = computed(() => {
      if (_.isEmpty(editorHook.model.visitDate))
        return null

      return dayjs(editorHook.model.visitDate)
          .toDate()
    })

    async function getRelatedData() {
      companies.value = await ResourceHelper.List('/tenant/profile')

      const visitingCompanies = await axios.get(`/visitor/contact/${editorHook.recordId.value}`)

      if (visitingCompanies.status === 200)
        visitCompanies.value = visitingCompanies.data

      const { data: response } = await axios.post('/blacklist/check', {
        salutation: editorHook.model.salutation,
        firstName : editorHook.model.firstName,
        lastName  : editorHook.model.lastName,
        phone     : editorHook.model.contactNo,
      })

      if (response.success)
        blacklisted.value = response.blacklisted

      show.value = true
    }

    getRelatedData()

    return {
      ...editorHook.expose,
      show,
      blacklisted,
      companies,
      visitCompanies,
      visitDate,
    }
  },
})
