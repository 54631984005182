
import router from "@/router"
import { Component, defineComponent, inject, shallowRef, triggerRef } from "vue"

import { EditorHook } from "./hooks"

export default defineComponent({
	methods: {
		router() {
			return router
		}
	},
	setup() {
		const editorHook = inject<EditorHook>("editor-hook")!
		const component = shallowRef<Component | undefined>(undefined)

		function editorOpen() {
			component.value = editorHook.component

			triggerRef(component)
		}

		function editorClose() {
			component.value = undefined

			triggerRef(component)
		}

		return {
			...editorHook.expose,
			component,
			editorOpen,
			editorClose
		}
	}
})
