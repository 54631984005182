import LangHelper from '@/helpers/lang'
import Login      from '@/views/auth/login/Login.vue'


const routes = [
  {
    path     : '/login',
    name     : LangHelper.trans('label.login'),
    meta     : {
      icon: 'sign-in-alt',
    },
    component: Login,
  },
]

export default routes
