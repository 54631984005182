class ExposeHelper {
  static GetProperties<T>(hook: T): T {
    const hookPrototype   = Object.getPrototypeOf(hook)
    const properties: any = {}

    Object.getOwnPropertyNames(hook)
          .forEach((propertyName: string) => {
            if (!propertyName.startsWith('_'))
              properties[propertyName] = (<any>hook)[propertyName]
          })

    Object.getOwnPropertyNames(hookPrototype)
          .forEach((methodName: string) => {
            const descriptor = Object.getOwnPropertyDescriptor(hookPrototype, methodName)!

            if (methodName === 'constructor')
              return

            if (!descriptor.get)
              properties[methodName] = (<any>hook)[methodName].bind(hook)
          })

    return properties
  }
}

export default ExposeHelper
