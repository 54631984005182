import axios from "axios"
import dayjs, { Dayjs } from "dayjs"
import { ElMessage } from "element-plus/es"

import {
	IDataTableConfig,
	IDTActionBarConfig,
	IDTDataConfig,
	IDTEditorConfig,
	IDTTableConfig
} from "@/components/data-table/types"
import LangHelper from "@/helpers/lang"
import RecordHelper, { RecordType } from "@/helpers/record"

import PreRegistrationEditor from "./PreRegistrationEditor.vue"

const endPoint = "/visitor/group"
const searchFields = [
	"tenant",
	"groupName",
	"sponsorFirstName",
	"contactPerson",
	"contactNo",
	"visitors"
]
const data = <IDTDataConfig>{
	source: endPoint,

	parser: (row: any) => {
		row.visitType = RecordHelper.Get(
			RecordType.VisitType,
			row.visitorGroupTypeId
		)
		row.createUser = RecordHelper.Get(RecordType.User, row.createBy)

		row.visitDate = dayjs(row.visitDate)

		return row
	},
	searchByDate: (row: any, [start, end]: Dayjs[]) => {
		return (
			row.visitDate.isValid() &&
			(row.visitDate.isSame(start) ||
				row.visitDate.isSame(end) ||
				(row.visitDate.isAfter(start) && row.visitDate.isBefore(end)))
		)
	},
	searchByColumn: (row: any, kw: string) => {
		kw = kw.toLocaleLowerCase()
		if (!kw || kw === "") return true

		const keywords = kw.split(" ")
		const matches = keywords.filter((str) => {
			return searchFields.some((field) => {
				if (!row[field] || row[field] === "") {
					return
				} else if (field === "visitors") {
					return (
						row[field] &&
						row[field].length > 0 &&
						row[field].some((visitor: any) => {
							return (
								(visitor.FirstName + visitor.LastName)
									.toLocaleLowerCase()
									.includes(str) ||
								(visitor.LastName + visitor.FirstName)
									.toLocaleLowerCase()
									.includes(str) ||
								visitor.ContactNo.includes(str) ||
								visitor.visitorEmail.includes(str)
							)
						})
					)
				} else {
					return row[field].toLocaleLowerCase().includes(str)
				}
			})
		})

		return matches.length == keywords.length
	}
}

const actionBar = <IDTActionBarConfig>{
	search: "date"
}

const table = <IDTTableConfig>{
	columns: [
		{
			title: LangHelper.trans("label.visitor-type"),
			prop: "visitType",
			sortable: true
		},
		{
			title: LangHelper.trans("label.company"),
			prop: "tenant",
			sortable: true
		},
		{
			title: LangHelper.trans("label.event-name"),
			prop: "groupName",
			sortable: true
		},
		{
			title: LangHelper.trans("label.visitor-company"),
			prop: "sponsorFirstName",
			sortable: true
		},
		{
			title: LangHelper.trans("label.contact-person"),
			prop: "contactPerson",
			sortable: true
		},
		{
			title: LangHelper.trans("label.phone"),
			prop: "contactNo",
			sortable: true
		},
		{
			title: LangHelper.trans("label.visit-date"),
			prop: "visitDate",
			sortable: true,
			formatter: ({ cellValue }: any) => cellValue.format("YYYY-MM-DD")
		},
		{
			title: LangHelper.trans("label.visit-time"),
			prop: "visitSchFm",
			formatter: ({ cellValue }: any) => {
				return cellValue ? dayjs(cellValue).format("HH:mm") : ""
			}
		},
		{
			title: LangHelper.trans("label.visitor-count"),
			prop: "numberOfVisitors",
			sortable: true
		},
		{
			title: LangHelper.trans("label.created-by"),
			prop: "createUser",
			sortable: true
		}
	],
	sort: {
		field: "visitDate",
		order: "desc"
	},
	action: {
		mode: "edit",
		delete: true,
		custom: [
			{
				title: LangHelper.trans("label.resend-email"),
				icon: "ic:baseline-mail-outline",
				action: async (row: any) => {
					const allow = await axios.get(`/visitor/group/mail/${row._id}`)

					if (!allow || allow.status !== 200 || !allow.data.success)
						return ElMessage.error(
							LangHelper.trans("message.visitors_resend-email_fail")
						)

					ElMessage.success(
						LangHelper.trans("message.visitors_resend-email_success")
					)
				}
			}
			// {
			//   title : LangHelper.trans('label.edit'),
			//   icon  : 'ic:round-fiber-new',
			//   action: async (
			//     row: any,
			//     router: Router,
			//   ) => await router.push(`/visitor/pre-registration?id=${row._id}`),
			// },
		]
	}
}

const editor = <IDTEditorConfig>{
	title: "Pre-Registration",
	component: PreRegistrationEditor,
	editRedirect: "/visitor/pre-registration?id=",
	authEdit: (model: any) => {
		if (!model.visitDate) return true

		const current = dayjs().add(15, "minute")
		const visitDate = dayjs(model.visitDate)

		return visitDate.diff(current) > 0
	},
	delete: true,
	defaultModel: {
		tenantId: null,
		floorIds: [],
		visitDate: "",
		visitTime: "",
		visitorCompany: "",
		visitorType: 1,
		remarks: "",

		groupName: "",
		contactPerson: "",
		contactNo: "",
		contactEmail: "",

		visitors: []
	},
	labelPosition: "top"
}

export default <IDataTableConfig>{
	data,
	actionBar,
	table,
	editor
}
