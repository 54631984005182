
import axios from "axios"
import { defineComponent, ref } from "vue"
import { Icon } from "@iconify/vue"

export default defineComponent({
	components: { Icon },
	props: {
		gate: {
			type: Object,
			required: true
		}
	},
	setup({ gate }) {
		const status = ref<number>(gate.doorStatus)

		async function setGateStatus(newStatus: number) {
			const { data: response } = await axios.put(
				`/building/gate/status/${gate.gateId}/${newStatus}`
			)

			if (!response.success) return

			status.value = newStatus
		}

		return {
			status,
			setGateStatus
		}
	}
})
