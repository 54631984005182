import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "data-table" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_data_table_action_bar = _resolveComponent("data-table-action-bar")!
  const _component_data_table_body = _resolveComponent("data-table-body")!
  const _component_data_table_editor = _resolveComponent("data-table-editor")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_data_table_action_bar, {
        config: _ctx.config.actionBar
      }, {
        prepend: _withCtx(() => [
          _renderSlot(_ctx.$slots, "action-bar-prepend")
        ]),
        append: _withCtx(() => [
          _renderSlot(_ctx.$slots, "action-bar-append")
        ]),
        _: 3
      }, 8, ["config"]),
      _createVNode(_component_data_table_body, {
        config: _ctx.config.table,
        checkbox: !!_ctx.config.actionBar.delete
      }, null, 8, ["config", "checkbox"])
    ]),
    (_ctx.config.editor)
      ? (_openBlock(), _createBlock(_component_data_table_editor, {
          key: 0,
          ref: "editor"
        }, null, 512))
      : _createCommentVNode("", true)
  ], 64))
}