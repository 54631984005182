import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "data-table__body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_empty = _resolveComponent("el-empty")!
  const _component_vxe_column = _resolveComponent("vxe-column")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_data_table_body_action_custom = _resolveComponent("data-table-body-action-custom")!
  const _component_data_table_body_action_mode = _resolveComponent("data-table-body-action-mode")!
  const _component_data_table_body_action_status = _resolveComponent("data-table-body-action-status")!
  const _component_data_table_body_action_delete = _resolveComponent("data-table-body-action-delete")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!
  const _component_vxe_table = _resolveComponent("vxe-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_vxe_table, {
      ref: "table",
      border: "inner",
      "show-overflow": "tooltip",
      "row-class-name": "data-table__body--row",
      data: _ctx.rowData,
      loading: _ctx.loading,
      "sort-config": _ctx.sort,
      fit: true,
      height: _ctx.height,
      "row-config": {
				height: 40,
				isHover: true
			},
      "loading-config": {
				icon: 'vxe-icon-indicator roll',
				text: 'Loading ...'
			},
      onCellDblclick: _ctx.onCellDoubleClick
    }, {
      empty: _withCtx(() => [
        _createVNode(_component_el_empty, { description: "No records found" })
      ]),
      default: _withCtx(() => [
        (_ctx.checkbox)
          ? (_openBlock(), _createBlock(_component_vxe_column, {
              key: 0,
              type: "checkbox",
              width: "45"
            }))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.config.columns, (column) => {
          return (_openBlock(), _createBlock(_component_vxe_column, {
            field: column.prop,
            title: column.title,
            fixed: column.fixed,
            sortable: column.sortable,
            "sort-by": column.sorter,
            formatter: column.formatter
          }, null, 8, ["field", "title", "fixed", "sortable", "sort-by", "formatter"]))
        }), 256)),
        (_ctx.withAction)
          ? (_openBlock(), _createBlock(_component_vxe_column, {
              key: 1,
              "class-name": "data-table__body--action",
              fixed: "right",
              title: _ctx.trans('label.action'),
              width: _ctx.actionWidth
            }, {
              default: _withCtx((scope) => [
                _createVNode(_component_el_dropdown, { placement: "bottom-end" }, {
                  dropdown: _withCtx(() => [
                    _createVNode(_component_el_dropdown_menu, null, {
                      default: _withCtx(() => [
                        (_ctx.config.action.custom)
                          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.config.action.custom, (config) => {
                              return (_openBlock(), _createBlock(_component_data_table_body_action_custom, {
                                config: config,
                                row: scope.row
                              }, null, 8, ["config", "row"]))
                            }), 256))
                          : _createCommentVNode("", true),
                        (_ctx.config.action.mode)
                          ? (_openBlock(), _createBlock(_component_data_table_body_action_mode, {
                              key: 1,
                              config: _ctx.config.action,
                              id: scope.row._id
                            }, null, 8, ["config", "id"]))
                          : _createCommentVNode("", true),
                        (_ctx.config.action.status)
                          ? (_openBlock(), _createBlock(_component_data_table_body_action_status, {
                              key: 2,
                              config: _ctx.config.action.status,
                              row: scope.row
                            }, null, 8, ["config", "row"]))
                          : _createCommentVNode("", true),
                        (_ctx.config.action.delete)
                          ? (_openBlock(), _createBlock(_component_data_table_body_action_delete, {
                              key: 3,
                              id: scope.row._id
                            }, null, 8, ["id"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_el_button, {
                      type: "primary",
                      size: "small",
                      text: ""
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", null, _toDisplayString(_ctx.trans("label.action")), 1),
                        _createVNode(_component_Icon, { icon: "ic:baseline-keyboard-arrow-down" })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 1
            }, 8, ["title", "width"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["data", "loading", "sort-config", "height", "loading-config", "onCellDblclick"])
  ]))
}