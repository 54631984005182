
import axios from 'axios'
import {
  computed,
  defineComponent,
  inject,
  ref,
}            from 'vue'

import { EditorHook } from '@/components/data-table/hooks'
import AuthHelper     from '@/helpers/auth'
import LangHelper     from '@/helpers/lang'
import ResourceHelper from '@/helpers/resource'

import ContactPersons from './ContactPersons.vue'


export default defineComponent({
  components: {
    ContactPersons,
  },
  setup() {
    const editorHook  = inject<EditorHook>('editor-hook')!
    const zones       = ref<any[]>([])
    const floors      = ref<any[]>([])
    const options     = computed<any[]>(() => {
      return _.sortBy(zones.value, [ 'zoneName' ])
              .map((zone: any) => ({
                value   : zone.zoneId,
                label   : zone.zoneName,
                children: _.sortBy(floors.value, [ 'floorName' ])
                           .filter((floor: any) => zone.zoneId === floor.zoneId)
                           .map((floor: any) => ({
                             value: floor.floorId,
                             label: floor.floorName,
                           })),
              }))
    })
    const floorIds    = computed({
      get: () => JSON.parse(editorHook.model!.suite_FloorID || '[]'),
      set: value => editorHook.model!.suite_FloorID = JSON.stringify(value),
    })

    async function getRelatedData() {
      const result = await Promise.all([
        ResourceHelper.List('/building/zone'),
        ResourceHelper.List('/building/floor'),
      ])

      zones.value  = result[0]
      floors.value = result[1]
    }

    getRelatedData()

    return {
      ...editorHook.expose,
      options,
      floorIds,
    }
  },
})
