import dayjs from 'dayjs'

import ResourceHelper from '@/helpers/resource'

export enum RecordType {
  User,
  Role,
  VisitType,
}

interface ICacheType {
  [id: string]: string
}

type ICache = {
  [type in RecordType]: ICacheType
}

interface IRecord {
  id: number
  value: string
}

type IRecordFetcher = (row: any) => IRecord

interface ITypeFetcher {
  [endPoint: string]: IRecordFetcher
}

type IFetcher = {
  [type in RecordType]: ITypeFetcher
}

class RecordHelper {
  private static _lastCached: number = -1
  private static _cache: ICache      = {
    [RecordType.User]     : {},
    [RecordType.Role]     : {},
    [RecordType.VisitType]: {},
  }
  private static _fetcher: IFetcher  = {
    [RecordType.User]     : {
      '/staff/user'    : row => ({
        id   : row.userId,
        value: `${row.lastName} ${row.firstName}`.trim(),
      }),
      '/tenant/profile': row => ({
        id   : 10000000 + row.tenantId,
        value: row.tenantName,
      }),
    },
    [RecordType.Role]     : {
      '/staff/role': row => ({
        id   : row.userRoleId,
        value: row.userRoleName,
      }),
    },
    [RecordType.VisitType]: {
      '/visitor/type': row => ({
        id   : row.recordTypeId,
        value: row.recordTypeName,
      }),
    },
  }

  static async Fetch(force: boolean = false) {
    const current = dayjs()
      .unix()

    if (
      (current - this._lastCached) < 60 &&
      !force
    )
      return

    for (let cacheType of Object.keys(this._cache)) {
      const type        = <RecordType><any>cacheType
      const typeFetcher = this._fetcher[type]
      const list: any   = {}

      for (let endPoint of Object.keys(typeFetcher)) {
        const fetcher  = typeFetcher[endPoint]
        const response = await ResourceHelper.List(endPoint)

        response.forEach(row => {
          const {
                  id,
                  value,
                } = fetcher(row)

          list[id] = value
        })
      }

      this._cache[type] = list
    }

    this._lastCached = current
  }

  static Get(
    type: RecordType,
    id: number,
  ): string {
    return this._cache[type][id] ?? ''
  }
}

export default RecordHelper
