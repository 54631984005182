import axios            from 'axios'
import { ElMessageBox } from 'element-plus/es'

import LangHelper                   from '@/helpers/lang'
import RecordHelper, { RecordType } from '@/helpers/record'


function getHtml(data: any): string {
  return `
<form class="el-form el-form--small el-form--label-left">
    <div class="el-form-item">
        <label class="el-form-item__label"
               style="width: 140px;">${LangHelper.trans('label.salutation')}</label>
        <div class="el-form-item__content">
            <div class="el-input el-input--small">
                <div class="el-input__wrapper">
                    <input class="el-input__inner"
                           type="text"
                           value="${data.salutation}"
                           readonly>
                </div>
            </div>
        </div>
    </div>
    <div class="el-form-item">
        <label class="el-form-item__label"
               style="width: 140px;">${LangHelper.trans('label.first-name')}</label>
        <div class="el-form-item__content">
            <div class="el-input el-input--small">
                <div class="el-input__wrapper">
                    <input class="el-input__inner"
                           type="text"
                           value="${data.firstName}"
                           readonly>
                </div>
            </div>
        </div>
    </div>
    <div class="el-form-item">
        <label class="el-form-item__label"
               style="width: 140px;">{{ trans('label.last-name') }}</label>
        <div class="el-form-item__content">
            <div class="el-input el-input--small">
                <div class="el-input__wrapper">
                    <input class="el-input__inner"
                           type="text"
                           value="${data.lastName}"
                           readonly>
                </div>
            </div>
        </div>
    </div>
    <div class="el-form-item">
        <label class="el-form-item__label"
               style="width: 140px;">${LangHelper.trans('label.phone')}</label>
        <div class="el-form-item__content">
            <div class="el-input el-input--small">
                <div class="el-input__wrapper">
                    <input class="el-input__inner"
                           type="text"
                           value="${data.phone}"
                           readonly>
                </div>
            </div>
        </div>
    </div>
    <div class="el-form-item">
        <label class="el-form-item__label"
               style="width: 140px;">Email</label>
        <div class="el-form-item__content">
            <div class="el-input el-input--small">
                <div class="el-input__wrapper">
                    <input class="el-input__inner"
                           type="text"
                           value="${data.email}"
                           readonly>
                </div>
            </div>
        </div>
    </div>
    <div class="el-form-item">
        <label class="el-form-item__label"
               style="width: 140px;">${LangHelper.trans('label.blacklisted-by')}</label>
        <div class="el-form-item__content">
            <div class="el-input el-input--small">
                <div class="el-input__wrapper">
                    <input class="el-input__inner"
                           type="text"
                           value="${data.blacklistedBy}"
                           readonly>
                </div>
            </div>
        </div>
    </div>
    <div class="el-form-item">
        <label class="el-form-item__label"
               style="width: 140px;">${LangHelper.trans('label.remarks')}</label>
        <div class="el-form-item__content">
            <div class="el-textarea el-textarea">
                <textarea class="el-textarea__inner" readonly>${data.remarks}</textarea>
            </div>
        </div>
    </div>
</form>
`
}

class UserHelper {
  static async checkBlacklist(data: any): Promise<any> {
    const { data: response } = await axios.post('/blacklist/check', data)

    if (
      response.success &&
      response.blacklisted
    ) {
        if(response.record.length >= 0)
        {
            _.forEach(response.record, (value: any, key: string) => {
                response.record[key].blacklistedBy = RecordHelper.Get(RecordType.User, value.voidBy)
            });
        }

        return response.record
    }

    return false
  }

  static async checkBlacklistAndShowMessage(data: any): Promise<any> {
    const blacklist = await UserHelper.checkBlacklist(data)

    if (blacklist === false)
      return true

    try {
      await ElMessageBox.confirm(
        getHtml(blacklist),
        LangHelper.trans('confirm.visitor_bypass-blacklisted.title'),
        {
          confirmButtonText       : 'Confirm',
          confirmButtonClass      : 'el-button--danger',
          cancelButtonText        : LangHelper.trans('label.cancel'),
          dangerouslyUseHTMLString: true,
        },
      )

      return true
    } catch (error) {
      return false
    }
  }
}

export default UserHelper
