import LangHelper                   from '@/helpers/lang'
import RecordHelper, { RecordType } from '@/helpers/record'
import dayjs                        from 'dayjs'

import {
  IDataTableConfig,
  IDTActionBarConfig,
  IDTDataConfig,
  IDTEditorConfig,
  IDTTableConfig,
} from '@/components/data-table/types'

import StaffEditor from './StaffEditor.vue'

const data = <IDTDataConfig>{
  source: '/staff/user',
  parser: (row: any) => {
    row.role = RecordHelper.Get(RecordType.Role, row.userRoleId)
    row.name = `${row.lastName} ${row.firstName}`.trim()

    return row
  },
}

const actionBar = <IDTActionBarConfig>{
  search: true,
  create: true,
}

const table = <IDTTableConfig>{
  columns: [
    {
      title   : LangHelper.trans('label.name'),
      prop    : 'name',
      sortable: true,
    },
    {
      title   : LangHelper.trans('label.username'),
      prop    : 'userName',
      sortable: true,
    },
    {
      title   : LangHelper.trans('label.role'),
      prop    : 'role',
      sortable: true,
    },
    {
      title    : LangHelper.trans('label.created-date'),
      prop     : 'createDate',
      sortable : true,
      formatter: ({ cellValue }: any) => dayjs('2023-01-01 00:00')
        .format('YYYY-MM-DD HH:mm'),
    },
  ],
  action : {
    mode  : 'edit',
    delete: true,
  },
}

const editor = <IDTEditorConfig>{
  title       : LangHelper.trans('label.staff'),
  component   : StaffEditor,
  delete      : true,
  defaultModel: {
    userName  : '',
    firstName : '',
    lastName  : '',
    password  : '',
    userRoleId: 1,
  },
}

export default <IDataTableConfig>{
  data,
  actionBar,
  table,
  editor,
}
