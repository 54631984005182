
import { defineComponent, inject } from "vue"

import { EditorHook } from "../hooks"
import { Icon } from "@iconify/vue"

export default defineComponent({
	components: {
		Icon
	},
	setup() {
		const editorHook = inject<EditorHook>("editor-hook")!

		async function deleteRecord() {
			await editorHook.removeSelected()
		}

		return {
			deleteRecord
		}
	}
})
