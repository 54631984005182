import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_sub_menu = _resolveComponent("el-sub-menu")!
  const _component_el_menu = _resolveComponent("el-menu")!

  return (_openBlock(), _createBlock(_component_el_menu, {
    class: "nav__menu",
    mode: "vertical",
    "default-active": _ctx.active,
    ellipsis: _ctx.isMobile
  }, {
    default: _withCtx(() => [
      (_ctx.isStaff)
        ? (_openBlock(), _createBlock(_component_el_menu_item, {
            key: 0,
            index: "/dashboard",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.routeTo('/dashboard')))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Icon, { icon: "ic:baseline-dashboard" }),
              _createElementVNode("span", null, _toDisplayString(_ctx.trans("label.dashboard")), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuRoutes, (route) => {
        return (_openBlock(), _createElementBlock(_Fragment, null, [
          (_ctx.checkPermission(route))
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (route.children)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      (route.name)
                        ? (_openBlock(), _createBlock(_component_el_sub_menu, {
                            key: 0,
                            index: `/${route.path}/`
                          }, {
                            title: _withCtx(() => [
                              _createVNode(_component_Icon, {
                                icon: route.meta.icon
                              }, null, 8, ["icon"]),
                              _createElementVNode("span", null, _toDisplayString(route.name), 1)
                            ]),
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(route.children, (subRoute) => {
                                return (_openBlock(), _createElementBlock(_Fragment, null, [
                                  (_ctx.checkPermission(subRoute))
                                    ? (_openBlock(), _createBlock(_component_el_menu_item, {
                                        key: 0,
                                        index: `/${route.path}/${subRoute.path}`,
                                        onClick: ($event: any) => (_ctx.routeTo(`/${route.path}/${subRoute.path}`))
                                      }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("span", null, _toDisplayString(subRoute.name), 1)
                                        ]),
                                        _: 2
                                      }, 1032, ["index", "onClick"]))
                                    : _createCommentVNode("", true)
                                ], 64))
                              }), 256))
                            ]),
                            _: 2
                          }, 1032, ["index"]))
                        : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(route.children, (subRoute) => {
                            return (_openBlock(), _createElementBlock(_Fragment, null, [
                              (_ctx.checkPermission(subRoute))
                                ? (_openBlock(), _createBlock(_component_el_menu_item, {
                                    key: 0,
                                    index: `/${route.path}/${subRoute.path}`,
                                    onClick: ($event: any) => (_ctx.routeTo(`/${route.path}/${subRoute.path}`))
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_Icon, {
                                        icon: subRoute.meta.icon
                                      }, null, 8, ["icon"]),
                                      _createElementVNode("span", null, _toDisplayString(subRoute.name), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["index", "onClick"]))
                                : _createCommentVNode("", true)
                            ], 64))
                          }), 256))
                    ], 64))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      (_ctx.checkPermission(route))
                        ? (_openBlock(), _createBlock(_component_el_menu_item, {
                            key: 0,
                            index: `/${route.path}`,
                            onClick: ($event: any) => (_ctx.routeTo(`/${route.path}`))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_Icon, {
                                icon: route.meta.icon
                              }, null, 8, ["icon"]),
                              _createElementVNode("span", null, _toDisplayString(route.name), 1)
                            ]),
                            _: 2
                          }, 1032, ["index", "onClick"]))
                        : _createCommentVNode("", true)
                    ], 64))
              ], 64))
            : _createCommentVNode("", true)
        ], 64))
      }), 256))
    ]),
    _: 1
  }, 8, ["default-active", "ellipsis"]))
}