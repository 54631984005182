
import { defineComponent, inject } from "vue"
import { Icon } from "@iconify/vue"

import { EditorHook } from "../hooks"

export default defineComponent({
	components: {
		Icon
	},
	setup() {
		const editorHook = inject<EditorHook>("editor-hook")!

		function createRecord() {
			editorHook.show("create")
		}

		return {
			createRecord
		}
	}
})
