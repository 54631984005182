import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_data_table = _resolveComponent("data-table")!

  return (_openBlock(), _createBlock(_component_data_table, { config: _ctx.config }, {
    "action-bar-append": _withCtx(() => [
      _createVNode(_component_el_button, {
        type: "primary",
        onClick: _withModifiers(_ctx.preRegistration, ["prevent"])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Icon, { icon: "ic:baseline-add-circle" }),
          _createElementVNode("span", null, _toDisplayString(_ctx.trans("label.add-item")), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _: 1
  }, 8, ["config"]))
}