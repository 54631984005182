import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "photo-taker-container" }
const _hoisted_2 = {
  key: 0,
  class: "preview"
}
const _hoisted_3 = {
  key: 0,
  class: "camera"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 0,
  class: "buttons"
}
const _hoisted_7 = {
  key: 1,
  class: "no-ready"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.preview || _ctx.ready)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (!_ctx.captured)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("img", {
                  ref: "image",
                  src: _ctx.previewImage
                }, null, 8, _hoisted_4)
              ]))
            : _createCommentVNode("", true),
          (_ctx.preview || _ctx.captured)
            ? (_openBlock(), _createElementBlock("img", {
                key: 1,
                class: "preview-img",
                src: _ctx.model.visitorImage
              }, null, 8, _hoisted_5))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (!_ctx.preview)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.ready)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                (!_ctx.captured)
                  ? (_openBlock(), _createBlock(_component_el_button, {
                      key: 0,
                      type: "primary",
                      disabled: false,
                      onClick: _withModifiers(_ctx.takePhoto, ["prevent"])
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.trans('label.take-photo')), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
                  : (_openBlock(), _createBlock(_component_el_button, {
                      key: 1,
                      type: "primary",
                      disabled: false,
                      onClick: _withModifiers(_ctx.retake, ["prevent"])
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.trans('label.retake')), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_7, " Camera is not ready "))
        ], 64))
      : _createCommentVNode("", true)
  ]))
}