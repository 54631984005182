
import { defineComponent, reactive, ref, toRaw, watch } from "vue"
import { ElForm } from "element-plus"
import { ElMessage } from "element-plus/es"
import * as XLSX from "xlsx"

import LangHelper from "@/helpers/lang"

type VisitorModel = {
	visitorId: number
	salutation: string
	lastName: string
	firstName: string
	contactNo: string
	visitorEmail: string
	entryType: string
	status: number
	alert: number
}

export default defineComponent({
	props: {
		view: {
			type: Boolean,
			default: false
		}
	},
	setup() {
		const visitorForm = ref<InstanceType<typeof ElForm>>()
		const importFile = ref<HTMLInputElement>()
		const visitors = ref<any[]>([])
		const model = reactive<any>({
			salutation: "Mr",
			lastName: "",
			firstName: "",
			contactNo: "",
			visitorEmail: "",
			entryType: "Single-entry",
			status: 5,
			alert: 0
		})
		const showDialog = ref<boolean>(false)

		const phoneRegex = /^[\w\s\d]{8,20}$/
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+[^.]$/

		const editingIdx = ref<number>(-1)
		let cacheVisitor: VisitorModel | null = null

		function changeAll(item: string, value: number) {
			visitors.value = visitors.value.map((visitor) => {
				visitor[item] = value

				return visitor
			})
		}

		async function checkPhone(rule: any, value: any, callback: any) {
			if (!phoneRegex.test(value))
				return callback(
					new Error(
						LangHelper.trans(
							"message.item_invalid_fail",
							LangHelper.trans("label.contact-no")
						)
					)
				)

			callback()
		}

		async function checkEmail(rule: any, value: any, callback: any) {
			const exist = visitors.value
				.map((visitor) => visitor.visitorEmail)
				.includes(value)
			if (exist)
				return callback(
					new Error(
						LangHelper.trans(
							"message.item_exist",
							LangHelper.trans("label.email")
						)
					)
				)
			callback()
		}

		function resetVisitor() {
			visitorForm.value!.resetFields()
		}

		async function addVisitor() {
			if (visitors.value.length >= 300)
				return ElMessage.error(LangHelper.trans("message.visitors_fail"))

			try {
				await visitorForm.value!.validate()

				if (editingIdx.value >= 0) {
					// console.log(cacheVisitor)
					const visitor = {
						...toRaw(cacheVisitor),
						...toRaw(model)
					}

					visitors.value.splice(editingIdx.value, 0, visitor)
					// console.log(visitors.value[0])
					cacheVisitor = null
					editingIdx.value = -1
					closeDialog()
				} else {
					visitors.value = [...visitors.value, { ...toRaw(model) }]
					closeDialog()
				}

				resetVisitor()
			} catch (e) {}
		}

		async function removeVisitor(targetIndex: number) {
			const newList: any[] = []

			visitors.value.forEach((company: any, index: number) => {
				if (index !== targetIndex) newList.push(company)
			})

			visitors.value = newList
		}

		function importVisitors(event: InputEvent) {
			const target = event.target as HTMLInputElement
			const files = target.files

			if (
				files === null ||
				files!.length !== 1 ||
				files[0].type !==
					"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
			)
				return

			const reader = new FileReader()

			reader.addEventListener("load", readVisitors)
			reader.readAsArrayBuffer(files[0])
		}

		function readVisitors(event: ProgressEvent<FileReader>) {
			const result = event.target!.result
			const workbook = XLSX.read(result)
			const firstSheet = workbook.Sheets[workbook.SheetNames[0]]

			const data = XLSX.utils.sheet_to_json(firstSheet, {
				header: 1,
				blankrows: false
			})

			let msg: any = [LangHelper.trans("message.vistor_import_error_header")]
			let pass = true
			let emails: string[] = []
			data.forEach((row, index) => {
				let label = []
				pass = true
				if (index === 0) return
				row = (row as string[]).map((item) => {
					if (item && typeof item == "string") return item.trim()
					return item
				})
				const record = row as string[]
				const salutationList = ["Mr", "Ms", "Mrs", "Miss", "Prof", "Dr"]
				// Capitalize first letter fix
				if (record[0]) {
					record[0] =
						record[0].toString().toLowerCase().charAt(0).toUpperCase() +
						record[0].toString().toLowerCase().slice(1)
				}

				if (!record[0] || !salutationList.includes(record[0])) {
					label.push("salutation")
					pass = false
				}
				if (!record[1]) {
					label.push("last-name")
					pass = false
				}
				if (!record[3] || !phoneRegex.test(record[3])) {
					label.push("contact-no")
					pass = false
				}

				if (!record[4] || !emailRegex.test(record[4])) {
					label.push("email")
					pass = false
				} else if (emails.includes(record[4])) {
					pass = false
					label.push("duplicate-email")
				} else {
					emails.push(record[4])
				}

				if (
					!record[5] ||
					(record[5].toString().toLowerCase() !== "s" &&
						record[5].toString().toLowerCase() !== "1d")
				) {
					label.push("entry-type")
					pass = false
				}
				if (
					!record[6] ||
					(record[6].toString().toLowerCase() !== "y" &&
						record[6].toString().toLowerCase() !== "n")
				) {
					label.push("verification")
					pass = false
				}
				if (
					!record[7] ||
					(record[7].toString().toLowerCase() !== "y" &&
						record[7].toString().toLowerCase() !== "n")
				) {
					label.push("alert")
					pass = false
				}
				if (!pass)
					msg.push(
						`- ${LangHelper.trans("message.row")} ${index + 1} (${
							label.length > 1
								? label.reduce(
										(tl, l, i) =>
											(i == 1 ? LangHelper.trans("label." + tl) : tl) +
											"," +
											LangHelper.trans("label." + l)
								  )
								: LangHelper.trans("label." + label)
						})`
					)
			})
			if (msg.length > 1) {
				msg.push("\n" + LangHelper.trans("message.vistor_import_error"))
				alert(msg.join("\r\n"))
			} else
				data.forEach((row, index) => {
					if (index === 0) return

					row = (row as string[]).map((item) => {
						if (item && typeof item == "string") return item.trim()
						return item
					})
					const record = row as string[]
					const visitor = {
						salutation: record[0],
						lastName: record[1],
						firstName: record[2] ?? "",
						contactNo: record[3],
						visitorEmail: record[4],
						entryType:
							record[5].toLowerCase() === "s" ? "Single-entry" : "1-Day",
						status: record[6].toLowerCase() === "y" ? 5 : 1,
						alert: record[7].toLowerCase() === "y" ? 1 : 0
					}

					visitors.value = [...visitors.value, visitor]
				})

			importFile.value!.value = ""
		}

		function checkVisitors(): boolean {
			const visitorList = getVisitors()

			return !visitorList.some(
				(visitor) =>
					visitor.visitorEmail.trim() === "" ||
					visitorList.filter(
						(item) => item.visitorEmail === visitor.visitorEmail
					).length > 1
			)
		}

		function getVisitors(): any[] {
			return visitors.value
		}

		function setVisitors(visitorList: any[]) {
			visitors.value = visitorList
		}

		async function editVisitor(visitor: VisitorModel, index: number) {
			openDialog()

			editingIdx.value = index
			cacheVisitor = visitor

			await removeVisitor(index)
			model.salutation = visitor.salutation
			model.lastName = visitor.lastName
			model.firstName = visitor.firstName
			model.contactNo = String(visitor.contactNo)
			model.visitorEmail = visitor.visitorEmail
			model.entryType = visitor.entryType
			model.status = visitor.status
			model.alert = visitor.alert
		}

		const openDialog = () => {
			showDialog.value = true
			resetVisitor()
		}
		const closeDialog = () => {
			showDialog.value = false
		}
		const handleDialogClose = (done: () => void) => {
			if (editingIdx.value >= 0 && cacheVisitor) {
				visitors.value.splice(editingIdx.value, 0, cacheVisitor)
				cacheVisitor = null
				editingIdx.value = -1
			}

			resetVisitor()

			done()
		}

		watch(
			() => model.tenantId,
			() => (model.floorIds = [])
		)

		return {
			visitorForm,
			importFile,
			visitors,
			model,
			changeAll,
			checkPhone,
			checkEmail,
			addVisitor,
			removeVisitor,
			importVisitors,
			checkVisitors,
			getVisitors,
			setVisitors,
			editVisitor,
			resetVisitor,
			showDialog,
			openDialog,
			closeDialog,
			handleDialogClose,
			editingIdx
		}
	}
})
