
import {
  defineComponent,
  onMounted,
  ref,
}                 from 'vue'

import AuthHelper from '@/helpers/auth'

export default defineComponent({
  setup() {
    const mobileVersion = AuthHelper.mobileVersion

    const ready = ref<boolean>(false)

    onMounted(() => ready.value = true)

    return {
      mobileVersion,
      ready,
    }
  },
})
