
import { computed, defineComponent, onBeforeUnmount, onMounted, ref } from "vue"

import ResourceHelper from "@/helpers/resource"

import Gate from "./Gate.vue"
import { Icon } from "@iconify/vue"

export default defineComponent({
	components: {
		Icon,
		Gate
	},
	setup() {
		const zones = ref<any[]>([])
		const gates = ref<any[]>([])
		const list = computed<any[]>(() => {
			const gateList = gates.value

			return zones.value
				.map((zone) => ({
					...zone,
					gates: gateList.filter((gate) => zone.zoneId === gate.zoneId)
				}))
				.filter((zone) => zone.gates.length > 0)
		})

		async function getRelatedData() {
			const result = await Promise.all([
				ResourceHelper.List("/building/zone"),
				ResourceHelper.List("/building/gate")
			])

			zones.value = result[0]
			gates.value = result[1]
		}

		getRelatedData()

		let jobId = -1

		onMounted(() => (jobId = window.setInterval(getRelatedData, 60000)))
		onBeforeUnmount(() => clearInterval(jobId))

		return {
			list
		}
	}
})
