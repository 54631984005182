
import { defineComponent, inject, PropType } from "vue"

import { IDTActionBarConfig } from "@/components/data-table/types"
import DataTableActionBarCreate from "./action-bar/DataTableActionBarCreate.vue"
import DataTableActionBarDelete from "./action-bar/DataTableActionBarDelete.vue"
import DataTableActionBarExport from "./action-bar/DataTableActionBarExport.vue"
import DataTableActionBarImport from "./action-bar/DataTableActionBarImport.vue"
import DataTableActionBarSearch from "./action-bar/DataTableActionBarSearch.vue"
import { DataHook } from "@/components/data-table/hooks"
import { Icon } from "@iconify/vue"

export default defineComponent({
	components: {
		DataTableActionBarCreate,
		DataTableActionBarDelete,
		DataTableActionBarExport,
		DataTableActionBarImport,
		DataTableActionBarSearch,
		Icon
	},
	props: {
		config: {
			type: Object as PropType<IDTActionBarConfig>,
			required: true
		}
	},
	setup() {
		const data = inject<DataHook>("data-hook")!

		function refresh() {
			data.refresh()
		}

		return {
			refresh
		}
	}
})
