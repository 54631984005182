
import { defineComponent, reactive } from "vue"
import { ElMessage } from "element-plus"
import { useRouter } from "vue-router"

import AuthHelper from "@/helpers/auth"
import LangHelper from "@/helpers/lang"
import { Icon } from "@iconify/vue"

export default defineComponent({
	components: { Icon },
	setup() {
		const credentials = reactive<any>({
			username: "",
			password: ""
		})
		const router = useRouter()

		async function login() {
			const success = await AuthHelper.Login(credentials)

			if (!success)
				return ElMessage.error(LangHelper.trans("message.login_fail"))

			ElMessage.success(LangHelper.trans("message.login_success"))

			await router.push("/dashboard")
		}

		return {
			credentials,
			login
		}
	}
})
