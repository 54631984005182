
import { defineComponent } from 'vue'

import { DataTable } from '@/components'

import config from './config'
import AuthHelper from '@/helpers/auth'

export default defineComponent({
  components: {
    DataTable,
  },
  setup() {
    config.editor!.delete = AuthHelper.roleId.value > 0 && AuthHelper.roleId.value <=2;
    
    return {
      config,
    }
  },
})
