import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_form_item, {
      prop: "userName",
      label: _ctx.trans('label.username'),
      rules: [
			{
				required: true,
				message: _ctx.trans('message.field_require', _ctx.trans('label.username'))
			},
			{ validator: _ctx.checkUsername }
		]
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_input, {
          modelValue: _ctx.model.userName,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.userName) = $event)),
          maxlength: 50,
          disabled: _ctx.mode !== 'create',
          "show-word-limit": ""
        }, null, 8, ["modelValue", "disabled"])
      ]),
      _: 1
    }, 8, ["label", "rules"]),
    _createVNode(_component_el_form_item, {
      prop: "lastName",
      label: _ctx.trans('label.last-name'),
      rules: {
			required: true,
			message: _ctx.trans('message.field_require', _ctx.trans('label.last-name'))
		}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_input, {
          modelValue: _ctx.model.lastName,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.lastName) = $event)),
          maxlength: 50,
          "show-word-limit": ""
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["label", "rules"]),
    _createVNode(_component_el_form_item, {
      prop: "firstName",
      label: _ctx.trans('label.first-name'),
      rules: {
			required: true,
			message: _ctx.trans('message.field_require', _ctx.trans('label.first-name'))
		}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_input, {
          modelValue: _ctx.model.firstName,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.firstName) = $event)),
          maxlength: 50,
          "show-word-limit": ""
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["label", "rules"]),
    _createVNode(_component_el_form_item, {
      prop: "password",
      label: _ctx.trans('label.password'),
      rules: [
			{
				required: _ctx.mode === 'create',
				message: _ctx.trans('message.field_require', _ctx.trans('label.password'))
			},
			{
				pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/,
				message: _ctx.trans('message.field_password-fail')
			}
		]
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_input, {
          modelValue: _ctx.model.password,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.password) = $event)),
          type: "password",
          maxlength: 20,
          "show-word-limit": ""
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["label", "rules"]),
    _createVNode(_component_el_form_item, {
      prop: "confirmPassword",
      label: _ctx.trans('label.confirm-password'),
      rules: { validator: _ctx.confirmPassword }
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_input, {
          modelValue: _ctx.retypePassword,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.retypePassword) = $event)),
          type: "password",
          maxlength: 20,
          "show-word-limit": ""
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["label", "rules"]),
    (!_ctx.henderson)
      ? (_openBlock(), _createBlock(_component_el_form_item, {
          key: 0,
          prop: "userRoleId",
          label: _ctx.trans('label.role'),
          rules: {
			required: true,
			message: _ctx.trans('message.field_require', _ctx.trans('label.role'))
		}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_select, {
              modelValue: _ctx.model.userRoleId,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.userRoleId) = $event)),
              placeholder: _ctx.trans('label.nothing-selected'),
              "automatic-dropdown": false,
              filterable: ""
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.roles, (role) => {
                  return (_openBlock(), _createBlock(_component_el_option, {
                    label: role.userRoleName,
                    value: role.userRoleId
                  }, null, 8, ["label", "value"]))
                }), 256))
              ]),
              _: 1
            }, 8, ["modelValue", "placeholder"])
          ]),
          _: 1
        }, 8, ["label", "rules"]))
      : _createCommentVNode("", true)
  ], 64))
}