import dayjs     from 'dayjs'
import * as XLSX from 'xlsx'

class ReportHelper {
  static export(
    fields: any,
    result: any[],
    name: string,
  ) {
    const header = Object.keys(fields)
    const data   = [ fields ]
    const time   = dayjs()
      .format('YYYY-MM-DD HH:mm:ss')

    result.forEach(record => {
      const {
              _X_ROW_KEY,
              ...row
            } = record

      data.push(row)
    })

    const workbook  = XLSX.utils.book_new()
    const worksheet = XLSX.utils.json_to_sheet(data, {
      header,
      skipHeader: true,
    })

    XLSX.utils.book_append_sheet(workbook, worksheet, name)
    XLSX.writeFile(workbook, `${name} Report-${time}.xlsx`)
  }
}

export default ReportHelper
