
import { defineComponent, ref } from "vue"
import { RouteRecordRaw, useRoute, useRouter } from "vue-router"

import { menuRoutes } from "@/router"
import AuthHelper from "@/helpers/auth"
import { Icon } from "@iconify/vue"

export default defineComponent({
	components: {
		Icon
	},
	setup() {
		const active = ref("")
		const router = useRouter()
		const route = useRoute()
		const isStaff = AuthHelper.isStaff
		const isMobile = AuthHelper.isMobile

		function updateSidebar() {
			const { path } = route

			active.value = _.trimEnd(path, "/")
		}

		function checkPermission(route: RouteRecordRaw): boolean {
			const isStaffValue = isStaff.value
			const permissions = AuthHelper.permissions.value

			const notTypeOnly = checkNotTypeOnly(
				route,
				isStaffValue ? "tenant" : "staff"
			)
			let withPermission = true

			if (
				route.meta?.permission &&
				(isStaffValue || <number>route.meta?.permission > 90)
			)
				withPermission = permissions.includes(<number>route.meta?.permission)

			return !notTypeOnly && withPermission
		}

		function checkNotTypeOnly(route: RouteRecordRaw, type: string): boolean {
			return (
				route.meta?.[type] === true ||
				(!_.isEmpty(route.children) &&
					!_.some(
						route.children,
						(subRoute: RouteRecordRaw) => !(subRoute.meta?.[type] ?? false)
					))
			)
		}

		router.afterEach(updateSidebar)
		updateSidebar()

		return {
			isStaff,
			isMobile,
			menuRoutes,
			active,
			checkPermission,
			routeTo: router.push
		}
	}
})
