
import {
	defineComponent,
	inject,
	nextTick,
	onBeforeUnmount,
	onMounted,
	PropType,
	ref
} from "vue"
import { VxeTableInstance } from "vxe-table"

import { useRoute } from "vue-router"

import { IDTTableConfig } from "./types"
import { DataHook, EditorHook } from "./hooks"
import DataTableBodyActionCustom from "./body/DataTableBodyActionCustom.vue"
import DataTableBodyActionMode from "./body/DataTableBodyActionMode.vue"
import DataTableBodyActionStatus from "./body/DataTableBodyActionStatus.vue"
import DataTableBodyActionDelete from "./body/DataTableBodyActionDelete.vue"
import { Icon } from "@iconify/vue"

export default defineComponent({
	components: {
		DataTableBodyActionCustom,
		DataTableBodyActionMode,
		DataTableBodyActionStatus,
		DataTableBodyActionDelete,
		Icon
	},
	props: {
		config: {
			type: Object as PropType<IDTTableConfig>,
			required: true
		},
		checkbox: {
			type: Boolean,
			required: true
		}
	},
	setup({ config }) {
		const table = ref<VxeTableInstance>()
		const height = ref<number>(100)
		const data = inject<DataHook>("data-hook")!
		const editor = inject<EditorHook>("editor-hook")!
		const withAction =
			!_.isEmpty(config.action) &&
			_.some(config.action, (option: any) => option)
		const defaultSort = config.sort
			? config.sort
			: {
					field: _.first(config.columns)!.prop,
					order: "asc"
			  }
		let actionWidth = 85
		const route = useRoute()
		const lowerProp = defaultSort.field.toLowerCase()

		if (lowerProp.includes("date") || lowerProp.includes("time"))
			defaultSort.order = "desc"

		async function onResize() {
			await nextTick()

			height.value = document.body.clientHeight - 247
		}

		function onCellDoubleClick({ row, column }: any) {
			if (column.title === "Action") return

			if (config.action.mode) editor.show(config.action.mode!, row._id)
		}

		onMounted(() => {
			onResize()

			window.addEventListener("resize", onResize)
			editor.setTable(table)
		})
		onBeforeUnmount(() => window.removeEventListener("resize", onResize))

		if (_.has(route.query, "id")) {
			let id = _.toNumber(route.query.id)
			editor.show(config.action.mode! || "view", id)
		}

		return {
			table,
			height,
			withAction,
			actionWidth,
			loading: data.loading,
			rowData: data.rowData,
			sort: { defaultSort },
			onCellDoubleClick
		}
	}
})
