
import {
  defineComponent,
  reactive,
}                    from 'vue'
import axios         from 'axios'
import { ElMessage } from 'element-plus'

import LangHelper from '@/helpers/lang'


export default defineComponent({
  setup() {
    const options = reactive<any>({
      port : 6789,
      type : 'rfid',
      query: '',
    })
    const result  = reactive<any>({
      url     : '',
      response: '\n',
    })

    function setQuery(
        type: string,
    ) {
      switch (type) {
        case 'open':
          options.query = 'action=open-port\nip=192.168.1.1\nport=4001'
          break

        case 'close':
          options.query = 'action=close-port'
          break

        case 'read':
          options.query = 'action=read'
          break

        case 'write':
          options.query = 'action=write\ndata=test'
          break
      }
    }

    async function sendRequest() {
      const query = options.query
                           .split('\n')
                           .map((part: string) => part.trim())
                           .filter((part: string) => part !== '')
                           .join('&')

      result.url      = ''
      result.response = '\n'

      try {
        const endPoint = `http://localhost:${options.port}/${options.type}?${query}`
        const response = await axios.get(endPoint)

        result.url      = endPoint
        result.response = syntaxHighlight(response.data)
      } catch (error) {
        ElMessage.error(LangHelper.trans('message.com_connect_fail'))
      }
    }

    function syntaxHighlight(
        data: string | object,
    ) {
      let json: string = _.isString(data) ? data as string : JSON.stringify(data, undefined, 2)

      json = json.replace(/&/g, '&amp;')
                 .replace(/</g, '&lt;')
                 .replace(/>/g, '&gt;')

      return json.replace(
          /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
          match => {
            let cls = 'number'

            if (/^"/.test(match))
              if (/:$/.test(match))
                cls = 'key'
              else
                cls = 'string'
            else if (/true|false/.test(match))
              cls = 'boolean'
            else if (/null/.test(match))
              cls = 'null'

            return `<span class="${cls}">${match}</span>`
          })
    }

    return {
      options,
      result,
      setQuery,
      sendRequest,
    }
  },
})
