import { IConfig } from '@/helpers/types'

const config: IConfig = {
  camera_ip  : '',
  camera_port  : '',
  camera_username  : '',
  camera_password  : '',

  connector_port: '6789',

  rfid_ip  : '',
  rfid_port: '',

  qrcode1_ip  : '',
  qrcode1_port: '',

  qrcode2_ip  : '',
  qrcode2_port: '',
}

class ConfigHelper {
  static get config(): IConfig {
    return config
  }

  static init() {
    _.forEach(
      config,
      (
        value: string,
        key: keyof IConfig,
      ) => {
        const [ group, item ] = key.split('_')
        const temp            = ConfigHelper.get(group, item)

        if (temp === null)
          ConfigHelper.set(group, item, value)
        else
          config[key] = temp
      },
    )
  }

  static get(
    group: string,
    item: string,
  ): string | null {
    const key = `config.${group}_${item}`

    return localStorage.getItem(key)
  }

  static set(
    group: string,
    item: string,
    value: string,
  ): boolean {
    const key   = `${group}_${item}`
    const lsKey = `config.${key}`

    if (!_.has(config, key))
      return false

    localStorage.setItem(lsKey, value)

    config[key as keyof IConfig] = value

    return true
  }
}

export default ConfigHelper
