
import { defineComponent } from "vue"

import AuthHelper from "@/helpers/auth"
import LangHelper from "@/helpers/lang"

import Chart from "./Chart.vue"
import { Icon } from "@iconify/vue"

interface IItem {
	title: string
	icon: string
	url: string
	show: boolean
}

export default defineComponent({
	components: {
		Icon,
		Chart
	},
	setup() {
		const mobileVersion = AuthHelper.mobileVersion
		const permissions = AuthHelper.permissions.value
		const items: IItem[] = [
			{
				title: LangHelper.trans("label.qrcode-validation"),
				icon: "ic:baseline-qr-code-scanner",
				url: "/visitor/reg-validation",
				show: permissions.includes(5)
			},
			{
				title: LangHelper.trans("label.check-rfid-card"),
				icon: "ic:outline-badge",
				url: "/visitor/validation",
				show: permissions.includes(7) && AuthHelper.is.mobileVersion
			},
			{
				title: LangHelper.trans("label.visitor-registration"),
				icon: "ic:outline-assignment-ind",
				url: "/visitor/registration",
				show: permissions.includes(6)
			},
			{
				title: LangHelper.trans("label.gate-control"),
				icon: "ic:outline-door-sliding",
				url: "/gate-control",
				show: permissions.includes(11)
			}
		]

		return {
			mobileVersion,
			items
		}
	}
})
