
import { ElForm }    from 'element-plus'
import { ElMessage } from 'element-plus/es'
import {
  defineComponent,
  reactive,
  ref,
}                    from 'vue'
import axios         from 'axios'
import dayjs         from 'dayjs'

import { CodeScanner } from '@/components'
import LangHelper      from '@/helpers/lang'
import ResourceHelper  from '@/helpers/resource'

import VisitorProfile    from '../components/VisitorProfile.vue'
import VisitingCompanies from '../components/VisitingCompanies.vue'
import { defaultModel }  from '../components/config'


export default defineComponent({
  components: {
    VisitorProfile,
    CodeScanner,
    VisitingCompanies,
  },
  setup() {
    const open           = ref<boolean>(false)
    const show           = ref<boolean>(false)
    const outed          = ref<boolean>(false)
    const companies      = ref<any[]>([])
    const scannedCode    = ref<string>('')
    const model          = reactive<any>({
      _id: '',
      ...defaultModel,
    })
    const visitCompanies = ref<any[]>([])
    const entryRecords   = ref<any[]>([])

    async function trigger() {
      if (!open.value)
        return open.value = true

      if (show.value)
        await manualSignOut()
    }

    async function loadVisitor(
      code: string,
    ) {
      scannedCode.value = code

      const visitor = await axios.get(`/visitor/general/find/all/${code}`)

      if (
        !visitor ||
        visitor.status !== 200
      ) {
        show.value = false

        return
      }

      Object.keys(model)
            .forEach((key: string) => model![key] = visitor.data[key])

      if (!_.isEmpty(visitor.data.visitDate))
        model.visitDate = dayjs(visitor.data.visitDate)
          .toDate()

      const visitingCompanies = await axios.get(`/visitor/contact/${visitor.data._id}`)

      if (visitingCompanies.status === 200)
        visitCompanies.value = visitingCompanies.data

      const visitorEntries = await axios.get(`/visitor/entry/find/${visitor.data.RFID}/${visitor.data._id}`)

      if (visitingCompanies.status === 200)
        entryRecords.value = visitorEntries.data

      const compareFormat = 'YYYY-MM-DD'
      const eventDate     = dayjs(visitorEntries?.data[0]?.eventTime)
      const todayDate     = dayjs()
      const isToday       = eventDate
                              .format(compareFormat)
                            === todayDate
                              .format(compareFormat)
      if (visitorEntries.data.length) {
        switch (visitorEntries.data[0].IO) {
          case 'In':
            outed.value = false
            break

          case 'Out':
            // if (!isToday) {
            //   show.value = false

            //   await clearData()
            //   return
            // }

            outed.value = true
            break
        }
      }

      show.value = true
    }

    async function clearData() {
      const items: string[] = []

      _.forEach(
        defaultModel,
        (
          value: any,
          key: string,
        ) => {
          items.push(key)

          model[key] = value
        },
      )

      visitCompanies.value = []
      entryRecords.value   = []
    }

    async function manualSignOut() {
      if (outed.value)
        return

      const { data: response } = await axios.put(`/visitor/entry/manual-sign-out/${model.RFID}/${model._id}`)

      if (!response.success)
        return ElMessage.error(LangHelper.trans('message.logout_fail'))

      await loadVisitor(scannedCode.value)
    }

    async function getRelatedData() {
      companies.value = await ResourceHelper.List('/tenant/profile')
    }

    getRelatedData()

    return {
      open,
      show,
      outed,
      companies,
      scannedCode,
      model,
      visitCompanies,
      entryRecords,
      trigger,
      loadVisitor,
      manualSignOut,
    }
  },
})
