
import axios              from 'axios'
import dayjs              from 'dayjs'
import {
  defineComponent,
  nextTick,
  ref,
}                         from 'vue'
import VChart             from 'vue-echarts'
import { use }            from 'echarts/core'
import { LineChart }      from 'echarts/charts'
import {
  GridComponent,
  LegendComponent,
  TooltipComponent,
}                         from 'echarts/components'
import { CanvasRenderer } from 'echarts/renderers'

use(
    [
      CanvasRenderer,
      LineChart,
      GridComponent,
      LegendComponent,
      TooltipComponent,
    ],
)

export default defineComponent({
  components: {
    VChart,
  },
  setup() {
    const seriesBase = {
      type     : 'line',
      areaStyle: {},
      emphasis : {
        focus: 'series',
      },
    }
    const show       = ref<boolean>(false)
    const option     = ref<any>({})

    const date  = {
      current : dayjs(),
      previous: dayjs()
          .subtract(1, 'month'),
    }
    const month = {
      current : date.current.format('MMMM'),
      previous: date.previous.format('MMMM'),
    }
    const days  = Math.max(date.current.daysInMonth(), date.previous.daysInMonth())

    function redraw(callback?: Function) {
      show.value = false

      if (callback)
        callback()

      nextTick(() => show.value = true)
    }

    async function getData() {
      const { data: response } = await axios.get('/dashboard/data')

      if (!response.success)
        return

      const chartData = response.data.chart

      redraw(() => {
        option.value = {
          tooltip: {
            trigger    : 'axis',
            axisPointer: {
              type : 'cross',
              label: {
                backgroundColor: '#3db2c7',
              },
            },
          },
          legend : {
            right: true,
            data : [
              month.previous,
              month.current,
            ],
          },
          grid   : {
            left        : 55,
            right       : 55,
            bottom      : 25,
            containLabel: true,
          },
          xAxis  : {
            type       : 'category',
            boundaryGap: false,
            name       : 'Date',
            data       : Array.from({ length: days }, (_, i) => i + 1),
          },
          yAxis  : {
            name       : 'In Count',
            minInterval: 10,
          },
          series : [
            {
              name: month.previous,
              ...seriesBase,
              data: chartData.previous,
            },
            {
              name: month.current,
              ...seriesBase,
              data: chartData.current,
            },
          ],
        }
      })
    }

    window.addEventListener(
        'resize',
        _.debounce(
            () => nextTick(() => redraw()),
            250,
        ),
    )

    getData()

    return {
      show,
      option,
    }
  },
})
