
import axios from "axios"
import { ElMessage } from "element-plus/es"
import { defineComponent, reactive, ref, toRaw } from "vue"

import { getDateRange, shortcuts } from "@/helpers/date"
import LangHelper from "@/helpers/lang"
import ResourceHelper from "@/helpers/resource"
import ReportHelper from "@/helpers/report"
import WrapperFooter from "@/views/wrapper/WrapperFooter.vue"

import { fields, parseData } from "./report"

import Preview from "../Preview.vue"
import { Icon } from "@iconify/vue"

export default defineComponent({
	components: {
		Icon,
		Preview,
		WrapperFooter
	},
	setup() {
		const staffs = ref<any[]>([])
		const tenants = ref<any[]>([])
		const result = ref<any[]>([])
		const model = reactive<any>({
			lastname: "",
			firstname: "",
			phone: "",
			remarks: "",
			date: getDateRange(1, "week"),
			voidBy: null
		})

		async function getRelatedData() {
			staffs.value = await ResourceHelper.List("/staff/user")
			tenants.value = _.sortBy(await ResourceHelper.List("/tenant/profile"), [
				"tenantName"
			])
		}

		getRelatedData()

		async function filterData() {
			result.value = []

			const { data: response } = await axios.post(
				"/report/blacklist",
				toRaw(model)
			)

			const related = {
				staffs: staffs.value,
				tenants: tenants.value
			}
			const data = response.data.map((row: any) => parseData(row, related))

			if (data.length === 0)
				ElMessage.error(LangHelper.trans("message.blacklist_not-found"))

			result.value = data
		}

		async function exportData() {
			if (result.value.length === 0)
				return ElMessage.error(
					LangHelper.trans("message.blacklist_export_not-found")
				)

			ReportHelper.export(fields, result.value, "Blacklist")
		}

		return {
			staffs,
			tenants,
			result,
			model,
			shortcuts,
			fields,
			filterData,
			exportData
		}
	}
})
