
import { EditorHook } from "@/components/data-table/hooks"
import { defineComponent, inject, reactive, ref, toRaw } from "vue"
import { ElForm } from "element-plus"

export default defineComponent({
	setup() {
		const { mode, model } = inject<EditorHook>("editor-hook")!
		const personForm = ref<InstanceType<typeof ElForm>>()
		const personModel = reactive<any>({
			tenantContactId: -1,
			contactPerson: "",
			contactNo: "",
			contactEmail: ""
		})

		async function addContactPerson() {
			try {
				await personForm.value!.validate()

				model.contactPersons = [
					...(model.contactPersons || []),
					{ ...toRaw(personModel) }
				]

				personForm.value!.resetFields()
			} catch (e) {}
		}

		async function removeContactPerson(targetIndex: number) {
			model.contactPersons = model.contactPersons.map(
				(person: any, index: number) => {
					if (index == targetIndex) person.deleted = true
					return person
				}
			)
		}

		return {
			mode,
			model,
			personForm,
			personModel,
			addContactPerson,
			removeContactPerson
		}
	}
})
