
import { defineComponent, inject, onMounted, ref } from "vue"

import axios from "axios"

import { EditorHook } from "@/components/data-table/hooks"

import VisitingCompanies from "../components/VisitingCompanies.vue"
import VisitorInformation from "../components/VisitorInformation.vue"
import HostInformation from "../components/HostInformation.vue"
import VisitorList from "../components/VisitorList.vue"

export default defineComponent({
	components: {
		VisitingCompanies,
		VisitorInformation,
		HostInformation,
		VisitorList,
	},
	setup() {
		const visitCompanies = ref<any[]>([])
		const visitorList = ref<InstanceType<typeof VisitorList>>()
		const editorHook = inject<EditorHook>("editor-hook")!

		onMounted(() => visitorList.value!.setVisitors(editorHook.model.visitors))

		async function getRelatedData() {
			if (!editorHook.model.visitors || editorHook.model.visitors.length == 0)
				return
			const visitorId = editorHook.model.visitors[0].visitorId
			const visitingCompanies = await axios.get(`/visitor/contact/${visitorId}`)

			if (visitingCompanies.status === 200)
				visitCompanies.value = visitingCompanies.data
		}

		getRelatedData()

		return {
			visitCompanies,
			visitorList,
			...editorHook.expose,
		}
	},
})
