import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_preview = _resolveComponent("preview")!
  const _component_wrapper_footer = _resolveComponent("wrapper-footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_card, {
      header: _ctx.trans('label.report-filter')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form, {
          ref: "form",
          "label-width": "140px",
          "label-position": "left",
          model: _ctx.model
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              prop: "floorId",
              label: _ctx.trans('label.floor')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: _ctx.model.floorId,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.floorId) = $event)),
                  placeholder: _ctx.trans('label.nothing-selected'),
                  "automatic-dropdown": false,
                  filterable: "",
                  clearable: ""
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.floors, (floor) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        label: floor.floorName,
                        value: floor.floorId
                      }, null, 8, ["label", "value"]))
                    }), 256))
                  ]),
                  _: 1
                }, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_form_item, {
              prop: "name",
              label: _ctx.trans('label.company-name')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.model.name,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.name) = $event)),
                  maxlength: 50,
                  "show-word-limit": ""
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_form_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  type: "primary",
                  onClick: _withModifiers(_ctx.filterData, ["prevent"])
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Icon, { icon: "ic:baseline-search" }),
                    _createElementVNode("span", null, _toDisplayString(_ctx.trans("label.search")), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model"])
      ]),
      _: 1
    }, 8, ["header"]),
    _createVNode(_component_preview, {
      fields: _ctx.fields,
      result: _ctx.result
    }, null, 8, ["fields", "result"]),
    _createVNode(_component_wrapper_footer, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_button, {
          type: "warning",
          onClick: _withModifiers(_ctx.exportData, ["prevent"])
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Icon, { icon: "ic:baseline-cloud-download" }),
            _createElementVNode("span", null, _toDisplayString(_ctx.trans("label.export")) + " (" + _toDisplayString(_ctx.result.length) + ")", 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    })
  ], 64))
}