import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createBlock(_component_el_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_el_form, {
        ref: "form",
        "label-width": "175px",
        model: _ctx.general,
        "status-icon": ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, {
            label: _ctx.trans('label.username')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.username,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.username) = $event)),
                autocomplete: "off",
                readonly: ""
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_divider, { "content-position": "left" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.trans('label.change-password')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, {
            prop: "current",
            label: _ctx.trans('label.current-password'),
            rules: {
                      required: true,
                      message: _ctx.trans('message.field_require', _ctx.trans('label.current-password')),
                    }
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.general.current,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.general.current) = $event)),
                type: "password",
                autocomplete: "off"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["label", "rules"]),
          _createVNode(_component_el_form_item, {
            prop: "new",
            label: _ctx.trans('label.new-password'),
            rules: {
                      required: true,
                      message: _ctx.trans('message.field_require', _ctx.trans('label.new-password')),
                    }
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.general.new,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.general.new) = $event)),
                type: "password",
                autocomplete: "off"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["label", "rules"]),
          _createVNode(_component_el_form_item, {
            prop: "confirm",
            label: _ctx.trans('label.confirm-password'),
            rules: {
                      required: true,
                      message: _ctx.trans('message.field_require', _ctx.trans('label.confirm-password')),
                    }
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.general.confirm,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.general.confirm) = $event)),
                type: "password",
                autocomplete: "off"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["label", "rules"]),
          _createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_button, {
                type: "primary",
                onClick: _ctx.changePassword
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.trans('label.change-password')), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model"])
    ]),
    _: 1
  }))
}