
import LangHelper from '@/helpers/lang'
import {
  defineComponent,
  ref,
}                 from 'vue'
import {
  useRoute,
  useRouter,
}                 from 'vue-router'

import AuthHelper from '@/helpers/auth'

export default defineComponent({
  setup() {
    const isStaff = AuthHelper.isStaff
    const title   = ref<string>('')
    const router  = useRouter()
    const route   = useRoute()

    function updateBreadcrumb() {
      const { matched } = route

      title.value = matched.filter(route => route.name)
                           .map(route => route.name as string)
                           .pop()!

      if (title.value === LangHelper.trans('label.dashboard'))
        title.value = isStaff.value ? ' ' : 'Hi! Welcome back'
    }

    router.afterEach(updateBreadcrumb)
    updateBreadcrumb()

    return {
      title,
    }
  },
})
