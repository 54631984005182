import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "data-table__editor-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.visible,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.visible) = $event)),
    title: _ctx.title,
    "append-to-body": true,
    "show-close": false,
    onOpen: _ctx.editorOpen,
    onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.mode = 'hide')),
    onClosed: _ctx.editorClose,
    "lock-scroll": ""
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, [
          (_ctx.allowDelete && _ctx.mode === 'edit' && !_ctx.readonly)
            ? (_openBlock(), _createBlock(_component_el_button, {
                key: 0,
                type: "danger",
                onClick: _ctx.remove
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", null, _toDisplayString(_ctx.trans("label.delete")), 1)
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", null, [
          _createVNode(_component_el_button, {
            plain: "",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.mode = 'hide'))
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(_ctx.trans("label.cancel")), 1)
            ]),
            _: 1
          }),
          (_ctx.mode === 'create')
            ? (_openBlock(), _createBlock(_component_el_button, {
                key: 0,
                type: "warning",
                onClick: _ctx.create
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", null, _toDisplayString(_ctx.trans("label.create")), 1)
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true),
          (_ctx.allowEdit && !_ctx.readonly && _ctx.mode === 'view')
            ? (_openBlock(), _createBlock(_component_el_button, {
                key: 1,
                type: "warning",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (
							_ctx.editRedirect
								? _ctx.$router.push(`${_ctx.editRedirect}${_ctx.recordId}`)
								: (_ctx.mode = 'edit')
						))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", null, _toDisplayString(_ctx.trans("label.edit")), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (!_ctx.readonly && _ctx.mode === 'edit')
            ? (_openBlock(), _createBlock(_component_el_button, {
                key: 2,
                type: "warning",
                onClick: _ctx.update
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", null, _toDisplayString(_ctx.trans("label.save")), 1)
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_el_form, {
        ref: "form",
        "label-width": "150px",
        model: _ctx.model,
        "label-position": _ctx.labelPosition,
        disabled: _ctx.disable
      }, {
        default: _withCtx(() => [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.component)))
        ]),
        _: 1
      }, 8, ["model", "label-position", "disabled"])
    ]),
    _: 1
  }, 8, ["modelValue", "title", "onOpen", "onClosed"]))
}