import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!

  return (_openBlock(), _createBlock(_component_el_dropdown_item, {
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openEditor(_ctx.id)))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Icon, { icon: _ctx.icon }, null, 8, ["icon"]),
      _createElementVNode("span", null, _toDisplayString(_ctx.text), 1)
    ]),
    _: 1
  }))
}