import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "report-preview" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_empty = _resolveComponent("el-empty")!
  const _component_vxe_column = _resolveComponent("vxe-column")!
  const _component_vxe_table = _resolveComponent("vxe-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_vxe_table, {
      border: "inner",
      "show-overflow": "tooltip",
      data: _ctx.result,
      fit: true,
      "max-height": _ctx.maxHeight,
      loading: _ctx.loading,
      "loading-config": {
                 icon: 'vxe-icon-indicator roll',
                 text: 'Loading ...'
               },
      "row-config": {
                 height: 40,
                 isHover: true
               }
    }, {
      empty: _withCtx(() => [
        _createVNode(_component_el_empty, {
          description: _ctx.trans('message.other_search-first')
        }, null, 8, ["description"])
      ]),
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (field, key) => {
          return (_openBlock(), _createBlock(_component_vxe_column, {
            field: key,
            title: field
          }, null, 8, ["field", "title"]))
        }), 256))
      ]),
      _: 1
    }, 8, ["data", "max-height", "loading", "loading-config"])
  ]))
}