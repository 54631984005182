
import {
  defineComponent,
  reactive,
  ref,
}                    from 'vue'
import { ElMessage } from 'element-plus'
import axios         from 'axios'

import AuthHelper from '@/helpers/auth'
import LangHelper from '@/helpers/lang'


export default defineComponent({
  setup() {
    const form    = ref()
    const general = reactive<any>({
      current: '',
      new    : '',
      confirm: '',
    })

    async function changePassword() {
      try {
        await form.value!.validate()

        if (general.current === general.new)
          return ElMessage.error(LangHelper.trans('message.password_same_fail'))

        if (general.new !== general.confirm)
          return ElMessage.error(LangHelper.trans('message.password_not-match_fail'))

        const response = await axios.post('/auth/change-password', {
          ...general,
          username: AuthHelper.username.value,
        })

        if (!response.data.success)
          return ElMessage.error(LangHelper.trans('message.password_not-correct_fail'))

        form.value!.resetFields()
        ElMessage.success(LangHelper.trans('message.password_update_success'))
      } catch (e) {
      }
    }

    return {
      form,
      username: AuthHelper.username,
      general,
      changePassword,
    }
  },
})
