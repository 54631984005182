
import LangHelper from "@/helpers/lang"
import { defineComponent, inject, PropType } from "vue"

import { IDataTableAction } from "../types"
import { EditorHook } from "../hooks"
import { Icon } from "@iconify/vue"

// const icons: any = {
//   view: 'ic:baseline-preview',
//   edit: 'ic:baseline-edit',
// }

export default defineComponent({
	components: { Icon },
	props: {
		config: {
			type: Object as PropType<IDataTableAction>,
			required: true
		},
		id: {
			type: [String, Number],
			required: true
		}
	},
	setup({ config }) {
		const editorHook = inject<EditorHook>("editor-hook")!
		// const icon       = icons[config.mode!]
		// const text       = LangHelper.trans(`label.${config.mode!}`)
		const icon = "ic:baseline-preview"
		const text = LangHelper.trans(`label.view`)

		function openEditor(id: number) {
			editorHook.show(config.mode!, <number>id)
		}

		return {
			icon,
			text,
			openEditor
		}
	}
})
