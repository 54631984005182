
import ReportHelper from "@/helpers/report"
import WrapperFooter from "@/views/wrapper/WrapperFooter.vue"
import axios from "axios"
import { defineComponent, ref } from "vue"

import Preview from "../Preview.vue"

import { fields, parseData } from "./report"
import { Icon } from "@iconify/vue"

export default defineComponent({
	components: {
		Icon,
		Preview,
		WrapperFooter
	},
	setup() {
		const result = ref<any[]>([])
		let loading = ref<boolean>(false)

		async function loadData() {
			const { data: response } = await axios.post("/report/rfid-inventory")

			result.value = [parseData(response.data)]
			loading.value = false
		}

		loading.value = true
		loadData()

		async function exportData() {
			ReportHelper.export(fields, result.value, "RFID Inventory")
		}

		return {
			result,
			fields,
			exportData,
			loading
		}
	}
})
