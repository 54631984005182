
import { defineComponent } from 'vue'

import AuthHelper    from '@/helpers/auth'
import { useRouter } from 'vue-router'

import Staff from './Staff.vue'

export default defineComponent({
  components: {
    Staff,
  },
  setup() {
    const isStaff = AuthHelper.isStaff.value
    const router  = useRouter()

    if (!isStaff)
      router.push('/visitor/pre-registration')
  },
})
