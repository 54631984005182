import dayjs from 'dayjs'

export const fields = {
  date           : 'Date',
  salutation     : 'Salutation',
  lastName       : 'LastName',
  firstName      : 'FirstName',
  Phone          : 'Phone',
  blacklistedFrom: 'From',
  blacklistedBy  : 'BlacklistedBy',
  remarks        : 'Remarks',
}

function formatDate(date: string): string {
  return dayjs(date)
    // .utc()
    .format('YYYY-MM-DD HH:mm')
}

export function parseData(
  row: any,
  data: any,
): any {
  let blacklistedBy = ''

  if (row.blacklistedBy < 10000000) {
    const staff = data.staffs.find((staff: any) => row.blacklistedBy === staff.userId)

    if (staff)
      blacklistedBy = `${staff.lastName} ${staff.firstName}`
  } else {
    const tenantId = row.blacklistedBy - 10000000

    let tenant = data.tenants.find((tenant: any) => tenantId === tenant.tenantId)
    if(tenant)
      blacklistedBy = tenant.tenantName
    else 
      blacklistedBy = 'Tenant'
  }
 
  return {
    date           : formatDate(row.createdAt),
    salutation     : row.salutation,
    lastName       : row.lastName,
    firstName      : row.firstName,
    Phone          : row.phone,
    blacklistedFrom: row.blacklistedBy < 10000000 ? 'Staff' : 'Tenant',
    blacklistedBy,
    remarks        : row.remarks,
  }
}
