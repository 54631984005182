import axios from "axios"
import _ from "lodash"
import dayjs, { Dayjs } from "dayjs"
import { ElMessage, ElMessageBox } from "element-plus/es"

import {
	IDataTableConfig,
	IDTActionBarConfig,
	IDTDataConfig,
	IDTEditorConfig,
	IDTTableConfig
} from "@/components/data-table/types"
import AuthHelper from "@/helpers/auth"
import LangHelper from "@/helpers/lang"
import RecordHelper, { RecordType } from "@/helpers/record"
import ResourceHelper from "@/helpers/resource"

import VisitorEditor from "./VisitorEditor.vue"

import { defaultModel } from "../components/config"
import { ref } from "vue"

const endPoint = "/visitor/general"
let query = {}
const data = <IDTDataConfig>{
	source: endPoint,
	query,
	rowData: [],
	parser: (row: any) => {
		row.name = `${row.salutation} ${row.lastName} ${row.firstName}`.trim()
		row.entryType = row.entryType ?? "Single Entry"
		row.visitType = RecordHelper.Get(RecordType.VisitType, row.visitorType)
		row.createUser = RecordHelper.Get(RecordType.User, row.createUserId)

		row.createDatetime = dayjs(row.createDatetime)
		row.visitDate = dayjs(row.visitDate)
		row.entryTime = dayjs(row.entryTime)
		// .utc()
		row.exitTime = dayjs(row.exitTime)
		// .utc()

		return row
	},
	// searchByDate: (
	//   row: any,
	//   [ start, end ]: Dayjs[],
	// ) => row.visitDate.isValid() &&
	//      (
	//        row.visitDate.isSame(start) ||
	//        row.visitDate.isSame(end) ||
	//        (
	//          row.visitDate.isAfter(start) &&
	//          row.visitDate.isBefore(end)
	//        )
	//      ),
	search: (kw) => {
		_.set(query, "kw", kw)
		return
	},
	searchByVisitDate: (visitStartDate, visitEndDate) => {
		_.set(query, "visitStartDate", visitStartDate)
		_.set(query, "visitEndDate", visitEndDate)

		return
	}
}

const actionBar = <IDTActionBarConfig>{
	search: "visitDate",
	date: getSearchDate(),
	resetdate: () => {
		actionBar.date = getSearchDate()
	}
}

function getSearchDate() {
	const date = ref<Date[]>([
		dayjs().set("hour", 0).set("minute", 0).set("second", 0).toDate(),
		dayjs()
			.add(7, "day")
			.set("hour", 0)
			.set("minute", 0)
			.set("second", 0)
			.toDate()
	])

	return date
}

function getDateTime(value: Dayjs) {
	const time = value.format("HH:mm")
	const datetime = value.unix()

	return time === "Invalid Date" || !value ? -1 : datetime
}

function getTime(value: Dayjs) {
	const time = value.format("HH:mm")

	return time === "Invalid Date" || !value ? "-" : time
}

const table = <IDTTableConfig>{
	columns: [
		{
			title: LangHelper.trans("label.name"),
			prop: "name",
			sortable: true
		},
		{
			title: LangHelper.trans("label.company"),
			prop: "visitingCompanies",
			sortable: true
		},
		{
			title: LangHelper.trans("label.event-name"),
			prop: "otherName",
			sortable: true
		},
		{
			title: LangHelper.trans("label.phone"),
			prop: "contactNo",
			sortable: true
		},
		{
			title: LangHelper.trans("label.visitor-company"),
			prop: "visitorCompany",
			sortable: true
		},
		{
			title: LangHelper.trans("label.visit-date"),
			prop: "visitDate",
			sortable: true,
			formatter: ({ cellValue }: any) => cellValue.format("YYYY-MM-DD")
		},
		{
			title: LangHelper.trans("label.registration-date"),
			prop: "createDatetime",
			sortable: true,
			formatter: ({ cellValue }: any) => cellValue.format("YY-MM-DD HH:mm")
		},
		{
			title: LangHelper.trans("label.entry-time"),
			prop: "entryTime",
			sortable: true,
			sorter: ({ row }: any) => getDateTime(row.entryTime),
			formatter: ({ cellValue }: any) => getTime(cellValue)
		},
		{
			title: LangHelper.trans("label.exit-time"),
			prop: "exitTime",
			sortable: true,
			sorter: ({ row }: any) => getDateTime(row.exitTime),
			formatter: ({ cellValue }: any) => getTime(cellValue)
		},
		{
			title: LangHelper.trans("label.status"),
			prop: "isDeleted",
			sortable: true,
			formatter: ({ row }: any) => {
				if (row.isDeleted) return "⨯"

				if (!row.entryTime || row.entryTime.format() === "Invalid Date")
					return "Reg."

				if (!row.exitTime || row.exitTime.format() === "Invalid Date")
					return "IN"

				return row.exitTime.unix() > row.entryTime.unix() ? "OUT" : "IN"
			}
		},
		{
			title: LangHelper.trans("label.created-by"),
			prop: "createUser",
			sortable: true
		}
	],
	sort: {
		field: "visitDate",
		order: "desc"
	},
	action: {
		mode: "view",
		status: {
			get: (row: any) => !row.isDeleted,
			set: async (row: any, status: boolean) => {
				const result = await ResourceHelper.Status(endPoint, row._id, status)

				return result.success
			}
		},
		custom: [
			{
				title: "Blacklist",
				icon: "ic:baseline-block",
				action: async (row: any) => {
					try {
						await ElMessageBox.confirm(
							LangHelper.trans("confirm.visitor_blacklist.message"),
							LangHelper.trans("confirm.visitor_blacklist.title"),
							{
								confirmButtonText: "Confirm",
								confirmButtonClass: "el-button--danger",
								cancelButtonText: LangHelper.trans("label.cancel"),
								type: "warning"
							}
						)

						const allow = await axios.get(
							`/visitor/general/blacklist/${row._id}`
						)

						if (!allow || allow.status !== 200 || !allow.data.success)
							return ElMessage.error(
								LangHelper.trans("message.visitor_blacklist_fail")
							)

						ElMessage.success(LangHelper.trans("message.visitor_entry_fail"))
					} catch (error) {
						return
					}
				},
				get hide() {
					const isStaff = AuthHelper.isStaff.value
					const permissions = AuthHelper.permissions.value

					if (AuthHelper.roleId.value == 3) return true

					if (!isStaff) return false

					return !permissions.includes(8)
				}
			}
		]
	}
}

const editor = <IDTEditorConfig>{
	title: LangHelper.trans("label.visitor"),
	component: VisitorEditor,
	delete: true,
	defaultModel,
	labelPosition: "top"
}

export default <IDataTableConfig>{
	data,
	actionBar,
	table,
	editor
}
