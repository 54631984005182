import LangHelper from '@/helpers/lang'
import Profile    from '@/views/user/profile/Profile.vue'
import Config     from '@/views/user/config/Config.vue'

const routes = [
  {
    path     : 'profile',
    name     : LangHelper.trans('label.profile'),
    meta     : {
      icon: 'ic:outline-contacts',
    },
    component: Profile,
  },
  {
    path     : 'config',
    name     : LangHelper.trans('label.config'),
    meta     : {
      icon: 'ic:baseline-settings',
    },
    component: Config,
  },
]

export default routes
