import dayjs from "dayjs"

// export const fields = {
//   status    : 'Status',
//   type      : 'Type',
//   company   : 'Company',
//   floor     : 'Floor',
//   suite     : 'Suite',
//   salutation: 'Salutation',
//   lastName  : 'LastName',
//   firstName : 'FirstName',
//   Phone     : 'Phone',
//   idType    : 'ID Type',
//   actionTime  : 'Time',
//   // entry     : 'Entry Time',
//   // exit      : 'Exit Time',
//   blacklist : 'Blacklist',
// }

// function getStatus(row: any): string {
// 	if (row.actionType === "REG") return "Reg."

// 	if (row.actionType === "IN") return "IN"

// 	if (row.actionType === "OUT") return "OUT"

// 	// if (dayjs(row.entry)
// 	//   .isAfter(row.exit))
// 	//   return 'Return'

// 	return ""
// }

export const fields = {
	visitorType: "Type",
	company: "Company",
	floor: "Floor",
	suite: "Suite",
	salutation: "Salutation",
	lastName: "LastName",
	firstName: "FirstName",
	contactNo: "Phone",
	visitDate: "Visit DateTime",
	inRecords: "Entry Time",
	outRecords: "Exit Time",
	blacklist: "Blacklist"
}

function formatDate(date: string, format: string): string {
	return (
		dayjs(date)
			// .utc()
			.format(format)
	)
}

export function parseData(row: any, data: any): any {
	const floor = data.floors.find((floor: any) => floor.floorId === row.floorId)

	return {
		visitorType:
			data.types.find((type: any) => row.visitorType === type.recordTypeId)
				.recordTypeName || "",
		company: row.company || "-",
		floor: floor ? floor.floorName : "-",
		// floor:
		// 	row.floorIds
		// 		.map(
		// 			(floorId: number) =>
		// 				data.floors.find((floor: any) => floor.floorId === floorId)
		// 					.floorName
		// 		)
		// 		.join(", ") || "-",
		suite: row.suite || "-",
		salutation: row.salutation || "-",
		lastName: row.lastName || "-",
		firstName: row.firstName || "-",
		contactNo: row.contactNo || "-",
		visitDate: formatDate(row.visitDate, "YYYY-MM-DD HH:mm") || "-",
		inRecords: row.inRecords || "-",
		outRecords: row.outRecords || "-",
		blacklist: row.isBlacklisted ? "Blacklisted" : "-"
	}
	// return {
	// 	status: getStatus(row),
	// 	type: data.types.find((type: any) => row.visitorType === type.recordTypeId)
	// 		.recordTypeName,
	// 	company: row.company,
	// 	floor: row.floorIds
	// 		.map(
	// 			(floorId: number) =>
	// 				data.floors.find((floor: any) => floor.floorId === floorId).floorName
	// 		)
	// 		.join(", "),
	// 	suite: row.suite,
	// 	salutation: row.salutation,
	// 	lastName: row.lastName,
	// 	firstName: row.firstName,
	// 	Phone: row.phone,
	// 	idType: "-",
	// 	actionTime: formatDate(row.actionTime),
	// 	// entry     : row.entry ? formatDate(row.entry) : '-',
	// 	// exit      : row.exit ? formatDate(row.exit) : '-',
	// 	blacklist: row.isBlacklisted ? "Blacklisted" : "-"
	// }
}
