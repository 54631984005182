import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "data-table__action-bar--export" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_button, {
      type: "warning",
      onClick: _withModifiers(_ctx.exportData, ["prevent"])
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Icon, { icon: "ic:baseline-cloud-download" }),
        _createElementVNode("span", null, _toDisplayString(_ctx.trans("label.export")), 1)
      ]),
      _: 1
    }, 8, ["onClick"])
  ]))
}