
import { defineComponent } from "vue"
import { Icon } from "@iconify/vue"

export default defineComponent({
	components: {
		Icon
	},
	props: {
		exportFilename: {
			type: String,
			default: ""
		}
	},
	setup(props) {
		function exportData() {}

		return {
			exportData
		}
	}
})
