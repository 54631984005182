import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "gate-control" }
const _hoisted_2 = { class: "gate-control__zone" }
const _hoisted_3 = { class: "gate-control__zone--title" }
const _hoisted_4 = { class: "gate-control__zone--title-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_gate = _resolveComponent("gate")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (zone) => {
      return (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_Icon, { icon: "ic:sharp-door-sliding" }),
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.trans("label.zone")) + " - " + _toDisplayString(zone.zoneName), 1)
        ]),
        _createVNode(_component_el_row, {
          class: "gate-control__zone--gates",
          gutter: 12
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(zone.gates, (gate) => {
              return (_openBlock(), _createBlock(_component_el_col, {
                key: gate.gateId,
                span: 6,
                sm: { span: 12 },
                md: { span: 12 },
                lg: { span: 8 },
                xs: { span: 24 }
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_gate, { gate: gate }, null, 8, ["gate"])
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 2
        }, 1024)
      ]))
    }), 256))
  ]))
}