import axios         from 'axios'
import { ElMessage } from 'element-plus'

import LangHelper    from '@/helpers/lang'

import ConfigHelper from './config'
import { CardType } from './types'


class PortHelper {
  private static _connectorRunning = false

  static port = parseInt(ConfigHelper.get('connector', 'port')!)
  static type: {
    [key: string]: PortHelper
  }           = {}

  private readonly type: CardType
  private opened  = false
  private reading = false

  ip: string
  port: string

  static get isConfigured(): boolean {
    return _.some(PortHelper.type, (serial: PortHelper) => serial.ip !== '')
  }

  static init() {
    _.forEach(CardType, (type: CardType) => PortHelper.type[type] = new PortHelper(type))
  }

  static async runConnector(
    retry: number = 5,
  ) {
    if (PortHelper._connectorRunning)
      return

    try {
      const endPoint = `http://localhost:${PortHelper.port}`
      const response = await axios.get(endPoint)

      if (response.data.connected)
        PortHelper._connectorRunning = true
    } catch (e) {
      location.assign('vmscc://')

      if (retry > 0)
        await PortHelper.runConnector(retry - 1)
    }
  }

  static setPort(
    port: number | string,
  ) {
    if (_.isString(port))
      port = parseInt(port as string)

    ConfigHelper.set('connector', 'port', port.toString())

    PortHelper.port = port as number
  }

  static async wait(
    ms: number,
  ): Promise<any> {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  static async close() {
    for (let type in PortHelper.type)
      await PortHelper.type[type].close()
  }

  static startReading(
    callback: Function,
    prefix: string = '',
  ) {
    for (let type in PortHelper.type)
      PortHelper.type[type].startReading(callback, prefix)
  }

  static stopReading() {
    for (let type in PortHelper.type)
      PortHelper.type[type].stopReading()
  }

  constructor(
    type: CardType,
  ) {
    this.type = type
    this.ip   = ConfigHelper.get(type, 'ip')!
    this.port = ConfigHelper.get(type, 'port')!
  }

  setItem(
    item: string,
    value: string,
  ) {
    (this as any)[item] = value
  }

  async send(
    query: string,
  ): Promise<any> {
    try {
      const endPoint = `http://localhost:${PortHelper.port}/${this.type}?${query}`
      const response = await axios.get(endPoint)
      const data     = response.data
      const status   = data.status

      if (data.message)
        if (status)
          ElMessage.success(data.message)
        else
          ElMessage.error(data.message)

      if (!status)
        return false

      if (data.data)
        return data.data
    } catch (error) {
      if (this.opened)
        ElMessage.error(LangHelper.trans('message.com_connect_fail'))

      this.opened = false

      return false
    }

    return true
  }

  async open() {
    if (
      this.opened ||
      this.ip === ''
    )
      return

    await PortHelper.runConnector()

    const result = await this.send(`action=open-port&ip=${this.ip}&port=${this.port}`)

    this.opened = result !== false
  }

  async close() {
    await this.send('action=close-port')

    this.opened = false
  }

  async clear() {
    await this.open()

    if (this.opened)
      await this.send('action=clear')
  }

  async read(): Promise<string> {
    await this.open()

    if (this.opened) {
      const data = await this.send('action=read')

      if (_.isObject(data))
        return (<any>data).ascii
      else
        return ''
    }

    return ''
  }

  async write(
    data: string,
  ) {
    if (!this.opened)
      await this.open()

    if (this.opened)
      await this.send(`action=write&data=${data}`)
  }

  async startReading(
    callback: Function,
    prefix: string = '',
  ): Promise<void> {
    if (this.reading)
      return

    if (!this.opened)
      await this.open()

    if (!this.opened)
      throw new Error(LangHelper.trans('message.reader_connect_fail'))

    await this.clear()

    this.reading = true

    while (
      this.opened &&
      this.reading
      ) {
      await PortHelper.wait(500)

      const data = await this.read()

      if (
        data !== '' &&
        data.startsWith(prefix)
      )
        callback(data)
    }

    this.reading = false
  }

  stopReading() {
    if (this.reading)
      this.reading = false
  }
}

(window as any).PortHelper = PortHelper

export default PortHelper
