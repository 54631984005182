
import { defineComponent } from 'vue'

import { DataTable } from '@/components'

import config from './config'

export default defineComponent({
  components: {
    DataTable,
  },
  setup() {
    return {
      config,
    }
  },
})
