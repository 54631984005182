
import axios from "axios"
import dayjs from "dayjs"
import { defineComponent, reactive, ref, toRaw } from "vue"
import { ElMessage } from "element-plus/es"

import LangHelper from "@/helpers/lang"
import ResourceHelper from "@/helpers/resource"
import ReportHelper from "@/helpers/report"
import WrapperFooter from "@/views/wrapper/WrapperFooter.vue"

import { fields, parseData } from "./report"

import Preview from "../Preview.vue"
import { Icon } from "@iconify/vue"

export default defineComponent({
	components: {
		Icon,
		Preview,
		WrapperFooter
	},
	setup() {
		const companies = ref<any[]>([])
		const floors = ref<any[]>([])
		const types = ref<any[]>([])
		const result = ref<any[]>([])
		// const model     = reactive<any>({
		//   date: dayjs()
		//       .toDate(),
		// })
		// const model     = reactive<any>(null)

		async function getRelatedData() {
			const result = await Promise.all([
				ResourceHelper.List("/tenant/profile"),
				ResourceHelper.List("/building/floor"),
				ResourceHelper.List("/visitor/type")
			])

			companies.value = result[0]
			floors.value = result[1]
			types.value = result[2]
		}

		async function filterData() {
			result.value = []

			// const { data: response } = await axios.post('/report/still-in-building', toRaw(model))
			const { data: response } = await axios.post("/report/still-in-building")

			const related = {
				companies: companies.value,
				floors: floors.value,
				types: types.value
			}
			const data = response.data.map((row: any) => parseData(row, related))

			if (data.length === 0)
				ElMessage.error(LangHelper.trans("message.visitor_export_not-found"))

			result.value = data
		}

		async function exportData() {
			if (result.value.length === 0)
				return ElMessage.error(
					LangHelper.trans("message.building_export_not-found")
				)

			ReportHelper.export(fields, result.value, "Still In Building")
		}

		getRelatedData()
		filterData()

		return {
			fields,
			result,
			// model,
			filterData,
			exportData
		}
	}
})
