
import { ElMessageBox } from 'element-plus/es'
import {
  defineComponent,
  reactive,
  ref,
  watch,
}                       from 'vue'
import axios            from 'axios'
import dayjs            from 'dayjs'

import { CodeScanner } from '@/components'
import LangHelper      from '@/helpers/lang'
import ResourceHelper  from '@/helpers/resource'

import { defaultModel }  from '../components/config'
import VisitingCompanies from '../components/VisitingCompanies.vue'
import VisitorProfile    from '../components/VisitorProfile.vue'


export default defineComponent({
  components: {
    VisitorProfile,
    CodeScanner,
    VisitingCompanies,
  },
  setup() {
    const show           = ref<boolean>(false)
    const companies      = ref<any[]>([])
    const scanModel      = reactive<any>({
      RFID: '',
    })
    const model          = reactive<any>({ ...defaultModel })
    const visitCompanies = ref<any[]>([])
    const entryRecords   = ref<any[]>([])
    const expired        = ref<boolean>(false)

    watch(
      () => scanModel.RFID,
      _.debounce(async (code: string) => {
        const visitor = await axios.get(`/visitor/general/find/rfid/${code}`)

        if (
          !visitor ||
          visitor.status !== 200
        ) {
          show.value = false

          return
        }

        if (
          visitor.data.isBlacklisted ||
          visitor.data.isDeleted
        )
          await ElMessageBox.alert(
            LangHelper.trans('alert.visitor_problem.message', visitor.data.isBlacklisted ? 'blacklisted' : 'disabled'),
            LangHelper.trans('alert.visitor_problem.title'),
            {
              confirmButtonClass: 'el-button--danger',
              confirmButtonText : 'I understand',
            },
          )

        Object.keys(model)
              .forEach((key: string) => model![key] = visitor.data[key])

        if (_.isEmpty(visitor.data.visitDate))
          expired.value = true
        else {
          const visitDate          = dayjs(visitor.data.visitDate)
          const comparisonTemplate = 'YYYY-MM-DD'

          model.visitDate = visitDate.toDate()
          expired.value   = visitDate
                              .format(comparisonTemplate)
                            !== dayjs()
                              .format(comparisonTemplate)
        }

        const visitingCompanies = await axios.get(`/visitor/contact/${visitor.data._id}`)

        if (visitingCompanies.status === 200)
          visitCompanies.value = visitingCompanies.data

        const visitorEntries = await axios.get(`/visitor/entry/find/${visitor.data.RFID}/${visitor.data._id}`)

        if (visitingCompanies.status === 200)
          entryRecords.value = visitorEntries.data

        show.value = true
      }, 250),
    )

    async function getRelatedData() {
      companies.value = await ResourceHelper.List('/tenant/profile')
    }

    getRelatedData()

    return {
      show,
      companies,
      scanModel,
      model,
      visitCompanies,
      entryRecords,
      expired,
    }
  },
})
