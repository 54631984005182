import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "data-table__action-bar--search" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_input = _resolveComponent("el-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_input, {
      modelValue: _ctx.value,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value) = $event)),
      onKeyup: _ctx.search
    }, _createSlots({
      prepend: _withCtx(() => [
        _createVNode(_component_Icon, { icon: "ic:baseline-search" }),
        _createElementVNode("span", null, _toDisplayString(_ctx.trans("label.search")), 1)
      ]),
      _: 2
    }, [
      (_ctx.type === 'date' || _ctx.type === 'visitDate')
        ? {
            name: "append",
            fn: _withCtx(() => [
              _createVNode(_component_el_date_picker, {
                modelValue: _ctx.date,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.date) = $event)),
                type: "datetimerange",
                size: "small",
                "unlink-panels": "",
                "range-separator": _ctx.trans('label.to'),
                format: "DD-MM-YYYY HH:mm",
                "start-placeholder": _ctx.trans('label.start-date'),
                "end-placeholder": _ctx.trans('label.end-date'),
                shortcuts: _ctx.shortcuts
              }, null, 8, ["modelValue", "range-separator", "start-placeholder", "end-placeholder", "shortcuts"])
            ])
          }
        : undefined
    ]), 1032, ["modelValue", "onKeyup"])
  ]))
}