import LangHelper from "@/helpers/lang"
import BlankWrapper from "@/views/wrapper/BlankWrapper.vue"

import Registration from "@/views/visitor/registration/Registration.vue"
import PreRegistration from "@/views/visitor/pre-registration/PreRegistration.vue"
import Visitor from "@/views/visitor/management/Visitor.vue"
// import PreRegistrationManagement from "@/views/visitor/pre-reg-management/PreRegistration.vue"
import PreRegistrationManagement from "@/views/visitor/pre-reg-group/PreRegistration.vue"
import RegValidation from "@/views/visitor/reg-validation/RegValidation.vue"
import Validation from "@/views/visitor/validation/Validation.vue"

import TenantProfile from "@/views/tenant/profile/TenantProfile.vue"
import TenantStaff from "@/views/tenant/staff/TenantStaff.vue"

import GateControl from "@/views/general/gate-control/GateControl.vue"

import Staff from "@/views/staff/Staff.vue"

import VisitorReport from "@/views/report/visitor/Visitor.vue"
import BuildingReport from "@/views/report/building/Building.vue"
import StillInBuildingReport from "@/views/report/still-in-building/StillInBuilding.vue"
import RfidInventoryReport from "@/views/report/rfid-inventory/RfidInventory.vue"
import UsageReport from "@/views/report/usage/Usage.vue"
import EventLogReport from "@/views/report/event-log/EventLog.vue"
import TenantReport from "@/views/report/tenant/Tenant.vue"
import StaffReport from "@/views/report/staff/Staff.vue"
import BlacklistReport from "@/views/report/blacklist/Blacklist.vue"
import { RouteLocationNormalized, NavigationGuardNext } from "vue-router"

import Blacklist from "@/views/blacklist/Blacklist.vue"
import AuthHelper from "@/helpers/auth"
const permissions = AuthHelper.permissions.value
const PERMISSIONS_FOR_TENANTS = [9, 13]

const isAuthorizedForStaff = (pagePermission: number) => {
	return (
		AuthHelper.isStaff.value &&
		AuthHelper.permissions.value.includes(pagePermission)
	)
}
const isAuthorizedForTenant = (pagePermission: number) => {
	return (
		AuthHelper.tenantUserID.value &&
		PERMISSIONS_FOR_TENANTS.includes(pagePermission)
	)
}

const permissionCheck = (
	to: RouteLocationNormalized,
	from: RouteLocationNormalized,
	next: NavigationGuardNext
) => {
	const pagePermission = to.meta.permission as number | undefined
	if (pagePermission !== undefined) {
		const hasStaffPermission = isAuthorizedForStaff(pagePermission)
		const hasTenantPermission = isAuthorizedForTenant(pagePermission)

		if (!hasStaffPermission && !hasTenantPermission) {
			next({ path: "/" })
		}
	}
	next()
}
const routes = [
	// Visitor
	{
		path: "visitor",
		component: BlankWrapper,
		children: [
			{
				path: "registration",
				name: LangHelper.trans("label.visitor-registration"),
				component: Registration,
				beforeEnter: permissionCheck,
				meta: {
					staff: true,
					icon: "ic:outline-assignment-ind",
					permission: 6
				}
			},
			{
				path: "pre-registration",
				name: LangHelper.trans("label.pre-registration"),
				component: PreRegistration,
				beforeEnter: permissionCheck,
				meta: {
					icon: "ic:outline-assignment-ind",
					permission: 9
				}
			},
			{
				path: "management",
				name: LangHelper.trans("label.rfid-management"),
				component: Visitor,
				beforeEnter: permissionCheck,
				meta: {
					staff: true,
					icon: "ic:outline-badge",
					permission: 3
				}
			},
			{
				path: "pre-reg-management",
				name: LangHelper.trans("label.qrcode-management"),
				component: PreRegistrationManagement,
				beforeEnter: permissionCheck,
				meta: {
					icon: "ic:outline-badge",
					permission: 13
				}
			},
			{
				path: "reg-validation",
				name: LangHelper.trans("label.qrcode-validation"),
				component: RegValidation,
				beforeEnter: permissionCheck,
				meta: {
					staff: true,
					icon: "ic:baseline-qr-code-scanner",
					permission: 5
				}
			},
			{
				path: "validation",
				name: LangHelper.trans("label.check-rfid-card"),
				component: Validation,
				beforeEnter: permissionCheck,
				meta: {
					staff: true,
					icon: "ic:outline-fact-check",
					permission: 7
				}
			}
			// {
			//   path     : 'type',
			//   name     : LangHelper.trans('label.visit-type-management'),
			//   component: Type,
			//   meta     : {
			//     tenant    : true,
			//     icon      : 'ic:outline-article',
			//     permission: 3,
			//   },
			// },
		]
	},
	// Building
	// {
	//   path     : 'building',
	//   meta     : {
	//     staff: true,
	//   },
	//   component: BlankWrapper,
	//   children : [
	//     {
	//       path     : 'zone',
	//       name     : LangHelper.trans('label.zone-management'),
	//       component: Zone,
	//       meta     : {
	//         icon      : 'ic:outline-layers',
	//         permission: 1,
	//       },
	//     },
	//     {
	//       path     : 'floor',
	//       name     : LangHelper.trans('label.floor-management'),
	//       component: Floor,
	//       meta     : {
	//         icon      : 'ic:outline-door-sliding',
	//         permission: 1,
	//       },
	//     },
	//   ],
	// },
	// Tenant
	{
		path: "tenant",
		component: BlankWrapper,
		children: [
			{
				path: "profile",
				name: LangHelper.trans("label.tenant-profile"),
				component: TenantProfile,
				beforeEnter: permissionCheck,
				meta: {
					staff: true,
					icon: "ic:baseline-corporate-fare",
					permission: 1
				}
			},
			{
				path: "staff",
				name: LangHelper.trans("label.tenant-staff"),
				component: TenantStaff,
				beforeEnter: permissionCheck,
				meta: {
					staff: true,
					icon: "ic:outline-supervised-user-circle",
					permission: 12
				}
			}
		]
	},
	// Gate Control
	{
		path: "gate-control",
		name: LangHelper.trans("label.gate-control"),
		component: GateControl,
		beforeEnter: permissionCheck,
		meta: {
			staff: true,
			icon: "ic:outline-door-sliding",
			permission: 11
		}
	},
	// Staffs
	{
		path: "staff",
		name: LangHelper.trans("label.staff-management"),
		component: Staff,
		beforeEnter: permissionCheck,
		meta: {
			staff: true,
			icon: "ic:outline-people",
			permission: 2
		}
	},
	// Report
	{
		path: "report",
		name: LangHelper.trans("label.report-management"),
		component: BlankWrapper,
		beforeEnter: permissionCheck,
		meta: {
			staff: true,
			icon: "ic:outline-receipt-long",
			permission: 4
		},
		children: [
			{
				path: "visitor",
				name: LangHelper.trans("label.visitor"),
				component: VisitorReport
			},
			// {
			//   path     : 'building',
			//   name     : LangHelper.trans('label.building'),
			//   component: BuildingReport,
			// },
			{
				path: "still-in-building",
				name: LangHelper.trans("label.still-in-building"),
				component: StillInBuildingReport
			},
			{
				path: "rfid-inventory",
				name: LangHelper.trans("label.rfid-inventory"),
				component: RfidInventoryReport
			},
			// {
			//   path     : 'usage',
			//   name     : 'Usage',
			//   component: UsageReport,
			// },
			// {
			//   path     : 'event-log',
			//   name     : LangHelper.trans('label.event-log'),
			//   component: EventLogReport,
			// },
			{
				path: "tenant",
				name: LangHelper.trans("label.tenant"),
				component: TenantReport
			},
			// {
			//   path     : 'staff',
			//   name     : LangHelper.trans('label.staff'),
			//   component: StaffReport,
			// },
			{
				path: "blacklist",
				name: LangHelper.trans("label.blacklist"),
				component: BlacklistReport
			}
		]
	},
	// Blacklist
	{
		path: "blacklist",
		name: LangHelper.trans("label.blacklist-management"),
		component: Blacklist,
		beforeEnter: permissionCheck,
		meta: {
			staff: true,
			icon: "ic:baseline-block",
			permission: 8
		}
	}
]

export default routes
