export const fields = {
  total    : 'Total Card',
  missed   : 'Missed Card',
  inventory: 'Card Inventory',
}

export function parseData(
  row: any,
): any {
  return {
    total    : row.total,
    missed   : row.missed,
    inventory: row.total - row.missed,
  }
}
