
import axios from "axios"
import { defineComponent, inject, ref } from "vue"

import { EditorHook } from "@/components/data-table/hooks"
import AuthHelper from "@/helpers/auth"
import LangHelper from "@/helpers/lang"
import ResourceHelper from "@/helpers/resource"

export default defineComponent({
	setup() {
		const henderson = AuthHelper.isHenderson.value
		const editorHook = inject<EditorHook>("editor-hook")!

		const roles = ref<any[]>([])
		const zones = ref<any[]>([])
		const retypePassword = ref<string>("")

		const rawUserName = editorHook.model.userName

		editorHook.setCallback(() => {
			editorHook.model.password = ""
			retypePassword.value = ""
		})

		async function checkUsername(rule: any, value: string, callback: any) {
			if (!_.isEmpty(rawUserName) && rawUserName === value) return callback()

			const result = await axios.get(
				`/staff/user/check-duplicate-username/${value.toLowerCase}`
			)

			if (!result || result.status !== 200)
				return callback(
					new Error(
						LangHelper.trans(
							"message.item_exist",
							LangHelper.trans("label.username")
						)
					)
				)

			callback()
		}

		function confirmPassword(rule: any, value: any, callback: any) {
			if (
				(editorHook.mode.value === "create" ||
					!_.isEmpty(editorHook.model.password)) &&
				retypePassword.value !== editorHook.model.password
			)
				return callback(
					new Error(LangHelper.trans("message.password_not-match_fail"))
				)

			callback()
		}

		async function getRelatedData() {
			const result = await Promise.all([
				ResourceHelper.List("/staff/role"),
				ResourceHelper.List("/building/zone")
			])

			roles.value = result[0]
			zones.value = result[1]
		}

		getRelatedData()

		return {
			henderson,
			...editorHook.expose,
			roles,
			zones,
			retypePassword,
			checkUsername,
			confirmPassword
		}
	}
})
