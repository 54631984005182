
import { computed, defineComponent, nextTick, reactive, ref, watch } from "vue"
import { ElForm, ElSelect } from "element-plus"
import { stringSimilarity } from "string-similarity-js"

import AuthHelper from "@/helpers/auth"
import ResourceHelper from "@/helpers/resource"
import { Icon } from "@iconify/vue"

export default defineComponent({
	components: { Icon },
	props: {
		editable: {
			type: Boolean,
			default: false
		},
		records: {
			type: Array,
			default: []
		},
		multiple: {
			type: Boolean,
			default: false
		},
		tenantId: {
			type: Number,
			default: false
		}
	},
	setup(props) {
		const visitForm = ref<InstanceType<typeof ElForm>>()
		const company = ref<InstanceType<typeof ElSelect>>()
		// const companySearch      = ref<InstanceType<typeof ElInput>>()
		const companies = ref<any[]>([])
		const floors = ref<any[]>([])
		const suites = ref<any[]>([])
		const formattedCompanies = ref<any[]>([])
		const filteredCompanies = ref<any[]>([])
		const visitCompanies = ref<any[]>(props.records)
		const visitModel = reactive<any>({
			combineId: ""
		})
		const visitDisplayList = computed<any[]>(() => {
			const companyList = companies.value
			const floorList = floors.value
			const suiteList = suites.value
			const henderson = AuthHelper.isHenderson.value

			return visitCompanies.value
				.map((visitCompany: any) =>
					getRecord(
						visitCompany.tenantId,
						visitCompany.floorId,
						companyList,
						floorList,
						suiteList,
						henderson
					)
				)
				.filter((record: any) => !_.isEmpty(record))
		})

		const keyword = ref("")
		let filterString = ""
		let focusTimer: any = null

		function getFormattedCompanies() {
			const companyList = companies.value
			const floorList = floors.value
			const suiteList = suites.value
			const henderson = AuthHelper.isHenderson.value
			const result: any[] = []

			suiteList.forEach((suite: any) =>
				suite.floorIds.forEach((floorId: number) => {
					const record = getRecord(
						suite.tenantId,
						floorId,
						companyList,
						floorList,
						suiteList,
						henderson
					)

					if (
						record &&
						!_.isEmpty(record) &&
						(!props.tenantId || suite.tenantId === props.tenantId)
					)
						result.push(record)
				})
			)

			formattedCompanies.value = result
			filteredCompanies.value = result
		}

		function getRecord(
			tenantId: number,
			floorId: number,
			companyList: any[] = companies.value,
			floorList: any[] = floors.value,
			suiteList: any[] = suites.value,
			henderson: boolean = false
		): any {
			const company =
				companyList.find((company) => company.tenantId === tenantId) ?? {}
			const floor = floorList.find((floor) => floor.floorId === floorId) ?? {}
			const suite =
				suiteList.find(
					(suite) =>
						suite.tenantId === tenantId && suite.floorIds.includes(floorId)
				) ?? {}

			try {
				if (!henderson || floor.zoneId === 5)
					return {
						company,
						floor,
						suite,
						value: `${tenantId}-${floorId}`,
						display: `${company.tenantName} > ${floor.floorName} > ${suite.name}`
					}

				return {}
			} catch (e) {
				return {}
			}
		}

		function getFloorNumber(floor: string): number {
			return _.toNumber(floor.replace("/F", ""))
		}

		function filterCompany(search: string) {
			if (keyword.value !== search) keyword.value = search
			if (search === filterString) return

			const result: any[] = []

			if (search != "") {
				const searchList = search
					.trim()
					.split(",")
					.map((search) => search.trim())
					.map((search) => search.toLowerCase())

				formattedCompanies.value
					.map((item) => ({
						data: {
							company: item.company.tenantName.toLowerCase(),
							floor: item.floor.floorName.toLowerCase(),
							suite: item.suite.name.toLowerCase()
						},
						item
					}))
					.forEach(({ data, item }) => {
						const matched = searchList.every(
							(search) =>
								data.company.includes(search) ||
								data.floor.includes(search) ||
								filterSuite(search, data.suite)
						)

						if (matched) result.push(item)
					})

				result.sort((item1, item2) => {
					if (item1.floor.floorName !== item2.floor.floorName) {
						const floor1 = getFloorNumber(item1.floor.floorName)
						const floor2 = getFloorNumber(item2.floor.floorName)

						return floor1 - floor2
					}

					let result = 0

					searchList.forEach((search) => {
						result -= stringSimilarity(item1.company.tenantName, search)
						result -= stringSimilarity(item1.floor.floorName, search)
						result -= stringSimilarity(item1.suite.name, search)

						result += stringSimilarity(item2.company.tenantName, search)
						result += stringSimilarity(item2.floor.floorName, search)
						result += stringSimilarity(item2.suite.name, search)
					})

					return result
				})

				if (focusTimer) clearTimeout(focusTimer)
				focusTimer = setTimeout(() => focusCompany(), 500)
			} else {
				result.push(...formattedCompanies.value)
				result.sort((item1, item2) => {
					if (item1.floor.floorName !== item2.floor.floorName)
						return item1.floor.floorName > item2.floor.floorName ? 1 : -1

					if (item1.suite.name !== item2.suite.name)
						return item1.suite.name > item2.suite.name ? 1 : -1

					if (item1.company.tenantName !== item2.company.tenantName)
						return item1.company.tenantName > item2.company.tenantName ? 1 : -1

					return 0
				})
			}

			filteredCompanies.value = []

			nextTick(() => {
				filterString = search
				filteredCompanies.value = result
			})
		}

		function filterSuite(search: string, suite: string): boolean {
			let regex = new RegExp(`(?:^|[^0-9]+|\s)${search}.*$`)
			if (suite.includes(search) && regex.test(suite)) return true

			return suite
				.trim()
				.split(/[,&]/)
				.map((suite) => suite.trim())
				.flat()
				.filter((suite) => suite.includes("-"))
				.map((suite) => suite.split("-"))
				.filter((suites) => suites.length >= 2)
				.map((suites) => {
					const suiteList = suites.map((suite, index) => {
						// if (
						//     suite.length < 4 &&
						//     index > 0
						// )
						//   suite = `${suites[0].substring(0, 4 - suite.length)}${suite}`

						return parseInt(suite)
					})
					const max = Math.max(...suiteList)
					const min = Math.min(...suiteList)

					return [min, max]
				})
				.filter(([min, max]) => max - min < 100)
				.some(([min, max]) => {
					let n = parseInt(search)
					if (`${min}`.indexOf(search) == 0) return true
					if (`${max}`.indexOf(search) == 0) return true
					if (n >= min && n <= max) return true
					// for (let i = min; i <= max; i++)
					//   if (`${i}`.includes(search))
					//     return true

					return false
				})
		}

		function focusCompany(focus: boolean = true) {
			if (focusTimer) clearTimeout(focusTimer)
			if (focus) {
				company.value?.focus()
			} else {
				company.value?.blur()
			}
		}

		// function focusCompanySearch(
		//   focus: boolean = true
		// ) {
		//   if (focus)
		//     companySearch.value?.focus()
		//   else
		//     companySearch.value?.blur()
		// }

		async function addVisitCompany() {
			if (_.isNull(visitModel.combineId)) return

			keyword.value = ""
			// checkSpecialRequest()

			try {
				await visitForm.value!.validate()

				const [tenantId, floorId] = visitModel.combineId.split("-")
				const exists = visitCompanies.value.find((item) => {
					return item.tenantId == tenantId && item.floorId == floorId
				})

				if (!exists) {
					visitCompanies.value = [
						...visitCompanies.value,
						{
							tenantId: parseInt(tenantId),
							floorId: parseInt(floorId),
							contactPerson: "",
							contactNo: ""
						}
					]
				}
				filteredCompanies.value = formattedCompanies.value

				visitForm.value!.resetFields()
				visitForm.value!.clearValidate()
				// focusCompany()
				// focusCompanySearch()
			} catch (e) {}
		}

		function removeVisitCompany(targetIndex: number) {
			const newList: any[] = []

			console.log("removeVisitCompany", targetIndex)

			visitCompanies.value.forEach((company: any, index: number) => {
				if (index !== targetIndex) newList.push(company)
			})

			visitCompanies.value = newList
		}

		function clearVisitCompany() {
			visitCompanies.value = []

			visitForm.value!.resetFields()
			visitForm.value!.clearValidate()
		}

		function getVisitCompanies(): any[] {
			return visitCompanies.value
		}

		const showSr = ref<boolean>(false)
		const specialRequest = ref<any>({})
		// let handlingSr = false
		let handlingBlur = false
		// let addCompany = false

		function checkSpecialRequest(): boolean {
			const [tenantId] = visitModel.combineId
				.split("-")
				.map((id: string) => parseInt(id))
			const visitCompany = companies.value.find(
				(company) => company.tenantId === tenantId
			)

			if (
				!visitCompany ||
				!visitCompany.remarks ||
				_.isEmpty(visitCompany.remarks)
			)
				return false

			specialRequest.value = visitCompany
			showSr.value = true
			// handlingSr           = true

			return true
		}

		async function closeSpecialRequest() {
			if (!showSr.value) return

			showSr.value = false

			// if (addCompany) {
			await addVisitCompany()

			// 	addCompany = false
			// }

			// setTimeout(() => handlingSr = false, 100)
		}

		// function onFocus() {
		//   if (
		//     handlingSr ||
		//     handlingBlur
		//   )
		//     return

		//   // filterCompany('')
		// }

		function setFilteredCompany(all: boolean) {
			const filteredCompanyList = filteredCompanies.value

			if (
				(all && filteredCompanyList.length > 0) ||
				filteredCompanyList.length === 1
			) {
				const onlyCompany = filteredCompanyList[0]

				// addCompany = true
				visitModel.combineId = `${onlyCompany.company.tenantId}-${onlyCompany.floor.floorId}`
			}
		}

		async function onBlur() {
			if (handlingBlur) return

			handlingBlur = true
			const filteredCompanyList = filteredCompanies.value

			if (filteredCompanyList.length === 1) {
				const onlyCompany = filteredCompanyList[0]

				// addCompany = true
				visitModel.combineId = `${onlyCompany.company.tenantId}-${onlyCompany.floor.floorId}`
			}

			setTimeout(() => (handlingBlur = false), 200)
		}

		async function getRelatedData() {
			const result = await Promise.all([
				ResourceHelper.List("/tenant/profile"),
				ResourceHelper.List("/building/floor"),
				ResourceHelper.List("/tenant/suite")
			])

			companies.value = result[0]
			floors.value = result[1]
			suites.value = result[2].map((suite) => {
				suite.floorIds = JSON.parse(suite.floorId || "[]")
				return suite
			})

			getFormattedCompanies()
			onBlur()
			// focusCompany()
		}

		getRelatedData()

		watch(
			() => props.records,
			(records) => (visitCompanies.value = records)
		)
		watch(
			() => visitModel.combineId,
			() => {
				setTimeout(async () => {
					if (visitModel.combineId === "") return

					const needCheck = !props.tenantId && checkSpecialRequest()

					if (!needCheck) {
						await addVisitCompany()
					}
				}, 100)
			}
		)

		return {
			visitForm,
			company,
			// companySearch,
			companies,
			suites,
			floors,
			showSr,
			specialRequest,
			visitModel,
			filteredCompanies,
			visitDisplayList,
			filterCompany,
			focusCompany,
			addVisitCompany,
			removeVisitCompany,
			clearVisitCompany,
			getVisitCompanies,
			closeSpecialRequest,
			// onFocus,
			onBlur,
			keyword
		}
	}
})
