import {
  computed,
  nextTick,
  Ref,
  ref,
}                       from 'vue'
import dayjs, { Dayjs } from 'dayjs'
import { ComputedRef }  from '@vue/reactivity'

import ResourceHelper       from '@/helpers/resource'
import { VxeTableInstance } from 'vxe-table'

import {
  IDataTableColumn,
  IDTDataConfig,
} from '../types'

class DataHook {
  rowData: ComputedRef<any[]>

  loading = ref<boolean>(false)

  get endPoint() {
    return this._config.source
  }

  private readonly _config: IDTDataConfig

  private _rawData    = ref<any[]>([])
  private _search     = ref<string>('')
  private _searchDate = ref<Dayjs[]>([])
  private _callback   = ref<any>()

  constructor(
    config: IDTDataConfig,
    columns: IDataTableColumn[],
  ) {
    this._config = config

    const columnFilters = columns.map(column => {
      let filter = column.formatter ?
                   column.formatter :
                   ({ cellValue }: any) => {
                     if (_.isArray(cellValue))
                       return cellValue.join(' / ')

                     return `${cellValue}`
                   }

      return {
        prop: column.prop,
        filter,
      }
    })

    this.rowData = computed<any[]>(() => {
      let data = this._rawData.value

      if (this._config.parser)
        data = data.map(this._config.parser)

      if (
        this._config.searchByDate &&
        !_.isEmpty(this._searchDate.value)
      ) {
        data = data.filter((row: any) => this._config.searchByDate?.(row, this._searchDate.value))
      }

      if (!_.isEmpty(this._search.value)) {
        if (
          this._config.searchByColumn
        ) {
          data = data.filter((row: any) => this._config.searchByColumn?.(row, this._search.value))
        } else {
          const search = this._search.value.toLowerCase()

          data = data.filter((row: any) =>
            _.some(
              columnFilters,
              (column: any) => {
                const cellValue = row[column.prop]
                const result    = column.filter({
                  cellValue,
                  row,
                })

                return result.toLowerCase()
                            .includes(search)
              },
            ),
          )
        }
      }
      
      // if (
      //   this._config.searchByColumn &&
      //   !_.isEmpty(this._search.value)
      // ) {
      //   data = data.filter((row: any) => this._config.searchByColumn?.(row, this._search.value))
      // }

      if (this._config.rowData)
        this._config.rowData = data

      return data
    })
  }

  async loadData(callback?: Function | null) {
    this.loading.value  = true
    this._rawData.value = []
    await nextTick()
    this.loading.value  = true
    this._rawData.value = await ResourceHelper.FullList(this._config.source, this._config.query)

    await nextTick()

    this.loading.value = false
    if(typeof callback === 'function'){
      this._callback.value = callback;
      this._callback.value();
    }else if(typeof this._callback.value === 'function'){
      this._callback.value();
    }
  }

  async refresh() {
    await this.loadData()
  }

  search(value: string) {
    this._search.value = value.trim()

    if (this._config.search) {
      this._config.search(value);
      this.refresh();
    }
  }

  searchByDate(dates: Date[]) {
    this._searchDate.value = dates.map(date => {
      const time = dayjs(date)
        .format('YYYY-MM-DD HH:mm:ss')

      return dayjs(time)
    })
  }

  searchByVisitDate(dates: Date[]) {
    let visitStartDate, visitEndDate

    if (!dates || dates.length == 0) {
      // visitEndDate = dayjs()
      //   .format('YYYY-MM-DD HH:mm:ss');
      // visitStartDate = dayjs()
      //   .subtract(30, 'day')
      //   .format('YYYY-MM-DD HH:mm:ss');
    } else {
      visitStartDate = dayjs(_.nth(dates, 0))
        .format('YYYY-MM-DD HH:mm:ss');
      visitEndDate = dayjs(_.nth(dates, 1))
        .format('YYYY-MM-DD HH:mm:ss');
    }

    if (this._config.searchByVisitDate) {
      this._config.searchByVisitDate(visitStartDate, visitEndDate);
      this.refresh();
    }
  }
}

export default DataHook
