import { defaultImage } from '@/components/PhotoTaker.vue'
import LangHelper       from '@/helpers/lang'


export const defaultModel = {
  lastName      : '',
  firstName     : '',
  salutation    : '',
  visitorType   : 1,
  visitorCompany: '',
  otherName     : '',
  contactNo     : '',
  visitDate     : '',
  entryType     : 'Single-entry',
  visitorImage  : defaultImage,
  RFID          : '',
  isDeleted     : false,
}
