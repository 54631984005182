import dayjs from 'dayjs'

export const fields = {
  type      : 'Type',
  company   : 'Company',
  floor     : 'Floor',
  suite     : 'Suite',
  salutation: 'Salutation',
  lastName  : 'LastName',
  firstName : 'FirstName',
  Phone     : 'Phone',
  register  : 'Register Time',
  ioTime    : 'In Time',
}

function formatDate(date: string): string {
  return dayjs(date)
    // .utc()
    .format('YYYY-MM-DD HH:mm')
}

export function parseData(
  row: any,
  data: any,
): any {
  return {
    type      : data.types
                    .find((type: any) => row.visitorType === type.recordTypeId).recordTypeName,
    company   : row.company,
    floor     : row.floorIds
                   .map((floorId: number) => data.floors.find((floor: any) => floor.floorId === floorId).floorName)
                   .join(', '),
    suite     : row.suite,
    salutation: row.salutation,
    lastName  : row.lastName,
    firstName : row.firstName,
    Phone     : row.phone,
    register  : formatDate(row.createdAt),
    ioTime    : formatDate(row.ioTime),
  }
}
