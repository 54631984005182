import { ElForm, ElMessage, ElMessageBox } from "element-plus"
import { Component, computed, reactive, Ref, ref, toRaw } from "vue"
import { UnwrapNestedRefs } from "@vue/reactivity"

import ExposeHelper from "@/helpers/expose"
import LangHelper from "@/helpers/lang"
import ResourceHelper from "@/helpers/resource"
import { VxeTableInstance } from "vxe-table"

import { IDTEditorConfig, TActionMode } from "../types"

class EditorHook {
	mode = ref<TActionMode>("hide")
	readonly = ref<boolean>(true)
	visible = computed<boolean>({
		get: () => this.mode.value !== "hide",
		set: (_) => null
	})
	editRedirect = computed<string | boolean>(() =>
		_.isString(this._config.editRedirect) ? this._config.editRedirect! : false
	)
	labelPosition = computed<string>(() => this._config.labelPosition ?? "left")
	disable = computed<boolean>(() => ["hide", "view"].includes(this.mode.value))
	title = computed(() => {
		let prefix = ""

		switch (this.mode.value) {
			case "create":
				prefix = LangHelper.trans("label.create-new")
				break

			case "view":
				prefix = LangHelper.trans("label.view")
				break

			case "edit":
				prefix = LangHelper.trans("label.edit")
				break
		}

		if (LangHelper.lang === "en")
			return `${prefix} ${this._config.title} ${LangHelper.trans(
				"label.record"
			)}`
		else return `${prefix}${this._config.title}`
	})
	form = ref<InstanceType<typeof ElForm>>()

	recordId = ref<number>(-1)
	allowDelete = false
	allowEdit = ref<boolean>(true)
	ajaxBlock = false

	endPoint: string
	model: UnwrapNestedRefs<any> | undefined

	private readonly _config: IDTEditorConfig

	private _callback: Function[] = []
	private _table: Ref<VxeTableInstance | undefined> = ref()

	get component(): Component {
		return this._config.component
	}

	get expose(): EditorHook {
		return ExposeHelper.GetProperties(this)
	}

	constructor(config: IDTEditorConfig, endPoint: string) {
		this._config = config
		this.endPoint = endPoint

		this.allowDelete = config.delete

		if (config.defaultModel)
			this.model = reactive(_.cloneDeep(config.defaultModel))
	}

	setTable(table: Ref<VxeTableInstance | undefined>) {
		this._table = table
	}

	setCallback(callback: Function) {
		this._callback.push(callback)
	}

	private callback() {
		this._callback.forEach((callback) => callback())
	}

	async show(mode: TActionMode, id?: number) {
		if (["view", "edit"].includes(mode)) {
			await this.loadData(id!)

			this.mode.value = "view"
			this.readonly.value = mode === "view"

			if (this._config.authEdit)
				this.allowEdit.value = this._config.authEdit(toRaw(this.model))
		} else {
			this.mode.value = mode

			this.clearData()
		}
	}

	async loadData(id: number) {
		if (!this._config.defaultModel) return

		const record = await ResourceHelper.Get(this.endPoint, id)

		this.recordId.value = id

		Object.keys(this._config.defaultModel).forEach((key) => {
			if (key === "password") return (this.model![key] = "")

			this.model![key] = record[key]
		})
	}

	clearData() {
		this.recordId.value = -1

		if (this.form.value) this.form.value!.resetFields()

		if (!this._config.defaultModel) return

		Object.keys(this._config.defaultModel).forEach(
			(key) => (this.model![key] = this._config.defaultModel![key])
		)
	}

	async create() {
		if (this.ajaxBlock) return
		if (!this.ajaxBlock) this.ajaxBlock = true

		try {
			await this.form.value!.validate()

			const result = await ResourceHelper.Create(
				this.endPoint,
				toRaw(this.model)
			)
			this.ajaxBlock = false

			if (!result.success)
				return ElMessage.error(
					LangHelper.trans("message.item_create_fail", this._config.title)
				)

			ElMessage.success(
				LangHelper.trans("message.item_create_success", this._config.title)
			)
			this.callback()

			this.mode.value = "view"

			await this.loadData(result.id)
		} catch (e) {
			this.ajaxBlock = false
		}
	}

	async update() {
		if (this.ajaxBlock) return
		if (!this.ajaxBlock) this.ajaxBlock = true

		try {
			await this.form.value!.validate()

			let data = toRaw(this.model)

			data = Object.keys(data).reduce((keep, key) => {
				const value = data[key]

				if (!(key === "password" && _.isEmpty(value))) keep[key] = value

				return keep
			}, {} as any)

			const result = await ResourceHelper.Update(
				this.endPoint,
				this.recordId.value,
				data
			)
			this.ajaxBlock = false

			if (!result.success)
				return ElMessage.error(
					LangHelper.trans("message.item_update_fail", this._config.title)
				)

			ElMessage.success(
				LangHelper.trans("message.item_update_success", this._config.title)
			)
			this.callback()

			this.mode.value = "view"

			await this.loadData(this.recordId.value)
		} catch (e) {
			this.ajaxBlock = false
		}
	}

	async remove() {
		if (this.ajaxBlock) return
		if (!this.ajaxBlock) this.ajaxBlock = true

		try {
			await ElMessageBox.confirm(
				LangHelper.trans("confirm.item_delete.message", this._config.title),
				LangHelper.trans("confirm.item_delete.title", this._config.title),
				{
					confirmButtonText: "Confirm",
					confirmButtonClass: "el-button--danger",
					cancelButtonText: LangHelper.trans("label.cancel"),
					type: "warning"
				}
			)
		} catch (error) {
			this.ajaxBlock = false
			return
		}

		const response = await ResourceHelper.Delete(this.endPoint, [
			this.recordId.value
		])
		this.ajaxBlock = false

		if (response.affected === 0)
			return ElMessage.error(
				LangHelper.trans("message.item_delete_fail", this._config.title)
			)

		this.mode.value = "hide"

		ElMessage.success(
			LangHelper.trans("message.item_delete_success", this._config.title)
		)
		this.callback()
		this.clearData()
	}

	async removeSelected() {
		const visitors = this._table.value!.getCheckboxRecords()

		if (!_.size(visitors)) {
			return ElMessage.error(
				LangHelper.trans("message.other_select-first", this._config.title)
			)
		}

		try {
			await ElMessageBox.confirm(
				LangHelper.trans("confirm.record_delete.message"),
				LangHelper.trans("confirm.record_delete.title"),
				{
					confirmButtonText: "Confirm",
					confirmButtonClass: "el-button--danger",
					cancelButtonText: LangHelper.trans("label.cancel"),
					type: "warning"
				}
			)
		} catch (error) {
			return
		}

		const visitorIds = visitors.map((visitor) => visitor.visitorId)

		const response = await ResourceHelper.Delete(this.endPoint, visitorIds)

		if (response.affected === 0)
			return ElMessage.error(
				LangHelper.trans("message.record_delete_fail", this._config.title)
			)

		this.mode.value = "hide"

		ElMessage.success(
			LangHelper.trans("message.record_delete_success", this._config.title)
		)
		this.callback()
	}
}

export default EditorHook
