import LangHelper from '@/helpers/lang'
import dayjs      from 'dayjs'

import {
  IDataTableConfig,
  IDTActionBarConfig,
  IDTDataConfig,
  IDTEditorConfig,
  IDTTableConfig,
}                                   from '@/components/data-table/types'
import AuthHelper                   from '@/helpers/auth'
import RecordHelper, { RecordType } from '@/helpers/record'

import TenantProfileEditor from './TenantProfileEditor.vue'

function isAllowEdit() {
  return AuthHelper.permissions.value.includes(10)
}

const data = <IDTDataConfig>{
  source: '/tenant/profile',
  parser: (row: any) => {
    row.createUser = RecordHelper.Get(RecordType.User, row.createBy)

    return row
  },
}

const actionBar = <IDTActionBarConfig>{
  search: true,
  get create() {
    return isAllowEdit()
  },
}

const table = <IDTTableConfig>{
  columns: [
    {
      title   : LangHelper.trans('label.company-name'),
      prop    : 'tenantName',
      sortable: true,
    },
    {
      title    : LangHelper.trans('label.contact-person'),
      prop     : 'contactPerson1',
      formatter: ({ row }: any) => {
        const result: string[] = []

        for (let i = 1; i <= 3; i++)
          if (!_.isEmpty(row[`contactPerson${i}`])) {
            let contactPerson = row[`contactPerson${i}`]

            if (!_.isEmpty(row[`contactNo${i}`]))
              contactPerson = `${contactPerson} <${row[`contactNo${i}`]}>`

            result.push(contactPerson)
          }

        return result.join(' / ')
      },
    },
    {
      title   : LangHelper.trans('label.created-by'),
      prop    : 'createUser',
      sortable: true,
    },
    {
      title    : LangHelper.trans('label.created-date'),
      prop     : 'createDate',
      sortable : true,
      formatter: ({ cellValue }: any) => dayjs(cellValue)
        // .utc()
        .format('YYYY-MM-DD HH:mm'),
    },
    {
      title    : LangHelper.trans('label.updated-date'),
      prop     : 'lastUpdateDate',
      sortable : true,
      formatter: ({ cellValue }: any) => {
        if (cellValue === null)
          return '-'

        return dayjs(cellValue)
          // .utc()
          .format('YYYY-MM-DD HH:mm')
      },
    },
  ],
  action : {
    get mode() {
      return isAllowEdit() ? 'edit' : 'view'
    },
    get delete() {
      return isAllowEdit()
    },
  },
}

const editor = <IDTEditorConfig>{
  title    : LangHelper.trans('label.tenant-profile'),
  component: TenantProfileEditor,
  get delete() {
    return isAllowEdit()
  },
  defaultModel: {
    tenantName      : '',
    tenantSName     : '',
    contactPerson1  : '',
    contactNo1      : '',
    contactPerson2  : '',
    contactNo2      : '',
    contactPerson3  : '',
    contactNo3      : '',
    chargedServices : '',
    accessManagement: '',
    caseHistory     : '',
    remarks         : '',

    suite_SuiteID: -1,
    suite_FloorID: '[]',
    suite_Name   : '',
    suite_Phone  : '',
    suite_Remarks: '',
    suite_fmDate : `1990-01-01 00:00:00`,
    suite_toDate : `2999-12-31 23:59:59`,

    contactPersons: [],
  },
}

export default <IDataTableConfig>{
  data,
  actionBar,
  table,
  editor,
}
