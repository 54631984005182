import en from '@/translation/en.json'
import tw from '@/translation/tw.json'


const translations = {
  en,
  tw,
}

class LangHelper {
  static LANG_KEY         = 'lang'
  static lang             = 'en'
  static translation: any = null

  static LoadLang() {
    let lang = localStorage.getItem(LangHelper.LANG_KEY)
    const match = window.location.search.match(/lang=([a-z]{2})/)

    if (match && match[1] != lang) {
      if (match[1] == 'tc')
        lang = 'tw'
      else 
        lang = 'en'      
    }

    if (!lang)
      return LangHelper.SetLanguage(LangHelper.lang)
    else
      LangHelper.lang = lang

    // @ts-ignore
    LangHelper.translation = translations[lang]
  }

  static SetLanguage(
    lang: string,
  ) {
    localStorage.setItem(LangHelper.LANG_KEY, lang)
    location.reload()
  }

  static trans(
    key: string,
    value: string = '',
  ): string {
    let result: any = LangHelper.translation
    try {
      key.split('.')
        .forEach(part => result = result[part])
      result = result.replace('{value}', value)
      return result
    } catch (error) {
      return key
    }
  }
}

LangHelper.LoadLang()

export default LangHelper
