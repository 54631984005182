
import { defineComponent, nextTick, onBeforeUnmount, onMounted } from "vue"

import AuthHelper from "@/helpers/auth"
import RecordHelper, { RecordType } from "@/helpers/record"

import NavBreadcrumb from "./NavBreadcrumb.vue"
import NavMenu from "./NavMenu.vue"
import NavLanguage from "./NavLanguage.vue"
import NavDropdown from "./NavDropdown.vue"
import { Icon } from "@iconify/vue"

export default defineComponent({
	components: {
		Icon,
		NavBreadcrumb,
		NavMenu,
		NavLanguage,
		NavDropdown
	},
	setup() {
		const isStaff = AuthHelper.isStaff
		const mobileVersion = AuthHelper.mobileVersion
		let title = " "

		if (AuthHelper.is.staff)
			switch (AuthHelper.roleId.value) {
				case 3:
					title = "Concierge Mode"
					break

				case 4:
					title = "Security Mode"
					break
			}
		else
			title = RecordHelper.Get(
				RecordType.User,
				10000000 + AuthHelper.userId.value
			)

		return {
			isStaff,
			mobileVersion,
			title
		}
	}
})
