import {
  computed,
  ref,
}                    from 'vue'
import axios, {
  AxiosError,
  AxiosResponse,
}                    from 'axios'
import { ElMessage } from 'element-plus'

import LangHelper                   from '@/helpers/lang'
import RecordHelper, { RecordType } from '@/helpers/record'
import { ICredentials }             from '@/helpers/types'
import router                       from '@/router'


const TOKEN_KEY = 'token'

axios.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: AxiosError) => {
    if (error.response!.status === 401)
      await AuthHelper.Logout(false)
  },
)

class AuthHelper {
  static username      = ref<string>('')
  static userId        = ref<number>(-1)
  static roleId        = ref<number>(-1)
  static tenantUserID  = ref<number>(-1)
  static isStaff       = ref<boolean>(false)
  static isMobile      = ref<boolean>(false)
  static permissions   = ref<number[]>([])
  static forceDesktop  = ref<boolean>(false)
  static isHenderson   = computed<any>(() => {
    if (this.roleId.value === -1)
      return false

    return RecordHelper.Get(RecordType.Role, this.roleId.value)
                       .startsWith('Henderson')
  })
  static mobileVersion = computed<boolean>(() => {
    if (this.forceDesktop.value)
      return false

    return this.isMobile.value
  })

  static is = {
    get staff(): boolean {
      return AuthHelper.isStaff.value
    },
    get mobile(): boolean {
      return AuthHelper.isMobile.value
    },
    get mobileVersion(): boolean {
      return AuthHelper.mobileVersion.value
    },
    get henderson(): boolean {
      return AuthHelper.isHenderson.value
    },
  }

  static logoutRedirect = ''

  private static SetValue(
    value: any = {},
  ) {
    if (_.isEmpty(value))
      value = {
        username          : '',
        userId            : -1,
        roleId            : -1,
        tenantUserID      : -1,
        isStaff           : false,
        isMobile          : false,
        permissions       : [],
      }

    _.forEach(
      value,
      (value: any, key: string) => {
        const item = (<any>this)[key]

        if (item)
          item.value = value
      },
    )
  }

  static SetToken(
    token: string,
  ) {
    localStorage.setItem(TOKEN_KEY, token)
  }

  static async Login(
    credentials: ICredentials,
  ): Promise<boolean> {
    const { data: response } = await axios.post('/auth/login', credentials)

    if (!response.success)
      return false
    this.SetValue(response)
    this.SetRequestHeader(response.token)
    this.SetToken(response.token)

    return true
  }

  static async Verify(): Promise<boolean> {
    if (this.CheckRequestHeader())
      return true

    const token = localStorage.getItem(TOKEN_KEY)

    if (_.isEmpty(token))
      return false

    const { data: response } = await axios.post('/auth/verify', { token })

    if (!response.success)
      return false

    this.SetValue(response)
    this.SetRequestHeader(token!)

    return true
  }

  static async Logout(notify: boolean = true) {
    localStorage.removeItem(TOKEN_KEY)

    this.SetRequestHeader()
    this.SetValue()

    if (notify)
      ElMessage.success(LangHelper.trans('message.logout_success'))
    let logoutRedirect = localStorage.getItem('logoutRedirect') as string
    if (logoutRedirect) {
      localStorage.removeItem('logoutRedirect')
      location.href = logoutRedirect
    }
    else
      await router.replace('/login')
  }

  static CheckRequestHeader(): boolean {
    return !_.isEmpty(axios.defaults.headers.Authorization)
  }

  static SetRequestHeader(
    token?: string,
  ) {
    axios.defaults.headers.Authorization = _.isEmpty(token) ? '' : `Bearer ${token}`
  }
}

export default AuthHelper
