
import { computed, defineComponent, ref } from "vue"
import dayjs, { Dayjs } from "dayjs"

import AuthHelper from "@/helpers/auth"
import ResourceHelper from "@/helpers/resource"

export default defineComponent({
	props: {
		model: {
			type: Object,
			required: true
		}
	},
	setup({ model }) {
		const isStaff = AuthHelper.isStaff
		const companies = ref<any[]>([])
		const suites = ref<any[]>([])
		const floors = ref<any[]>([])
		const types = ref<any[]>([])
		const visitDate = computed({
			get: () => {
				const date = dayjs(model.visitDate)

				if (date.toDate().toString() === "Invalid Date") return null

				return dayjs(date.format("YYYY-MM-DD")).toDate()
			},
			set: (value) => {
				const date = dayjs(value).format("YYYY-MM-DD")
				let time = dayjs(model.visitDate).format("HH:mm")

				if (time === "Invalid Date") time = "00:00"

				model.visitDate = `${date} ${time}:00`
				model.visitSchFm = `${date} ${time}:00`
				model.visitSchTo = `${date} 23:59:00`
			}
		})
		const visitTime = computed({
			get: () => {
				const date = dayjs(model.visitDate).toDate()

				if (date.toString() === "Invalid Date") return ""

				return date
			},
			set: (value) => {
				const time = dayjs(value).format("HH:mm")
				let date = dayjs(model.visitDate).format("YYYY-MM-DD")

				if (date === "Invalid Date") date = dayjs().format("YYYY-MM-DD")

				model.visitDate = `${date} ${time}:00`
				model.visitSchFm = `${date} ${time}:00`
			}
		})
		const companyFloors = computed<any[]>(() => {
			const selectedCompanyFloors = suites.value
				.filter((suite) => suite.tenantId === model.tenantId)
				.map((suite) => JSON.parse(suite.floorId || "[]"))
				.flat()
				.filter((value, index, self) => self.indexOf(value) === index)
			const filterFloors = floors.value.filter((floor) =>
				selectedCompanyFloors.includes(floor.floorId)
			)

			model.floorIds = []

			if (filterFloors.length === 1) model.floorIds = [filterFloors[0].floorId]

			return filterFloors
		})

		function setVisitDateTime(dateTime: Dayjs) {
			const date = dateTime.toDate()

			visitDate.value = date
			visitTime.value = date
		}

		function limitVisitDate(date: Date) {
			const target = dayjs(date)
			const start = dayjs()
				.subtract(1, "day")
				.set("hour", 0)
				.set("minute", 0)
				.set("second", 0)
			const end = dayjs().add(2, "year")
			const currentSelectedDate = dayjs(model.visitDate).format("YYYY-MM-DD")
			const today = dayjs().format("YYYY-MM-DD")
			if (currentSelectedDate < today) {
				const start2 = dayjs()
					.subtract(1, "month")
					.set("hour", 0)
					.set("minute", 0)
					.set("second", 0)
				return target.isBefore(start2) || target.isAfter(end)
			}
			return target.isBefore(start) || target.isAfter(end)
		}

		function limitVisitTime(unit: String) {
			const result: number[] = []
			if (!model.visitDate) return result

			const selected = dayjs(model.visitDate)
			const today = dayjs()
			const current = today.add(15, "minute")

			if (selected.format("YYYY-MM-DD") <= today.format("YYYY-MM-DD")) {
				let hour, minute

				if (model.tenantId && selected.diff(current) <= 0) {
					hour = selected.get("hour")
					minute = selected.get("minute")
				} else {
					hour = current.get("hour")
					minute = current.get("minute")
				}

				if (!unit || unit == "h") {
					for (let i = 0; i < hour; i++) result.push(i)
				} else if (unit == "m") {
					if (hour == selected.get("hour")) {
						for (let i = 0; i < minute; i++) result.push(i)
					}
				}
			}

			return result
		}

		async function getRelatedData() {
			const result = await Promise.all([
				ResourceHelper.List("/tenant/profile"),
				ResourceHelper.List("/tenant/suite"),
				ResourceHelper.List("/building/floor"),
				ResourceHelper.List("/visitor/type")
			])

			companies.value = result[0]
			suites.value = result[1]
			floors.value = result[2]
			types.value = result[3]

			companies.value = _.map(companies.value, (company: any) => {
				const suiteFound = _.find(suites.value, { tenantId: company.tenantId })

				let floorName = ""
				if (_.size(_.get(suiteFound, "floorId"))) {
					const floorIds = JSON.parse(suiteFound.floorId)
					const floorsFound = _.filter(floors.value, (floor: any) => {
						return _.indexOf(floorIds, floor.floorId) !== -1
					})
					floorName = _.join(_.map(floorsFound, "floorName"), ",")
				}

				_.assign(company, {
					suiteName: _.get(suiteFound, "name", ""),
					floorName
				})

				return company
			})
		}

		getRelatedData()

		return {
			isStaff,
			companies,
			types,
			visitDate,
			visitTime,
			companyFloors,
			setVisitDateTime,
			limitVisitDate,
			limitVisitTime
		}
	}
})
