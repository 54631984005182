import dayjs from 'dayjs'

export const fields = {
  tenantName          : 'Name',
  // tenantSName         : 'Short Name',
  floor               : 'Floor',
  suite               : 'Suite',
  suitePhone          : 'Reception Phone',
  contactPerson1      : 'Contact Person 1',
  // contactNo1          : 'Contact No. 1',
  contactPerson2      : 'Contact Person 2',
  // contactNo2          : 'Contact No. 2',
  contactPerson3      : 'Contact Person 3',
  // contactNo3          : 'Contact No. 3',
  ChargedServices     : 'Charged Services',
  AccessManagement    : 'AccessManagement',
  CaseHistory         : 'CaseHistory',
  remarks             : 'Remarks',
}

export function parseData(
  row: any,
  data: any,
): any {
  return {
    tenantName          : row.tenantName,
    // tenantSName         : row.tenantSName,
    floor               : row.floorIds
                             .map((floorId: number) => data.floors.find((floor: any) => floor.floorId === floorId).floorName)
                             .join(', '),
    suite               : row.suite,
    suitePhone          : row.suitePhone,
    contactPerson1      : row.contactPerson1 + (row.contactNo1 ? '\n'+ row.contactNo1 : ''),
    // contactNo1          : row.contactNo1,
    contactPerson2      : row.contactPerson2 + (row.contactNo2 ? '\n'+ row.contactNo2 : ''),
    // contactNo2          : row.contactNo2,
    contactPerson3      : row.contactPerson3 + (row.contactNo3 ? '\n'+ row.contactNo3 : ''),
    // contactNo3          : row.contactNo3,
    ChargedServices     : row.ChargedServices,
    AccessManagement    : row.AccessManagement,
    CaseHistory         : row.CaseHistory,
    remarks             : row.remarks,
  }
}
