
import { defineComponent } from "vue"
import { Icon } from "@iconify/vue"

export default defineComponent({
	components: {
		Icon
	},
	setup() {
		function importData() {}

		return {
			importData
		}
	}
})
