import { createApp } from "vue"
import ElementPlus from "element-plus"
import VXETable from "vxe-table"
import _ from "lodash"
import axios from "axios"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import XEUtils from "xe-utils"
import tw from "vxe-table/es/locale/lang/zh-HK"
import en from "vxe-table/es/locale/lang/en-US"
import zhElement from 'element-plus/es/locale/lang/zh-tw'
import enElement from 'element-plus/es/locale/lang/en'

import "vxe-table/lib/style.css"

import LangHelper from "@/helpers/lang"

import App from "@/App.vue"
import router from "@/router"
import ConfigHelper from "@/helpers/config"
import PortHelper from "@/helpers/port"

import "@/registerServiceWorker"
import "@/styles/index.scss"

import "@/iconify"

axios.defaults.baseURL =
	process.env.NODE_ENV === "development"
		? `${location.protocol}//${location.hostname}:3000`
		: location.origin
		axios.defaults.baseURL = `${axios.defaults.baseURL}/api`


dayjs.extend(utc)

ConfigHelper.init()
PortHelper.init()

VXETable.setup({
	i18n: (key, args) =>
		XEUtils.toFormatString(
			XEUtils.get(LangHelper.lang === "en" ? en : tw, key),
			args
		)
})

const app = createApp(App)

app.use(ElementPlus, {
  locale: LangHelper.lang === "en" ? enElement : zhElement,
})
app.use(VXETable)
app.use(router)

app.config.globalProperties.__ = _
app.config.globalProperties.trans = LangHelper.trans

app.mount("#app")
