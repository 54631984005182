import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_preview = _resolveComponent("preview")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_wrapper_footer = _resolveComponent("wrapper-footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_preview, {
      fields: _ctx.fields,
      result: _ctx.result,
      loading: _ctx.loading
    }, null, 8, ["fields", "result", "loading"]),
    _createVNode(_component_wrapper_footer, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_button, {
          type: "warning",
          onClick: _withModifiers(_ctx.exportData, ["prevent"])
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Icon, { icon: "ic:baseline-cloud-download" }),
            _createElementVNode("span", null, _toDisplayString(_ctx.trans("label.export")) + " (" + _toDisplayString(_ctx.result.length) + ")", 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    })
  ], 64))
}