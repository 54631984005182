import axios from 'axios'

import {
  IDeleteResponse,
  IInfo,
} from './types'

class ResourceHelper {
  static async Info(
    endPoint: string,
  ): Promise<IInfo> {
    const response = await axios.get(`${ endPoint }/info`)

    return response.data
  }

  static async FullList(
    endPoint: string,
    query?: any,
  ): Promise<any[]> {
    const response = await axios.get(`${ endPoint }/full-list`, {
      params: query,
    })

    return response.data
  }

  static async List(
    endPoint: string,
    query?: any,
  ): Promise<any[]> {
    const response = await axios.get(endPoint, {
      params: query,
    })

    return response.data
  }

  static async Get(
    endPoint: string,
    id: number,
  ): Promise<any> {
    const response = await axios.get(`${ endPoint }/${ id }`)

    return response.data
  }

  static async Create(
    endPoint: string,
    data: any,
  ): Promise<any> {
    const response = await axios.post(endPoint, data)

    return response.data
  }

  static async Update(
    endPoint: string,
    id: number,
    data: any,
  ): Promise<any> {
    const response = await axios.put(`${ endPoint }/${ id }`, data)

    return response.data
  }

  static async Status(
    endPoint: string,
    id: number,
    status: boolean,
  ): Promise<any> {
    const response = await axios.put(`${ endPoint }/${ id }`, { _status: status })

    return response.data
  }

  static async Delete(
    endPoint: string,
    ids: number[],
  ): Promise<IDeleteResponse> {
    const response = await axios.delete(`${ endPoint }/${ ids.join(',') }`)

    return response.data
  }
}

export default ResourceHelper
