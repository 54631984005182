
import { ElMessage } from "element-plus"
import { computed, defineComponent, PropType, ref } from "vue"

import { IDataTableStatusAction } from "@/components/data-table/types"
import LangHelper from "@/helpers/lang"
import { Icon } from "@iconify/vue"

export default defineComponent({
	components: { Icon },
	props: {
		config: {
			type: Object as PropType<IDataTableStatusAction>,
			required: true
		},
		row: {
			type: Object,
			required: true
		}
	},
	setup({ config, row }) {
		const status = ref(config.get(row))
		const color = computed(() => (status.value ? "#f56c6c" : "#67c23a"))
		const icon = computed(() =>
			status.value ? "ic:baseline-person-off" : "ic:baseline-check-circle"
		)

		async function changeStatus() {
			const success = await config.set(row, !status.value)

			if (success) status.value = !status.value
			else ElMessage.error(LangHelper.trans("message.record_disable_fail"))
		}

		return {
			status,
			color,
			icon,
			changeStatus
		}
	}
})
