import LangHelper from "@/helpers/lang"
import dayjs from "dayjs"
import { defaultImage } from "@/components/PhotoTaker.vue"

import {
	IDataTableConfig,
	IDTActionBarConfig,
	IDTDataConfig,
	IDTEditorConfig,
	IDTTableConfig
} from "@/components/data-table/types"
import AuthHelper from "@/helpers/auth"
import RecordHelper, { RecordType } from "@/helpers/record"

import BlacklistEditor from "./BlacklistEditor.vue"

const data = <IDTDataConfig>{
	source: "/blacklist",
	parser: (row: any) => {
		row.name = `${row.lastName} ${row.firstName}`.trim()
		row.voidUser = RecordHelper.Get(RecordType.User, row.voidBy)

		return row
	},
	searchByColumn: (row: any, kw: string) => {
		// console.log('searchByColumn', kw, row);
		if (String(kw).match(/^[0-9]+/)) return String(row.phone).indexOf(kw) == 0

		// const fields = [row.lastName, row.firstName, row.name]
		//   .map((field) => field.trim().toLowerCase())

		// const keywords = kw.trim().split(' ')
		// const matched = keywords
		//   .map((word) => word.trim().toLowerCase())
		//   .filter((word) => {
		//     const regex = new RegExp(`(?:^|[\\s])${word}(.*)`, "ig")
		//     return fields.find((field) => regex.test(field))
		//   })

		// return matched.length > 0 && matched.length == keywords.length

		const keyword = kw.trim().replaceAll(" ", "").toLowerCase()
		const combinations = [
			(row.firstName + row.lastName).trim().replaceAll(" ", "").toLowerCase(),
			(row.lastName + row.firstName).trim().replaceAll(" ", "").toLowerCase()
		]
		const matched = combinations.filter((word) => {
			if (!word || word == "") return
			return word.indexOf(keyword) == 0
		})
		let pass = matched.length > 0

		if (!pass && row.remarks && row.remarks != "") {
			pass = row.remarks.indexOf(kw) >= 0
		}
		if (!pass && row.voidUser && row.voidUser != "") {
			pass = row.voidUser.indexOf(kw) >= 0
		}
		if (!pass && row.phone && row.phone != "") {
			pass = row.phone.indexOf(kw) >= 0
		}

		return pass
	}
}

const actionBar = <IDTActionBarConfig>{
	search: "column",
	get create(): boolean {
		return AuthHelper.roleId.value > 0 && AuthHelper.roleId.value != 3
	}
}

const table = <IDTTableConfig>{
	columns: [
		{
			title: LangHelper.trans("label.name"),
			prop: "name",
			sortable: true
		},
		{
			title: LangHelper.trans("label.phone"),
			prop: "phone",
			sortable: true
		},
		{
			title: LangHelper.trans("label.blacklisted-by"),
			prop: "voidUser",
			sortable: true
		},
		{
			title: LangHelper.trans("label.blacklisted-date"),
			prop: "createDate",
			sortable: true,
			formatter: ({ cellValue }: any) =>
				dayjs(cellValue)
					// .utc()
					.format("YYYY-MM-DD HH:mm")
		},
		{
			title: LangHelper.trans("label.remarks"),
			prop: "remarks"
		}
	],
	action: {
		get mode(): string {
			return AuthHelper.roleId.value <= 2 ? "edit" : "view"
		}
	}
}
const editor = <IDTEditorConfig>{
	title: LangHelper.trans("label.blacklist"),
	component: BlacklistEditor,
	delete: false,
	defaultModel: {
		lastName: "",
		firstName: "",
		salutation: "",
		idTypeId: 1,
		idCardNo: "",
		phone: "",
		email: "",
		remarks: "",
		isHenderson: false,
		voidBy: 1,
		image: defaultImage
	}
}

export default <IDataTableConfig>{
	data,
	actionBar,
	table,
	editor
}
