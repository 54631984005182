
import { defineComponent } from "vue"
import { useRouter } from "vue-router"

import { DataTable } from "@/components"

import config from "./config"
import { Icon } from "@iconify/vue"

export default defineComponent({
	components: {
		Icon,
		DataTable
	},
	setup() {
		const router = useRouter()

		function preRegistration() {
			router.push("/visitor/pre-registration")
		}

		return {
			config,
			preRegistration
		}
	}
})
