export interface ICredentials {
  username: string
  password: string
}

export interface IColumn {
  field: string,
  type: string,
  enum?: string[]
}

export interface IInfo {
  columns: IColumn[]
  primaryKey: string
}

export interface IDeleteResponse {
  affected: number
}

export interface IConfig {
  camera_ip: string
  camera_port: string
  camera_username: string
  camera_password: string

  connector_port: string

  rfid_ip: string
  rfid_port: string

  qrcode1_ip: string
  qrcode1_port: string

  qrcode2_ip: string
  qrcode2_port: string
}

export enum CardType {
  RFID    = 'rfid',
  QrCode1 = 'qrcode1',
  QrCode2 = 'qrcode2',
}
