import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouterOptions,
} from 'vue-router'

import Auth    from './auth'
import General from './general'
import Menu    from './menu'
import User    from './user'

import AuthHelper from '@/helpers/auth'
import Wrapper    from '@/views/wrapper/Wrapper.vue'

import Debug        from '@/views/com-connector/Debug.vue'
import RecordHelper from '@/helpers/record'

const authRoutes    = Auth
const generalRoutes = General
const menuRoutes    = Menu
const userRoutes    = User
const debugRoutes   = {
  path     : 'com-connector',
  name     : 'COM Connector',
  component: Debug,
}

const fullRoutes = [
  {
    path    : '/',
    redirect: '/login',
  },
  ...authRoutes,
  {
    path     : '/',
    component: Wrapper,
    children : [
      ...generalRoutes,
      ...menuRoutes,
      ...userRoutes,
      debugRoutes,
    ],
  },
  {
    path    : '/:catchAll(.*)',
    redirect: '/',
  },
]

const router = createRouter(<RouterOptions>{
  history: createWebHistory(),
  routes : fullRoutes,
})

router.beforeEach(async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ) => {
    if (_.has(to.query, '_token')) {
      AuthHelper.SetToken(to.query._token as string)

      if (_.has(to.query, 'logoutRedirect')) {
        let logoutRedirect = to.query.logoutRedirect as string

        if (_.has(to.query, 'lang')) {
          logoutRedirect += `/${to.query.lang}`
        }
        localStorage.setItem('logoutRedirect', logoutRedirect)

        AuthHelper.logoutRedirect = logoutRedirect;
      }
        
    }

    const verified = await AuthHelper.Verify()

    if (verified)
      await RecordHelper.Fetch()

    if (
      to.path === '/login' &&
      verified
    )
      return next('/dashboard')

    if (
      to.path !== '/login' &&
      !verified
    )
      return next('/login')

    next()
  },
)

export default router

export {
  fullRoutes,

  authRoutes,
  generalRoutes,
  menuRoutes,
  userRoutes,
}
