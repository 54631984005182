
import axios from "axios"
import { defineComponent, reactive, ref, toRaw } from "vue"
import { ElMessage } from "element-plus/es"

import { getDateRange, shortcuts } from "@/helpers/date"
import LangHelper from "@/helpers/lang"
import ResourceHelper from "@/helpers/resource"
import ReportHelper from "@/helpers/report"
import WrapperFooter from "@/views/wrapper/WrapperFooter.vue"

import { fields, parseData } from "./report"

import Preview from "../Preview.vue"
import { Icon } from "@iconify/vue"

export default defineComponent({
	components: {
		Icon,
		Preview,
		WrapperFooter
	},
	setup() {
		const companies = ref<any[]>([])
		const floors = ref<any[]>([])
		const types = ref<any[]>([])
		const result = ref<any[]>([])
		let loading = ref<boolean>(false)

		const model = reactive<any>({
			date: getDateRange(1, "week"),
			visitorType: null,
			tenantId: null,
			floorId: null,
			lastname: "",
			firstname: "",
			phone: ""
		})

		async function getRelatedData() {
			const result = await Promise.all([
				ResourceHelper.List("/tenant/profile"),
				ResourceHelper.List("/building/floor"),
				ResourceHelper.List("/visitor/type")
			])

			companies.value = result[0]

			floors.value = result[1]
			floors.value = floors.value.sort((a, b) => {
				const floorNameA = a.floorName.toLowerCase()
				const floorNameB = b.floorName.toLowerCase()

				if (floorNameA.startsWith("g/f")) {
					return -1
				}
				if (floorNameB.startsWith("g/f")) {
					return 1
				}

				const numberA = parseInt(floorNameA.split("/")[0])
				const numberB = parseInt(floorNameB.split("/")[0])

				if (!isNaN(numberA) && !isNaN(numberB)) {
					return numberA - numberB
				}

				if (!isNaN(numberA)) {
					return -1
				}
				if (!isNaN(numberB)) {
					return 1
				}

				if (floorNameA < floorNameB) {
					return -1
				}
				if (floorNameA > floorNameB) {
					return 1
				}
				return 0
			})
			// console.log(floors.value)

			types.value = result[2]
		}
		getRelatedData()

		async function filterData() {
			loading.value = true
			result.value = []

			const { data: response } = await axios.post(
				"/report/visitor",
				toRaw(model)
			)

			const related = {
				floors: floors.value,
				types: types.value
			}
			const data = response.data.map((row: any) => parseData(row, related))

			if (data.length === 0)
				ElMessage.error(LangHelper.trans("message.visitor_not-found"))

			result.value = data
			loading.value = false
		}

		async function exportData() {
			if (result.value.length === 0)
				return ElMessage.error(
					LangHelper.trans("message.visitor_export_not-found")
				)

			ReportHelper.export(fields, result.value, "Visitor")
		}

		return {
			companies,
			floors,
			types,
			result,
			model,
			shortcuts,
			fields,
			loading,
			filterData,
			exportData
		}
	}
})
