
import { defineComponent, ref } from "vue"
import { useRouter } from "vue-router"

import { ElMessage } from "element-plus/es"

import AuthHelper from "@/helpers/auth"
import { DataTable } from "@/components"
import LangHelper from "@/helpers/lang"
import ReportHelper from "@/helpers/report"
import WrapperFooter from "@/views/wrapper/WrapperFooter.vue"

import config from "./config"
import dayjs, { Dayjs } from "dayjs"
import { Icon } from "@iconify/vue"

export default defineComponent({
	components: {
		Icon,
		DataTable,
		WrapperFooter
	},
	setup() {
		const router = useRouter()
		const rowCount = ref<number>(0)

		function updatenumber() {
			rowCount.value = config.data.rowData.length
		}
		function visitorRegistration() {
			if (AuthHelper.is.staff) router.push("/visitor/registration")
			else router.push("/visitor/pre-registration")
		}

		if (config.actionBar.date) {
			config.actionBar.resetdate?.()

			const startDate = dayjs(
				_.nth(_.get(config.actionBar.date, "value"), 0)
			).format("YYYY-MM-DD HH:mm:ss")
			const endDate = dayjs(
				_.nth(_.get(config.actionBar.date, "value"), 1)
			).format("YYYY-MM-DD HH:mm:ss")

			if (config.data.searchByVisitDate)
				config.data.searchByVisitDate(startDate, endDate)
		}

		const fields = {
			name: LangHelper.trans("label.name"),
			visitingCompanies: LangHelper.trans("label.company"),
			otherName: LangHelper.trans("label.event-name"),
			contactNo: LangHelper.trans("label.phone"),
			visitorCompany: LangHelper.trans("label.visitor-company"),
			visitDate: LangHelper.trans("label.visit-date"),
			createDatetime: LangHelper.trans("label.registration-date"),
			entryTime: LangHelper.trans("label.entry-time"),
			exitTime: LangHelper.trans("label.exit-time"),
			isDeleted: LangHelper.trans("label.status")
		}

		function getTime(value: Dayjs) {
			const time = value.format("HH:mm")

			return time === "Invalid Date" || !value ? "-" : time
		}

		function getStatus(row: any) {
			if (row.isDeleted) return "⨯"

			if (!row.entryTime || row.entryTime.format() === "Invalid Date")
				return "Reg."

			if (!row.exitTime || row.exitTime.format() === "Invalid Date") return "IN"

			return row.exitTime.unix() > row.entryTime.unix() ? "OUT" : "IN"
		}

		async function exportData() {
			// alert('export')
			// console.log(config.data.rowData)

			const data = config.data.rowData.map((row: any) => {
				return {
					name: row.name,
					visitingCompanies: row.visitingCompanies.join(" "),
					otherName: row.otherName,
					contactNo: row.contactNo,
					visitorCompany: row.visitorCompany,
					visitDate: row.visitDate.format("YYYY-MM-DD"),
					createDatetime: row.createDatetime.format("YY-MM-DD HH:mm"),
					entryTime: getTime(row.entryTime),
					exitTime: getTime(row.exitTime),
					isDeleted: getStatus(row)
				}
			})

			if (data.length === 0)
				return ElMessage.error(LangHelper.trans("message.visitor_not-found"))

			ReportHelper.export(fields, data, "RFID Management")
		}

		return {
			rowCount,
			config,
			updatenumber,
			visitorRegistration,
			exportData
		}
	}
})
