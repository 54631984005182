import LangHelper from '@/helpers/lang'
import Dashboard  from '@/views/general/dashboard/Dashboard.vue'
import Debug      from '@/views/com-connector/Debug.vue'

const routes = [
  {
    path     : 'dashboard',
    name     : LangHelper.trans('label.dashboard'),
    meta     : {
      icon: 'ic:baseline-dashboard',
    },
    component: Dashboard,
  },
  {
    path     : 'com-connector',
    name     : 'COM Connector',
    meta     : {
      icon: 'fluent:bug-24-regular',
    },
    component: Debug,
  },
]

export default routes
