
import { defineComponent } from "vue"
import { useRouter } from "vue-router"

import AuthHelper from "@/helpers/auth"
import { Icon } from "@iconify/vue"

export default defineComponent({
	components: { Icon },
	setup() {
		const router = useRouter()
		const isStaff = AuthHelper.isStaff
		const isMobile = AuthHelper.isMobile
		const forceDesktop = AuthHelper.forceDesktop

		function switchVersion(desktop: boolean) {
			forceDesktop.value = desktop

			if (router.currentRoute.value.path !== "/dashboard")
				router.push("/dashboard")
		}

		function logout() {
			AuthHelper.Logout()
		}

		return {
			isStaff,
			isMobile,
			forceDesktop,
			switchVersion,
			logout,
			routeTo: router.push
		}
	}
})
