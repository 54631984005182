
import { defineComponent, onBeforeUnmount, onMounted, ref } from "vue"
import { ElHeader, ElAside } from "element-plus"

import LangHelper from "@/helpers/lang"

import { Breadcrumb as AppBreadcrumb, Nav as AppNav } from "@/components"
import AuthHelper from "@/helpers/auth"
import { useRoute, useRouter } from "vue-router"
import { Icon } from "@iconify/vue"

export default defineComponent({
	components: {
		Icon,
		AppBreadcrumb,
		AppNav
	},
	setup() {
		const isMobile = AuthHelper.isMobile
		const mobileVersion = AuthHelper.mobileVersion
		const router = useRouter()

		const isDashboard = ref<boolean>(false)
		const menuOpen = ref<boolean>(true)

		function checkRoute() {
			const { currentRoute } = router

			isDashboard.value =
				currentRoute.value.name === LangHelper.trans("label.dashboard")
		}

		function toggleMenu() {
			menuOpen.value = !menuOpen.value
		}

		function onResize() {
			if (mobileVersion.value) return

			menuOpen.value = window.innerWidth >= 900
		}

		onMounted(() => {
			onResize()

			window.addEventListener("resize", onResize)
		})
		onBeforeUnmount(() => window.removeEventListener("resize", onResize))

		router.afterEach(checkRoute)
		checkRoute()

		return {
			ElHeader,
			ElAside,
			isMobile,
			mobileVersion,
			isDashboard,
			menuOpen,
			toggleMenu
		}
	}
})
